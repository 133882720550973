@import "../../../../../styles/variables.scss";

.favoriteIcon {
  stroke: $grey;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  &_active {
    fill: $yellow;
    stroke: $yellow;
  }
}
