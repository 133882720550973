@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.servicesPersonal {
  padding-bottom: 28px;
  @media screen and (max-width: $breakpoint-xm) {
    padding-bottom: 40px;
  }

  &__tabs {
    margin: 24px 0 23px 0;
    width: 100%;

    @media screen and (max-width: $breakpoint-sm) {
      overflow-x: scroll;
      scrollbar-width: thin;
      scrollbar-color: rgba(0, 0, 0, 0.08) #ffffff00;

      &::-webkit-scrollbar {
        background: transparent;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: $light-grey;
        height: 4px;
        border-radius: 10px;
      }
    }
  }

  &__cardsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__loadingBlock {
    position: relative;
    max-width: 760px;
    height: 300px;
  }

  &__loader {
    top: 200px;
    bottom: auto;
  }

  &__cardsEmpty {
    width: 100%;
    max-width: 760px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 63px;

    @media screen and (max-width: $breakpoint-xm) {
      margin-top: 40px;
    }

    @media screen and (max-width: $breakpoint-lg) {
      margin-bottom: 64px;
    }
  }

  &__cardsEmptyTitle {
    @include font-text-medium;
    line-height: 40px;
    text-align: center;
  }

  &__cardsEmptyBtn {
    @include font-text($white, 24px);
    width: 260px;
    height: 56px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }
}
