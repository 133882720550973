@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/variables.scss";

.myBookings {
  width: 100%;
  margin: 57px 0 0 40px;

  @media screen and (max-width: $breakpoint-xxl) {
    margin-left: 0;
  }

  @media screen and (max-width: $breakpoint-sm) {
    margin-top: 24px;
  }

  @media screen and (max-width: $adaptive) {
    padding: 0 7px;
  }

  &__title {
    @include font-text-big;
    line-height: 40px;
    margin-bottom: 24px;
  }

  &__cardsContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (max-width: $breakpoint-xs) {
      gap: 16px;
    }
  }

  &__empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 70px;
  }

  &__modalReuseTitle {
    @include font-text-big;
  }
}
