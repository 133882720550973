@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.bulletsWrapper {
  background-color: $yellow;
  .swiper-slide {
    @include font-text-reduced($white);
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $yellow;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .swiper-slide-thumb-active {
    background-color: $brown;
    &:hover {
      opacity: 0.8;
    }
  }
}
