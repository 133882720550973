@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.finance {
  width: 100%;
  padding: 57px 0 0 40px;

  @media screen and (max-width: $breakpoint-xxl) {
    padding-left: 0;
  }

  @media screen and (max-width: $adaptive) {
    padding: 25px 7px 56px 8px;
  }

  &__title {
    @include font-text-big;
    line-height: 40px;
    margin-bottom: 27px;

    @media screen and (max-width: $adaptive) {
      margin-bottom: 23px;
    }
  }

  &__topCardsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 760px;
    margin-bottom: 24px;
    gap: 16px;

    @media screen and (max-width: $breakpoint-xm) {
      flex-direction: column;
    }
  }

  &__topCard {
    width: 100%;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    padding: 21px 24px 27px 23px;
    background-color: $white;
    border: 1px solid $light-grey;
    border-radius: 8px;

    @media screen and (max-width: 600px) {
      max-width: none;
    }

    @media screen and (max-width: $adaptive) {
      padding: 13px 16px 18px 15px;
    }
  }

  &__topCardTitle {
    @include font-text-subtitle-small($dark-grey);
    margin-bottom: 6px;

    @media screen and (max-width: $breakpoint-xs) {
      @include font-text-subtitle-small;
    }

    @media screen and (max-width: $adaptive) {
      margin-bottom: 7px;
    }
  }

  &__topCardValue {
    @include font-title-big;
    font-size: 30px;

    @media screen and (max-width: $breakpoint-xm) {
      @include font-text-medium;
    }
  }

  &__historyCardsWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (max-width: $breakpoint-xm) {
      gap: 16px;
    }
  }
}
