@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.serviceItemMain {
  max-width: 360px;
  width: 50%;
  min-height: 60px;
  height: max-content;
  display: flex;
  flex-direction: column;

  h3 {
    @include font-text-medium;
    margin-bottom: 8px;

    @media only screen and (max-width: $breakpoint-xs) {
      @include font-text-middle;
      line-height: 24px;
      margin-top: 16px;
    }
  }

  p {
    @include font-text($dark-grey);
  }

  @media only screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
