@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.selectBlock {
  position: relative;
  width: 288px;
  display: flex;
  flex-direction: column;

  &__label {
    color: $grey;
    font-size: 14px;
  }

  &__select {
    position: relative;
    width: 100%;
    background-color: $white;
    font-size: 24px;
    line-height: 40px;
    border: 1px solid $light-grey;
    border-radius: 15px;

    &:read-only {
      color: $dark-grey;
      background-color: $light-grey;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
      width: 100%;
    }
  }

  &__selectWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: min-content;
  }

  &__arrow {
    @include size(16px, 16px);
  }

  &__errorText {
    @include font-text-reduced($text-color-error);
    position: absolute;
    line-height: 15px;
    right: 0;
    top: calc(100% + 4px);
    text-align: end;
  }
}
