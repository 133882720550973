@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.choosePay {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: $popup-z-index;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__popupWrapper {
    @include popupSpecialWrapper(560px);
    margin: auto;

    @media only screen and (max-width: $breakpoint-xm) {
      margin: 0;
    }
  }

  &__popup {
    position: relative;
    width: 100%;
    max-width: 551px;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 24px;
    padding: 19px 18px 56px 32px;

    @media only screen and (max-width: $breakpoint-xm) {
      @include popupScrollAdaptive;
      padding: 32px 8px 24px 8px;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__wrapperButton {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__error {
    @include font-text-reduced($text-color-error);
    position: absolute;
    text-align: center;
  }
  &__close {
    position: relative;
    align-self: flex-end;
    cursor: pointer;
    margin-left: 3px;
    transform: scale(0.75);

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      margin-right: 15px;
    }
  }

  &__title {
    color: $main-black;
    font-size: 32px;
    line-height: 48px;
    margin-top: 20px;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 32px;
    }
  }
  &__methods {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 24px;

    @media only screen and (max-width: $breakpoint-xs) {
      flex-direction: column;
    }
  }
  &__button {
    max-width: 487px;
    width: 100%;
    margin-top: 23px;
    color: $white;
    align-self: flex-start;
  }

  &__hint {
    position: relative;
    width: 32px;
    height: 32px;
    top: 7px;
    left: 13px;
  }

  &__hintIcon {
    position: relative;
    width: 32px;
    height: 32px;
  }

  &__hintText {
    max-width: 200px;
    left: 36px;
    bottom: -3px;

    @media only screen and (max-width: 790px) {
      left: -80px;
      bottom: 35px;
    }

    @media only screen and (max-width: 530px) {
      left: -170px;
    }

    @media only screen and (max-width: 435px) {
      left: 0px;
    }
  }

  &__linkBtn {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    margin-top: 24px;
    align-self: flex-start;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      width: 100%;
    }
  }

  &__iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    background: $white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
  }

  &__linkText {
    @include font-text-subtitle-small;
    transition: 0.3s;
  }

  &__link:hover &__linkText {
    color: $yellow;
  }

  &__loader {
    top: 90px;
    bottom: auto;
  }

  &__sberButton {
    margin-top: 16px;
  }
}

@keyframes showModalAdaptive {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0;
  }
}
