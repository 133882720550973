@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  width: 100%;
  display: flex;
  max-width: $main-container;
  width: 100%;

  @media only screen and (max-width: $breakpoint-sm) {
    display: block;
  }

  @media only screen and (max-width: $breakpoint-xs) {
    height: max-content;
  }

  @media only screen and (max-width: $adaptive) {
    width: 100%;
    height: max-content;
  }

  &__leftColumn {
    max-width: 720px;
    width: 100%;

    @media only screen and (max-width: 795px) {
      max-width: 100%;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      height: max-content;
    }

    @media only screen and (max-width: $adaptive) {
      width: 100%;
      height: 100%;
    }
  }

  &__offer {
    @include size(100%, 512px);
    background-image: url("../../../../assets/images/greyBack.svg");

    @media only screen and (max-width: $breakpoint-xm) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    @media only screen and (max-width: $adaptive) {
      @include size(100%, 352px);
    }
  }

  &__content {
    padding-top: 104px;
    margin-left: 120px;

    @media only screen and (max-width: $main-part) {
      margin-left: 40px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
      padding: 0;
    }

    @media only screen and (max-width: $adaptive) {
      margin-bottom: 18px;
    }
  }

  &__title {
    color: $yellow;
    margin-bottom: 17px;

    @media only screen and (max-width: $breakpoint-xl) {
      font-size: 16px;
    }

    @media only screen and (max-width: $main-part) {
      font-size: 18px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      font-size: 16px;
    }

    @media only screen and (max-width: $adaptive) {
      color: $white;
      margin-bottom: -3px;
    }
  }

  &__header {
    width: 335px;
    color: $white;
    margin-top: 16px;

    @media (max-width: $breakpoint-xl) {
      font-size: 42px;
    }

    @media (max-width: $breakpoint-xm) {
      font-size: 40px;
      text-align: center;
    }

    @media only screen and (max-width: $adaptive) {
      width: 320px;
      font-size: 40px;
      text-align: center;
      letter-spacing: 0.04em;
    }
  }

  &__text {
    width: 342px;
    font-size: 20px;
    line-height: 24px;
    color: $white;
    margin-top: 16px;
    margin-bottom: 39px;
    user-select: none;

    @media only screen and (max-width: 854px) {
      width: 100%;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      text-align: center;
    }

    @media only screen and (max-width: $adaptive) {
      width: 285px;
      font-size: 16px;
      margin: 16px 0;
    }
  }

  &__button {
    @include btn(56px, 16px 40px);
    font-weight: bold;
  }

  &__rightColumn {
    max-width: 680px;
    position: relative;

    @media only screen and (max-width: 795px) {
      display: none;
    }

    @media only screen and (max-width: $adaptive) {
      display: none;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__up {
    position: fixed;
    bottom: 30px;
    right: 20px;
    cursor: pointer;
    z-index: 98;

    &:hover {
      opacity: 0.9;
    }

    @media only screen and (max-width: $breakpoint-xl) {
      display: none;
    }
  }
}

.contacts {
  position: relative;
  width: 100%;
  min-height: 208px;
  background-color: $yellow;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;

  @media only screen and (max-width: $breakpoint-xs) {
    max-width: 100%;
    height: max-content;
    padding-bottom: 16px;
    justify-content: flex-end;
  }

  &__logoAnimate {
    @media only screen and (max-width: $breakpoint-xs) {
      position: absolute;
      width: 80px;
      height: 80px;
      left: 8px;
      top: 16px;
    }

    @media only screen and (max-width: 370px) {
      display: none;
    }
  }

  &__logo {
    @include size(128px, 128px);
    margin-top: 40px;
    margin-left: 120px;

    @media only screen and (max-width: $breakpoint-xl) {
      margin-left: 0;
      padding-left: 15%;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      width: 80px;
      height: 80px;
      margin: 0;
      padding: 0;
    }
  }

  &__info {
    margin-right: 40px;

    @media only screen and (max-width: $breakpoint-xs) {
      margin: 16px 6px 0 17px;
    }

    @media only screen and (max-width: 370px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__socials {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 40px;

    @media only screen and (max-width: $breakpoint-xs) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 0;
    }

    @media only screen and (max-width: 370px) {
      align-self: flex-end;
      justify-content: flex-end;
    }
  }

  &__politicsWrapper {
    @media only screen and (max-width: $breakpoint-xs) {
      max-width: 256px;
      width: 100%;
      margin-top: 17px;
      margin-right: 3px;
    }
  }

  &__politics {
    margin-top: 32px;
    text-align: end;

    @media only screen and (max-width: $breakpoint-xs) {
      display: block;
      max-width: 256px;
      width: 100%;
      margin: 0;
    }
  }

  &__rights {
    margin-bottom: 0px;
    color: $white;

    @media only screen and (max-width: $breakpoint-xs) {
      margin-bottom: 6px;
      font-size: 14px;
    }
  }

  &__privacy {
    display: block;
    margin-bottom: 8px;
    cursor: pointer;
    color: $white;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      margin-bottom: 6px;
      font-size: 14px;
    }
  }

  &__rules {
    display: none;

    @media only screen and (max-width: $breakpoint-sm) {
      @include font-text-reduced($white);
      display: block;
    }
  }

  &__name {
    color: $white;

    @media only screen and (max-width: $breakpoint-xs) {
      font-size: 14px;
      margin-top: -4px;
    }
  }
}
