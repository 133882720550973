@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.prolongResult {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $popup-z-index;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-height: 370px) and (min-width: $breakpoint-xm) {
    align-items: flex-start;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__popupWrapper {
    @include popupSpecialWrapper(560px);
  }

  &__popup {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 560px;
    background-color: $white;
    border-radius: 24px;
    padding: 56px 32px;

    min-height: 320px;

    @media only screen and (max-width: $breakpoint-xm) {
      @include popupScrollAdaptive;
      align-items: center;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      padding: 49px 16px;
    }
  }

  &__close {
    position: absolute;
    width: 12px;
    height: 12px;
    right: 22px;
    top: 22px;
    cursor: pointer;

    @media screen and (max-width: $breakpoint-xs) {
      right: 30px;
    }
  }

  &__title {
    @include font-text-big;
    width: 100%;
    text-align: start;
    margin-bottom: 16px;
  }

  &__description {
    @include font-text-medium($dark-grey);

    &_yellow {
      color: $yellow;
    }
  }

  &__payPrice {
    margin-top: 32px;

    @media screen and (max-width: $breakpoint-xs) {
      margin-top: 16px;
    }
  }

  &__button {
    max-width: 496px;
    width: 100%;
    height: 56px;
    padding: 0;
    margin-top: 32px;
    flex-shrink: 0;

    @media only screen and (max-width: $breakpoint-xs) {
      margin-top: 23px;
    }
  }
}
