@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  position: relative;
  max-width: 360px;
  height: 464px;
  width: 98%;
  border-radius: 24px;
  border: 1px solid $light-grey;
  background-color: $white;
  overflow: hidden;

  &__imgWrapper {
    width: 100%;
    max-width: 360px;
    height: 192px;
    border-radius: 24px 24px 0 0;
    overflow: hidden;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 500ms transform;

    &:hover {
      transform: scale((1.2));

      @media (max-width: $adaptive) {
        transform: none;
      }
    }
  }

  &__imgEmpty {
    @include font-text;
    width: 100%;
    max-width: 360px;
    height: 192px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px 24px 0 0;
    text-align: center;
  }

  &__titlePriceWrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 24px 0 22px;
    margin-top: 16px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 40px;
      height: 4px;
      background-color: $yellow;
      bottom: -11px;
    }

    @media screen and (max-width: 370px) {
      padding: 0 10px;
    }
  }

  &__title {
    @include font-text-medium;
    width: 210px;
    margin-top: 8px;

    &_normal {
      font-size: 24px;
    }

    &_small {
      font-size: 20px;
      line-height: 23px;
    }

    &_supersmall {
      font-size: 16px;
      line-height: 18px;
    }

    @media screen and (max-width: 370px) {
      width: 180px;
    }
  }

  &__price {
    @include font-text-medium($grey);
    position: absolute;
    right: 24px;
    top: 8px;

    &_small {
      font-size: 22px;
      right: 10px;
    }
  }

  &__description {
    @include font-text($dark-grey);
    width: 100%;
    height: max-content;
    max-height: 80px;
    overflow-y: scroll;
    padding: 0 19px 0 18px;
    margin-top: 26px;

    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.08) #ffffff00;

    &::-webkit-scrollbar {
      width: 6px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.08);
      border-radius: 15px;
    }
  }

  &__statusWrapper {
    padding-left: 24px;
    padding-right: 30px;
    position: absolute;
    bottom: 24px;

    @media screen and (max-width: 370px) {
      position: relative;
      bottom: auto;
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  &__statusNoAvailable {
    @include font-text-reduced($grey, 16px);
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__spanNoAvailable {
    margin-left: 28px;
    max-width: 107px;
  }

  &__buttonNoAvailable {
    @include btn(56px, 16px 38px);
    pointer-events: none;
    border: 1px solid $grey;
    color: $grey;
  }

  &__price {
    display: block;
  }

  &__statusAdded {
    position: relative;
    display: flex;
  }

  &__buttonAdded {
    @include btn(56px, 16px 40px);
    background-color: $yellow;
    pointer-events: none;
    color: $white;
  }

  &__buttonDelete {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $light-grey;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s;
    margin-left: 8px;

    &:hover {
      border: 1px solid $main-black;
    }
  }

  &__buttonDeleteIcon {
    width: 16px;
    height: 16px;
  }

  &__button {
    @include btn(56px, 16px 40px);
    border: 1px solid $yellow;
    color: $yellow;

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.16),
          rgba(255, 255, 255, 0.16)
        ),
        $yellow;
      color: $white;
      border: none;
    }
  }
}
