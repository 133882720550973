@import "../../../../../styles/variables.scss";

.info {
  min-width: 150px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.64);
  border: 1px solid rgba(255, 255, 255, 0.32);
  position: absolute;
  bottom: 50px;
  right: -65px;
  &__content {
    padding: 16px;
  }
  &__title {
    color: $white;
  }
  &__places {
    width: 135px;
    color: rgba(255, 255, 255, 0.48);
    font-size: 12px;
    line-height: 16px;
  }
  &__count {
    color: $white;
  }
}
