@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  max-width: $main-container;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  @media only screen and (max-width: $adaptive) {
    max-width: 375px;
    width: 100%;
  }

  &__loader {
    left: 48%;
  }

  &__empty {
    position: relative;
    width: 100%;
    height: 100vh;
  }

  &__content {
    max-width: 1160px;
    width: 96%;
    margin-top: 32px;
    gap: 24px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    @media only screen and (max-width: $breakpoint-xxl) {
      margin-left: 0;
      width: 98%;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
      width: 98%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 24px;
      margin-left: 0;
    }
  }

  &__news {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 16px;
    gap: 24px 40px;
    flex-grow: 1;

    @media only screen and (max-width: $breakpoint-xxl) {
      gap: 24px;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 375px;
      width: 100%;
      justify-content: center;
      margin-top: 0;
      gap: 16px 0;
    }
  }

  &__button {
    @include btn(56px, 16px 115.5px);
    border: 2px solid $dark-grey;
    margin-top: 8px;
    font-weight: bold;

    @media only screen and (max-width: $adaptive) {
      width: 360px;
      border: 2px solid $dark-grey;
      margin-top: 0px;
    }
  }

  &__footer {
    max-width: 1160px;
  }
}

.noContent {
  max-width: 1160px;
  width: 100%;

  &__empty {
    margin-top: 140px;
  }

  &__footer {
    margin-top: 225px;
    margin-left: -21px;

    @media screen and (max-width: $breakpoint-xxl) {
      margin-left: 0;
    }

    @media only screen and (max-width: $adaptive) {
      margin-top: 76px;
    }
  }
}
