@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.agreement {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $popup-z-index;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-height: 430px) and (min-width: $breakpoint-xm) {
    align-items: flex-start;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__popupWrapper {
    @include popupSpecialWrapper(560px);
  }

  &__popup {
    position: relative;
    width: 100%;
    max-width: 560px;
    background-color: $white;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 19px 19px 56px 32px;

    @media only screen and (max-width: $breakpoint-xm) {
      width: 100%;
      @include popupScrollAdaptive;
      border-radius: 24px 24px 0 0;
      padding: 19px 16px 0 16px;
    }
  }

  &__content {
    width: 100%;
    max-width: 1266px;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $breakpoint-xm) {
      justify-content: space-between;
      height: 100%;
    }
  }

  &__loader {
    top: -4px;
  }

  &__close {
    position: relative;
    cursor: pointer;
    align-self: flex-end;
    transform: scale(0.8);

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      margin-right: 10px;
    }
  }

  &__title {
    @include font-text-big;
    margin: 20px 0 32px 0;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-bottom: 22px;
    }
  }

  &__linkBtn {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      width: 100%;
    }
  }

  &__iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    background: $white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
  }

  &__linkText {
    @include font-text-subtitle-small;
    transition: 0.3s;
  }

  &__link:hover &__linkText {
    color: $yellow;
  }

  &__acceptBlock {
    position: relative;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 31px;

    @media only screen and (max-width: $breakpoint-xm) {
      margin: 25px 0 48px 0;
    }
  }

  &__checkbox {
    position: relative;
    display: flex;
    height: 24px;
  }

  &__accept {
    font-size: 14px;
    color: $dark-grey;
    margin-left: 16px;
  }

  &__button {
    max-width: 496px;
    width: 100%;
    color: $white;
    margin-bottom: 16px;
  }

  &__errorText {
    @include font-text-reduced($text-color-error);
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: -110%;

    @media only screen and (max-width: $breakpoint-xs) {
      bottom: -50px;
    }
  }

  &__mainError {
    @include font-text-reduced($text-color-error);
    display: flex;
    align-items: center;
    position: absolute;
    text-align: end;
    line-height: 15px;
    bottom: -20px;
  }
}
