@import "variables";

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin btn($height, $padding) {
  height: $height;
  padding: $padding;
  border-radius: 100px;
  cursor: pointer;
  font-family: "Muller", sans-serif;
}

@mixin buttonDisabled() {
  background-color: $light-grey;
  color: $main-black;
}

@mixin popupScrollAdaptive() {
  width: 100%;
  max-width: 100%;
  height: max-content;
  max-height: max-content;
  border-radius: 24px 24px 0 0;
}

@mixin popupSpecialWrapper($max-width: 560px) {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: $max-width;
  width: 98%;
  height: max-content;
  border-radius: 24px;

  @media only screen and (max-width: $breakpoint-xm) {
    width: 100%;
    max-width: 100%;
    height: max-content;
    max-height: 90%;
    position: relative;
    border-radius: 24px 24px 0 0;
    animation: 0.8s ease showModalAdaptive;
  }
}

@keyframes showModalAdaptive {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0;
  }
}

@mixin font-title-h1($color: $main-black) {
  font-family: "Muller", sans-serif;
  font-weight: 400;
  font-size: 64px;
  line-height: 64px;
  color: $color;
}

@mixin font-title-very-big($color: $main-black) {
  font-family: "Muller", sans-serif;
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  color: $color;
}

@mixin font-title-big($color: $main-black) {
  font-family: "Muller", sans-serif;
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;
  color: $color;
}

@mixin font-text-big($color: $main-black) {
  font-family: "Muller", sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: $color;
}

@mixin font-text-medium($color: $main-black) {
  font-family: "Muller", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: $color;
}

@mixin font-subtitle-bold($color: $main-black) {
  font-family: "Muller", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: $color;
}

@mixin font-subtitle-light($color: $main-black) {
  font-family: "Muller", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: $color;
}

@mixin font-text-middle($color: $main-black) {
  font-family: "Muller", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: $color;
}

@mixin font-text-subtitle-calendar($color: $yellow, $line-height: 32px) {
  font-family: "Muller", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: $line-height;
  color: $color;
}

@mixin font-text-subtitle-small($color: $main-black) {
  font-family: "Muller", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: $color;
}

@mixin font-text($color: $main-black, $line-height: 20px) {
  font-family: "Muller", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: $line-height;
  color: $color;
}

@mixin font-text-reduced($color: $main-black, $line-height: 24px) {
  font-family: "Muller", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: $line-height;
  color: $color;
}

@mixin font-text-reduced-mini($color: $main-black) {
  font-family: "Muller", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $color;
}

@mixin font-text-card-floor-title($color: $main-black) {
  font-family: "Muller", sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: $main-black;
}

@mixin font-text-button-bold($color: $white) {
  font-family: "Muller", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $color;
}

@mixin font-text-counter($color: $white) {
  font-family: "Muller", sans-serif;
  font-weight: 400;
  font-size: 8px;
  line-height: 8px;
  color: $color;
}

@mixin font-text-card-floor-subtitle($color: $grey) {
  font-family: "Muller", sans-serif;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  color: $color;
}
