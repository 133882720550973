@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.apartment {
  max-width: $main-container;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: $adaptive) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__empty {
    position: relative;
    width: 100%;
    height: 100vh;
  }

  &__back {
    @media only screen and (max-width: 1340px) {
      align-self: flex-start;
    }

    @media only screen and (max-width: $adaptive) {
      margin-left: 8px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 1190px;
    margin-top: 46px;
    overflow: hidden;

    @media only screen and (max-width: 1340px) {
      margin: 46px auto 0 auto;
      align-items: center;
      padding: 0 15px;
    }

    @media only screen and (max-width: $adaptive) {
      width: 100%;
      margin-top: 24px;
      margin-left: 0;
      padding: 0;
      align-items: center;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 26px;

    @media only screen and (max-width: $apartment) {
      max-width: 760px;
    }

    @media screen and (max-width: $breakpoint-lg) {
      flex-direction: column;
      align-items: flex-start;
      margin: 26px auto 0 auto;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
      width: 96%;
      align-items: center;
      margin: 21px auto 0 auto;
    }
  }

  &__viewTitleWrapper {
    width: calc(100% - 22px);
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media only screen and (max-width: $breakpoint-lg) {
      width: 100%;
    }
  }

  &__title {
    @include font-title-big;
    width: max-content;

    @media only screen and (max-width: $breakpoint-sm) {
      @include font-text-big;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      @include font-text-middle;
    }
  }

  &__view {
    display: inline-flex;
    align-items: center;
    margin-left: 19px;
    margin-bottom: 2px;

    @media only screen and (max-width: $breakpoint-lg) {
      display: none;
    }
  }

  &__viewSpan {
    margin-left: 12px;
    margin-top: 5px;

    @media only screen and (max-width: $adaptive) {
      margin-left: 11px;
    }
  }

  &__viewMobile {
    display: none;

    @media only screen and (max-width: $breakpoint-lg) {
      display: flex;
      align-items: center;
    }
  }

  &__headerIcons {
    position: relative;

    @media only screen and (max-width: $breakpoint-lg) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: min-content;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
      margin-bottom: 11px;
    }
  }

  &__favorite {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;

    @media only screen and (max-width: $adaptive) {
      margin: 0;
    }
  }

  &__bookingSliderWrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 1319px) {
      justify-content: space-between;
    }

    @media only screen and (max-width: $apartment) {
      flex-direction: column;
      align-items: center;
    }

    @media only screen and (max-width: 811px) {
      width: 100%;

      align-items: center;
    }

    @media only screen and (max-width: $adaptive) {
      display: block;
    }
  }

  &__infoBooking {
    margin-left: 40px;
    margin-top: 24px;

    @media only screen and (max-width: 1319px) {
      margin-left: 20px;
    }

    @media only screen and (max-width: $apartment) {
      margin-left: 0;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      display: none;
    }
  }

  &__slider {
    position: relative;
    margin-top: 24px;
    max-width: 760px;
    width: 100%;

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
      width: 100%;
      margin-top: 3px;
      margin-left: 8px;
    }

    @media only screen and (max-width: 370px) {
      margin-left: 0;
    }
  }

  &__bookBtn {
    display: none;

    @media only screen and (max-width: $breakpoint-xm) {
      width: 98%;
      max-width: 360px;
      display: block;
      color: $white;
      margin: 22px auto 0 auto;
    }
  }

  &__bookErrorAdaptive {
    display: none;

    @media only screen and (max-width: $breakpoint-xm) {
      @include font-text-reduced($text-color-error);
      display: block;
      position: relative;
      margin: 0 auto -15px auto;
    }
  }

  &__price {
    display: none;

    @media only screen and (max-width: $breakpoint-xm) {
      display: block;
      margin-top: 20px;
      text-align: center;
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    max-width: 1160px;
    margin-top: 40px;
    line-height: 20px;
    color: $dark-grey;

    @media only screen and (max-width: 1319px) {
      width: 100%;
    }

    @media only screen and (max-width: $apartment) {
      max-width: 760px;
    }

    @media only screen and (max-width: 811px) {
      margin-top: 40px;
      margin-left: 0;
      text-align: start;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 340px;
      width: 100%;
      margin-top: 24px;
      color: $main-black;
      text-align: center;
      margin-top: 29px;
      line-height: 24px;
    }
  }

  &__perks {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;

    @media only screen and (max-width: $apartment) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 760px;
      margin-top: 60px;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
      width: 100%;
      display: block;
      justify-content: space-between;
      margin-top: 40px;
    }
  }

  &__perksIcons {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 32px;
    margin-left: -24px;

    @media only screen and (max-width: $apartment) {
      max-width: 760px;
      justify-content: center;
    }

    @media only screen and (max-width: 473px) {
      flex-wrap: nowrap;
      padding-left: 32px;
      overflow-x: scroll;
      margin-left: 0;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__perkIcon {
    position: relative;
    width: 76px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $light-grey;
    overflow: hidden;

    &:last-child {
      border-right: none;
    }
  }

  &__perkIconImg {
    max-width: 28px;
    width: 100%;
    height: auto;
  }

  &__buttons {
    display: flex;
    margin-top: 8px;

    @media only screen and (max-width: $apartment) {
      margin-top: 36px;
      align-items: center;
    }

    @media only screen and (max-width: 473px) {
      max-width: 360px;
      width: 100%;
      flex-direction: column;
      margin-top: 0;
    }
  }

  &__buttonFloor {
    @include btn(56px, 16px 25.5px);
    border: 2px solid $dark-grey;
    font-weight: bold;
    margin-right: 16px;

    @media only screen and (max-width: 473px) {
      max-width: 360px;
      width: 100%;
      margin-top: 32px;
      border: 1px solid $grey;
      font-weight: bold;
      margin-right: 0;
    }
  }

  &__buttonFlat {
    width: 260px;
    border: 2px solid $dark-grey;
    font-weight: bold;

    @media only screen and (max-width: 473px) {
      max-width: 360px;
      width: 100%;
      border: 1px solid $grey;
      margin-left: 0;
      font-weight: bold;
      margin-top: 16px;
    }
  }

  &__floorPlan {
    position: absolute;
    top: 104px;
    left: 332px;
    z-index: 100;

    @media only screen and (max-width: $adaptive) {
      width: 100%;
      top: 104px;
      left: 332px;
      z-index: 100;
    }
  }

  &__flatPlan {
    position: absolute;
    top: 0;
    left: 332px;
    z-index: 100;

    @media only screen and (max-width: $adaptive) {
      width: 100%;
      top: 0;
      left: 332px;
      z-index: 100;
    }
  }
  &__footer {
    margin-top: 44px;

    @media only screen and (max-width: $adaptive) {
      margin-top: 36px;
      margin-left: 0;
      display: flex;
    }
  }
}

.reviews {
  width: 100%;
  margin-top: 41px;
  border-top: 1px solid $light-grey;
  padding-top: 56px;

  @media only screen and (max-width: $adaptive) {
    max-width: 360px;
    width: 100%;
    margin-top: 26px;
    border-top: none;
    padding-top: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    @media only screen and (max-width: $adaptive) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;
    }
  }

  &__title {
    font-size: 34px;
    line-height: 40px;
  }

  &__button {
    @include btn(56px, 16px 70px);
    font-size: 14px;
    color: $white;

    @media only screen and (max-width: 595px) {
      @include btn(40px, 16px 30px);
      font-size: 12px;
    }
    @media only screen and (max-width: $adaptive) {
      @include btn(32px, 8px 16px);
      margin-top: 6px;
      font-size: 10px;
    }
  }

  &__review {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__loader {
    position: relative;
  }

  &__showAllButton {
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: $adaptive) {
      margin-top: 16px;
    }
  }

  &__button {
    width: 360px;
    height: 56px;
    border: 1px solid $grey;
    border-radius: 100px;
    color: $main-black;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin: 24px auto 0 auto;

    &:hover {
      color: $white;
    }

    @media only screen and (max-width: $adaptive) {
      width: 360px;
      border: 1px solid $grey;
      font-weight: bold;
    }
  }

  &__empty {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }
}
