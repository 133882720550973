@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.picker {
  height: 72px;
  border-radius: 15px;
  border: 1px solid $light-grey;
  font-size: 24px;
  line-height: 40px;
  padding-left: 16px;
  cursor: pointer;
}

.container {
  @include size(803px, 528px);
  position: relative;
  bottom: 100px;
  left: 30%;
  border-radius: 16px;
  background-color: $white;
  &__content {
    width: 755px;
    &_close {
      margin-top: 36px;
      padding-left: 32px;
      cursor: pointer;
    }
    &_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 24px;
      padding-left: 32px;
      p {
        font-size: 18px;
        line-height: 32px;
        color: $yellow;
      }
    }
    &_calendar {
      margin-top: 32px;
    }
    &_button {
      text-align: end;
      .size {
        width: 285px;
      }
    }
  }
}

.pickerInline {
  :global(.react-datepicker__header) {
    background-color: $white;
    border-radius: 24px;
    border: none;
  }

  :global(.react-datepicker) {
    border: none;
  }

  :global(.react-datepicker__day-names) {
    display: none;
  }

  :global(.react-datepicker__day--selected) {
    position: relative;
    z-index: 0;
    background-color: transparent;
    color: $white;
    &:hover {
      position: relative;
      z-index: 0;
      background: $white;
      color: $white;
    }
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      z-index: -1;
      background-color: $main-black;
      border-radius: 50%;
    }
  }

  :global(.react-datepicker__day--keyboard-selected) {
    background-color: transparent;
    color: $main-black;
  }

  :global(.react-datepicker__day--disabled) {
    background-color: transparent;
    color: $grey;
  }

  :global(.react-datepicker__navigation-icon) {
    &:before {
      border-color: $main-black;
      border-style: solid;
      border-top-width: 2px;
      border-right-width: 2px;
      border-bottom-width: 0;
      border-left-width: 0;
    }
  }
  :global(.react-datepicker__navigation-icon--previous) {
    width: 7px;
  }
  :global(.react-datepicker__navigation-icon--next) {
    width: 7px;
  }

  :global(.react-datepicker__current-month) {
    font-size: 16px;
    display: block;
    margin-top: 7px;
    margin-left: 16px;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  :global(.react-datepicker__day) {
    height: 48px;
    width: 48px;
    padding: 12px;
    margin: 0;
    margin-top: -2px;
    margin-bottom: 1.5px;
    font-weight: bold;
    font-size: 16px;
  }

  :global(.react-datepicker__day--outside-month) {
    visibility: hidden;
  }
  :global(.react-datepicker__navigation-icon) {
    margin-top: 14px;
    margin-left: 3px;
  }

  :global(.react-datepicker__week) {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
  }

  :global(.react-datepicker__month-container) {
    width: 355px;
  }
  :global(.react-datepicker__triangle) {
    display: none;
  }
}
