@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

$max-width-desktop: 496px;

.deposit {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $popup-z-index;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-height: 650px) and (min-width: $breakpoint-xm) {
    align-items: flex-start;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__popupWrapper {
    @include popupSpecialWrapper(560px);
  }

  &__popup {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 560px;
    width: 100%;
    background-color: $white;
    border-radius: 24px;
    padding: 57px 25px 56px 32px;

    @media only screen and (max-width: $breakpoint-xm) {
      @include popupScrollAdaptive;
      align-items: center;
      padding: 36px 12px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      padding: 56px 16px;
    }
  }

  &__contentWrapper {
    position: relative;
    width: 100%;
    height: max-content;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__close {
    position: absolute;
    width: 12px;
    height: 12px;
    align-self: flex-end;
    top: 22px;
    right: 22px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      right: 30px;
    }
  }

  &__title {
    position: relative;
    @include font-text-big;

    &_gold {
      color: $yellow;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      max-width: none;
    }

    &_mainTitle {
      line-height: 40px;
      @media only screen and (max-width: $breakpoint-xs) {
        @include font-text-medium;
      }
    }
  }

  &__hint {
    position: relative;
    width: 32px;
    height: 32px;
    top: 7px;
    left: 13px;
  }

  &__hintIcon {
    position: relative;
    width: 32px;
    height: 32px;
  }

  &__hintText {
    max-width: 200px;
    left: 36px;
    bottom: -3px;

    @media only screen and (max-width: 500px) {
      left: -80px;
      bottom: 35px;
    }

    @media only screen and (max-width: 400px) {
      left: -170px;
      bottom: 35px;
    }
  }

  &__referenceBlock {
    width: 100%;
    max-width: $max-width-desktop;
    margin-top: 31px;

    @media only screen and (max-width: $breakpoint-xm) {
      max-width: none;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      margin-top: 24px;
    }
  }

  &__referenceHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__referenceBtn {
    cursor: pointer;
    transition: 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      opacity: 0.8;
    }

    &_rotate {
      animation: 0.4s ease rotate;
      transform: rotate(45deg);
    }
  }

  &__referenceText {
    display: none;
    @include font-text($grey, 24px);
    margin-top: 16px;
    transition: 0.4s;
    animation: 0.8s ease show;

    &_active {
      display: block;
    }
  }

  &__bottomLine {
    width: 100%;
    height: 0;
    display: block;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    margin-top: 28px;

    @media only screen and (max-width: $breakpoint-xs) {
      margin-top: 20px;
    }
  }

  &__paymentBlock {
    width: 100%;
    margin: 32px 0;

    @media only screen and (max-width: $breakpoint-xs) {
      margin: 24px 0;
    }
  }

  &__button {
    width: 100%;
    margin: 0 auto;
    max-width: $max-width-desktop;
  }

  &__sberMiniButtonUncheck {
    border: 1px solid grey;
    border-radius: 100%;
    height: 16px;
    width: 16px;
  }

  &__buttons {
    gap: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $breakpoint-xm) {
      width: 100%;
      gap: 24px;
    }
  }

  &__loader {
    top: 300px;
    bottom: auto;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(45deg);
  }
}
