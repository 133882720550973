@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

$favorite-adaptive: 660px;

.container {
  max-width: 760px;
  width: 100%;
  border-radius: 15px;
  border: 1px solid $light-grey;
  background-color: $white;
  position: relative;

  &__content {
    width: 100%;
    display: flex;

    @media only screen and (max-width: $breakpoint-xl) {
      flex-direction: column;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      flex-direction: row;
    }

    @media only screen and (max-width: $favorite-adaptive) {
      display: block;
      position: relative;
    }
  }

  &__close {
    @include size(12px, 12px);
    position: absolute;
    top: 21px;
    right: 19px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $favorite-adaptive) {
      display: none;
    }
  }

  &__closeMobile {
    display: none;

    @media only screen and (max-width: $favorite-adaptive) {
      display: block;
      position: absolute;
      top: 15px;
      right: 16px;
    }
  }

  &__photoWrapper {
    max-width: 245px;
    width: 40%;
    height: 198px;
    min-height: 198px;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    cursor: pointer;
    overflow: hidden;

    @media only screen and (max-width: $breakpoint-xl) {
      width: 100%;
      max-width: none;
      border-radius: 14px 14px 0 0;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      width: 35%;
      max-width: 245px;
      border-radius: 14px 0 0 14px;
    }

    @media only screen and (max-width: $favorite-adaptive) {
      max-width: 100%;
      width: 100%;
      height: 192px;
      min-height: 192px;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      border-bottom-left-radius: 0;
      margin-left: 0px;
    }
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 500ms transform;

    &:hover {
      transform: scale((1.2));

      @media (max-width: $adaptive) {
        transform: none;
      }
    }
  }

  &__photoEmpty {
    @include font-text;
    max-width: 245px;
    width: 40%;
    height: 198px;
    min-height: 198px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px;
    cursor: pointer;
    text-align: center;

    @media only screen and (max-width: $breakpoint-xl) {
      width: 100%;
      max-width: none;
    }


    @media only screen and (max-width: $breakpoint-lg) {
      width: 35%;
    }


    @media only screen and (max-width: $favorite-adaptive) {
      max-width: 100%;
      width: 100%;
    }
  }

  &__info {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 22px;

    @media only screen and (max-width: $breakpoint-xl) {
      width: 100%;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      width: 65%;
      padding: 16px;
    }

    @media only screen and (max-width: $favorite-adaptive) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 0px;
      padding: 16px;
    }
  }

  &__title {
    width: 100%;
    margin-top: 26px;
    margin-right: 125px;

    @media only screen and (max-width: $favorite-adaptive) {
      width: 300px;
      font-size: 24px;
      line-height: 40px;
      margin: 0;
    }
  }

  &__priceDesktop {
    margin-bottom: 29px;
    display: block;

    @media only screen and (max-width: $advantages) {
      font-size: 28px;
    }

    @media only screen and (max-width: $breakpoint-xl) {
      font-size: 24px;
    }

    @media only screen and (max-width: $favorite-adaptive) {
      display: none;
    }
  }

  &__modalDelete {
    max-width: 495px;
    line-height: 48px;
    margin-top: 24px;
    margin-bottom: 16px;
  }
}
