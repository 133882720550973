@import "../../../../styles/variables.scss";

.container {
  height: 30px;
  border-radius: 25px;
  border: 1px solid $yellow;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  padding: 7px 15px;

  &__text {
    font-size: 12px;
    line-height: 16px;
    color: $yellow;
    padding-left: 5px;
    user-select: none;
    text-align: start;
  }
}
