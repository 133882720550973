@import "../../../../../../../styles/mixins.scss";
@import "../../../../../../../styles/variables.scss";

.act {
  position: relative;
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 16px;
  padding: 24px;
  border-radius: 16px;
  background-color: $white;

  &_reviseMode {
    padding-top: 14px;
    row-gap: 0;
  }

  @media screen and (max-width: $breakpoint-xs) {
    padding: 24px 16px;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  &__title {
    @include font-text($main-black, 24px);
    display: inline-block;
    margin-bottom: 10px;
  }

  &__number {
    @include font-subtitle-bold;
    max-width: 180px;

    &_small {
      font-size: 16px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      font-weight: 400;
    }
  }

  &__date {
    @include font-text($grey, 24px);
  }

  &__buttonsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_reviseMode &__buttonsWrapper {
    margin-top: 16px;
  }

  &__loader {
    top: 0;
  }

  &__button {
    @include font-text-reduced;
    width: 146px;
    height: 48px;
    font-weight: 600;
    border: 1px solid $light-grey;
    padding: 0;

    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }

    &:hover {
      background-color: transparent;
      color: $main-black;
      opacity: 0.8;
      border: 1px solid $light-grey !important;
    }

    &:disabled {
      opacity: 0.7;
    }

    &_look {
      @media screen and (max-width: $breakpoint-sm) {
        display: none;
      }
    }
  }
}
