@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

$breakpoint-buttons: 1222px;

.residents {
  width: 100%;

  @media only screen and (max-width: $breakpoint-xxl) {
    padding: 0 15px;
  }

  @media only screen and (max-width: $breakpoint-sm) {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  &__content {
    width: 100%;
    max-width: 770px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media only screen and (max-width: 1380px) {
      margin: 0 auto;
    }
  }

  &__tabPageWrapper {
    width: 100%;
  }

  &__tabs {
    margin-top: 23px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    z-index: 10;

    @media only screen and (max-width: $breakpoint-xs) {
      margin-top: 23px;
    }
  }

  &__buttons {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;

    @media only screen and (max-width: $adaptive) {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__list {
    height: max-content;
    max-height: 1200px;
    max-width: 770px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    margin-top: 17px;

    @media only screen and (max-width: $breakpoint-lg) {
      padding-right: 8px;
    }

    @media only screen and (max-width: $adaptive) {
      overflow-y: hidden;
      max-height: 100%;
      padding-right: 0;
      margin-top: 25px;
    }
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.08) #ffffff00;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      width: 7px;
      background: rgba(0, 0, 0, 0.08);
      border-radius: 15px;
    }
  }

  &__renter {
    margin-top: 15px;
    margin-bottom: 56px;

    @media only screen and (max-width: $adaptive) {
      margin-top: -5px;
    }
  }

  &__pet {
    margin-top: 24px;
    margin-bottom: 56px;
    max-width: 760px;

    @media only screen and (max-width: $adaptive) {
      margin-top: 16px;
    }
  }

  &__buttons {
    width: 100%;
    max-width: 760px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 48px;
    flex-wrap: wrap;
    gap: 24px;

    @media only screen and (max-width: $breakpoint-buttons) {
      margin-top: 54px;
      justify-content: center;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      gap: 36px;
      justify-content: flex-end;
    }

    @media only screen and (max-width: $adaptive) {
      margin-bottom: 56px;
      margin-top: -36px;
      gap: 32px;
    }

    &_oneBtnMode {
      justify-content: flex-end;
    }
  }

  &__saveBtn {
    @include btn(56px, 16px 71.5px);

    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &__addPersonBtn {
    background-color: transparent;
  }

  &__tenantsEmpty {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 106px 12px 200px 0;

    @media only screen and (max-width: 470px) {
      padding: 63px 0 88px 0;
    }
  }

  &__tenantsEmptyText {
    @include font-text-medium;
    line-height: 40px;
    margin: 24px 0 32px 0;
    text-align: center;

    @media only screen and (max-width: 470px) {
      margin-bottom: 31px;
      margin-right: 8px;
      max-width: 300px;
    }
  }

  &__tenantsEmptyAddButton {
    width: 240px;
    height: 56px;
    background: transparent;
    border-radius: 100px;

    @media only screen and (max-width: 470px) {
      width: 100%;
      max-width: 360px;
    }
  }

  &__empty {
    width: 100%;
    max-width: 920px;
    display: flex;
    justify-content: center;
    margin-top: 200px;

    @media only screen and (max-width: $breakpoint-lg) {
      margin-bottom: 130px;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 375px;
      margin: 80px 0 0 0;
    }
  }

  &__error {
    @include font-text-reduced($text-color-error);
    position: absolute;
    text-align: end;
    line-height: 15px;

    &_emptyTenants {
      position: relative;
      margin-top: 10px;
    }

    &_tenants {
      margin-top: 70px;

      @media only screen and (max-width: $breakpoint-buttons) {
        margin-top: 105px;
      }

      @media only screen and (max-width: $breakpoint-sm) {
        margin-top: 0;
        top: 53px;
      }
    }
  }

  &__counter {
    color: $main-black;
  }

  &__counterButton {
    color: $main-black;
    background: transparent;
    border: 1px solid $main-black;
  }

  &__counterNum {
    color: $main-black;
  }

  &__messageBlock {
    @include font-text-medium;
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-top: 96px;
  }

  &__messageBlockText {
    max-width: 600px;
    text-align: center;
  }
}
