@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.contacts {
  max-width: $main-container;
  width: 100%;
  min-height: calc(100vh - $footer-height);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &__wrapper {
    display: flex;
    width: 100%;

    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
    }
  }

  &__left,
  &__leftEmpty {
    width: 50%;
    min-height: 800px;
    background-color: $yellow;

    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
      min-height: 336px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__leftEmpty {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loader {
    left: auto;
  }

  &__leftContent {
    display: flex;
    flex-direction: column;
    margin-top: 56px;
    margin-left: 120px;

    @media screen and (max-width: $breakpoint-xxl) {
      margin-left: 80px;
      padding-right: 10px;
    }

    @media screen and (max-width: $breakpoint-lg) {
      margin-left: 40px;
      padding-right: 0;
    }

    @media screen and (max-width: $breakpoint-xs) {
      margin-left: 0;
      margin-top: 36px;
      padding: 0 9px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__button {
    max-width: 244px;
    width: 100%;
    padding: 0;
    font-size: 14px;
    letter-spacing: 0.085em;
    font-weight: bold;

    &:hover {
      color: $white;
    }

    @media screen and (max-width: $breakpoint-xs) {
      width: 100%;
      max-width: 360px;
    }
  }

  &__contactItem {
    &:first-child {
      margin-bottom: 54px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 46px;
    }
  }

  &__title {
    line-height: 20px;
    color: $white;
    margin-bottom: 10px;
  }

  &__text {
    font-size: 34px;
    line-height: 40px;
    color: $white;

    @media screen and (max-width: $breakpoint-sm) {
      @include font-text-big($white);
    }

    @media screen and (max-width: $breakpoint-xm) {
      @include font-text-medium($white);
    }
  }

  &__email {
    display: inline-block;
    margin-bottom: 54px;
  }

  &__socials {
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    margin-top: 56.2%;
    font-size: 18px;
    column-gap: 32px;
    row-gap: 16px;

    @media only screen and (max-width: 1359px) {
      margin-top: 200px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      margin-top: 70px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      margin: 32px 0;
    }
  }

  &__socialLink {
    cursor: pointer;
    color: $white;
    text-decoration: none;

    &:visited {
      color: $white;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__right {
    width: 50%;
    min-height: 800px;

    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
      height: 350px;
      min-height: 300px;
      margin-bottom: 32px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      height: 536px;
    }
  }

  &__footer {
    max-width: 1160px;
    margin-top: 16px;

    @media screen and (max-width: $breakpoint-xs) {
      margin-top: 24px;
    }
  }
}
