@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.button {
  @include btn(56px, 16px 40px);
  letter-spacing: 0.11em;
  text-transform: uppercase;
  color: $main-black;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    transition: color 200ms ease-in, background-color 200ms ease-in;
    border: none;
    outline: none;
  }

  &_white {
    background-color: $white;
    border: none;
    &:hover {
      background-color: $main-black;
    }

    @media screen and (max-width: $breakpoint-xm) {
      &:hover {
        background-color: $white !important;
        color: $main-black !important;
      }
    }
  }

  &_gold {
    background-color: $yellow;
    border: none;
    color: $white;

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.16),
          rgba(255, 255, 255, 0.16)
        ),
        $yellow;
    }

    @media screen and (max-width: $breakpoint-xm) {
      &:hover {
        background-color: $yellow !important;
      }
    }
  }

  &_transparent {
    background-color: transparent;
    border: 2px solid $white;

    &:hover {
      background-color: $main-black;
      color: $white;
      border: 2px solid $main-black;
    }

    @media screen and (max-width: $breakpoint-xm) {
      &:hover {
        background-color: transparent !important;
        border: 2px solid $main-black !important;
        color: $main-black !important;
      }
    }
  }

  &_whiteDisabled {
    opacity: 0.8;
    &:hover {
      opacity: 0.8;
      background-color: $white;
    }
  }

  &_goldDisabled {
    @include buttonDisabled;
    cursor: default;

    &:hover {
      @include buttonDisabled;
      opacity: 1;
    }
  }

  &_transparentDisabled {
    background-color: transparent;
    border: 2px solid $white;

    &:hover {
      background-color: transparent;
      border: 2px solid $white;
    }
  }
}
