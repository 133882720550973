@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.stepPets {
  position: relative;
  max-width: 1161px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 49px;

  @media screen and (max-width: $breakpoint-xm) {
    margin-top: 29px;
  }

  &__backBtn {
    margin-bottom: 24px;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    margin-bottom: 16px;
  }

  &__title {
    @include font-text-big;
    line-height: 40px;
    margin-right: 24px;

    @media screen and (max-width: $breakpoint-xm) {
      width: 95%;
      margin-top: 23px;
    }
  }

  &__stepNum {
    @include font-subtitle-light($yellow);
    flex-shrink: 0;
  }

  &__descRadioWrapper {
    @media screen and (max-width: $breakpoint-xm) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__desc {
    @include font-text($grey, 24px);
    margin-bottom: 24px;

    @media screen and (max-width: $breakpoint-xm) {
      line-height: 20px;
      margin-bottom: 0;
    }
  }

  &__radioWrapper {
    display: flex;
    gap: 16px;

    @media screen and (max-width: $breakpoint-xm) {
      margin: 7px 0 32px 0;
    }
  }

  &__checkbox {
    margin: 0;
  }

  &__petTitle {
    @include font-text-middle($yellow);
    margin: 28px 0 12px 0;

    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 20px;
    }

    @media screen and (max-width: $breakpoint-xm) {
      margin: 22px 0 20px 0;
    }
  }

  &__petsCards {
    display: flex;
    flex-direction: column;
    row-gap: 84px;
  }

  &__addPetContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 31px 0 0 0;

    @media screen and (max-width: $breakpoint-xm) {
      margin: 31px 0 0 0;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      max-width: 860px;
      height: 1px;
      border-top: 1px solid $light-grey;
      left: 0;

      @media only screen and (max-width: $breakpoint-xlg) {
        width: 73%;
      }

      @media only screen and (max-width: $breakpoint-lg) {
        display: none;
      }
    }
  }

  &__addPetBtn {
    width: 207px;

    &_disabled {
      @include buttonDisabled;
      cursor: default;

      &:hover {
        @include buttonDisabled;
        opacity: 1;
      }
    }
  }

  &__button {
    width: 100%;
    max-width: 311px;
    align-self: center;
    margin-top: 64px;

    @media screen and (max-width: $breakpoint-xm) {
      max-width: 361px;
      margin-top: 32px;
    }
  }

  &__mainError {
    @include font-text-reduced($text-color-error);
    display: flex;
    align-items: center;
    position: absolute;
    text-align: end;
    line-height: 15px;
    bottom: 60px;
    align-self: center;
  }
}
