@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/variables.scss";

.content {
  width: 100%;
  margin-top: 56px;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: $breakpoint-lg) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (max-width: $adaptive) {
    margin: 28px 0 56px 0;
    padding: 0 7px;
  }

  &__title {
    display: none;

    @media only screen and (max-width: $breakpoint-lg) {
      display: block;
      align-self: flex-start;
      margin: 5px 0 19px 4px;
    }
  }

  &__count {
    color: $grey;
  }

  &__favorites {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 0 15px;
    height: max-content;
    max-height: 745px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.08) #ffffff00;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      width: 7px;
      background: rgba(0, 0, 0, 0.08);
      border-radius: 15px;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      padding: 10px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      max-width: 360px;
      gap: 15px;
      padding: 0 0;
      overflow-y: visible;
      max-height: max-content;
    }
  }

  &__empty {
    width: 100%;
    max-width: 920px;
    display: flex;
    justify-content: center;
    margin-top: 200px;

    @media only screen and (max-width: $breakpoint-lg) {
      margin-bottom: 130px;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 375px;
      display: flex;
      justify-content: center;
      margin: 80px 0 0 0;
    }
  }
}
