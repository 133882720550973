@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.wrapperSlider {
  position: relative;
  width: 100%;
  max-width: 760px;

  @media only screen and (max-width: $breakpoint-xxl) {
    max-width: 740px;
  }

  @media only screen and (max-width: $apartment) {
    max-width: 760px;
  }
}

.imgEmpty {
  @include font-text-middle;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.loupeButton {
  position: absolute;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid $white;
  right: 16px;
  top: 16px;
  z-index: 10;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    border: none;
    width: 24px;
    height: 24px;
    top: 16px;
    right: 16px;
  }
}

.fullslider {
  position: relative;
  width: 100%;
  height: min-content;

  .slideImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .flats-slider {
    display: none;
    @media only screen and (max-width: $breakpoint-xm) {
      display: block;
      width: 100%;
      border-radius: 15px;
      height: 360px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    @media only screen and (max-width: $breakpoint-xs) {
      height: 240px;
    }

    @media only screen and (max-width: $adaptive) {
      width: 360px;
    }
  }
  .flats-slider-more {
    display: block;
    width: 100%;
    .swiper-wrapper {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    @media only screen and (max-width: $breakpoint-xm) {
      display: none;
    }
  }

  .flats-thumbs {
    display: none;
    @media only screen and (max-width: $breakpoint-xm) {
      display: block;
    }
  }
  .flats-thumbs-more {
    display: block;
    width: 100%;
    pointer-events: all;
    @media only screen and (max-width: $breakpoint-xm) {
      display: none;
    }
  }

  .thumbs-more-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 16px;
  }

  .more-slides {
    width: 100%;
    max-width: 140px;
    height: 96px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    cursor: pointer;
    background-size: contain;
    margin-left: 9px;
    z-index: 1;
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.64),
        rgba(0, 0, 0, 0.64)
      );
    }
    @media only screen and (max-width: $breakpoint-sm) {
      max-width: 100px;
    }
    @media only screen and (max-width: $breakpoint-xm) {
      display: none;
    }
  }

  .moreSlidesImg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .moreSlidesCounter {
    position: relative;
    color: $white;
    font-size: 20px;
    z-index: 2;
  }

  .product-images-slider {
    max-width: 760px;
    width: 100%;
    height: 520px;
    border-radius: 15px;

    @media only screen and (max-width: $breakpoint-xxl) {
      height: 490px;
    }

    @media only screen and (max-width: $breakpoint-xl) {
      height: 450px;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      height: 400px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      height: 360px;
      margin-bottom: 8px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      height: 240px;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
      width: 100%;
    }
  }

  .swiper-button-next {
    top: 48.8%;
    right: 16px;
    width: 56px;
    height: 56px;
    background-image: url("../../../../../assets/images/nextBtn.svg");
    background-repeat: no-repeat;
    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      top: 51%;
      right: 8px;
      width: 40px;
      height: 40px;
      background-size: contain;
    }
  }

  .swiper-button-prev {
    top: 48.8%;
    left: 16px;
    width: 56px;
    height: 56px;
    background-image: url("../../../../../assets/images/prevBtn.svg");
    background-repeat: no-repeat;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      top: 51%;
      left: 8px;
      width: 40px;
      height: 40px;
      background-size: contain;
    }
  }

  .wrapperSlider_onePhoto .swiper-button-prev {
    display: none;
  }

  .wrapperSlider_onePhoto .swiper-button-next {
    display: none;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    content: "";
  }

  .swiper-slide {
    cursor: pointer;
    width: max-content;
  }

  .product-images-slider-thumbs {
    max-width: 760px;
    max-height: 105px;
    margin: 0;

    &-wrapper {
      width: 139px;
      height: 96px;
      overflow: hidden;
      position: relative;
      border-radius: 13px;

      @media only screen and (max-width: 740px) {
        width: 100%;
      }

      @media only screen and (max-width: $breakpoint-xm) {
        width: 100px;
        min-width: 100px;
        height: 80px;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .swiper-slide {
      max-width: 140px;
      overflow: hidden;
      position: relative;
      object-fit: cover;

      @media only screen and (max-width: $breakpoint-xm) {
        max-width: 100px;
        min-width: 100px;
        height: 80px;
      }

      img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .swiper-slide-thumb-active {
    border: 3px solid $yellow;
    border-radius: 16px;
    max-width: 139px;
    width: 100%;
    max-height: 98px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 13px;
      max-width: 140px;
      width: 100%;
      max-height: 92px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      width: 106px;
      min-width: 106px;
      height: 86px;
    }
  }
}
