@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.photo {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $popup-z-index;

  &__empty {
    @include font-text-medium;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__popup {
    max-width: 960px;
    width: 98%;
    height: 576px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-radius: 32px;
    position: relative;
    z-index: 1000;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 32px;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      height: 500px;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      height: 450px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      height: 380px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      height: 320px;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
      height: 266px;
      border-radius: 24px;
      img {
        border-radius: 24px;
      }
    }
  }

  &__close {
    @include size(56px, 56px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 51px;
    background-color: $white;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: -70px;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xl) {
      top: -75px;
      right: 7px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      top: -72px;
      right: 0;
    }
  }
}
