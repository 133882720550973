@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.popupActRevise {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $popup-z-index;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-height: 504px) and (min-width: $breakpoint-xm) {
    align-items: flex-start;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__popupWrapper {
    @include popupSpecialWrapper(560px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__popup {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 560px;
    width: 100%;
    background-color: $white;
    border-radius: 24px;
    padding: 57px 25px 56px 32px;

    @media only screen and (max-width: $breakpoint-xm) {
      @include popupScrollAdaptive;
      align-items: flex-start;
      padding: 36px 12px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      padding: 49px 16px;
    }
  }

  &__loader {
    top: auto;
    bottom: auto;
  }

  &__contentWrapper {
    position: relative;
    width: 100%;
    height: max-content;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__close {
    position: absolute;
    width: 12px;
    height: 12px;
    align-self: flex-end;
    top: 22px;
    right: 22px;
    cursor: pointer;

    @media only screen and (max-width: $breakpoint-xs) {
      right: 30px;
    }
  }

  &__title {
    position: relative;
    @include font-text-big;
    margin-bottom: 31px;

    &_gold {
      color: $yellow;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      max-width: none;
      margin-bottom: 23px;
    }
  }

  &__inputDate {
    max-width: 496px;

    &_dateFrom {
      margin-bottom: 32px;

      @media only screen and (max-width: $breakpoint-xs) {
        margin-bottom: 24px;
      }
    }
  }

  &__input {
    width: 100%;
  }

  &__buttonsWrapper {
    max-width: 496px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    column-gap: 96px;

    @media only screen and (max-width: 450px) {
      max-width: none;
      flex-direction: column-reverse;
      row-gap: 40px;
    }
  }

  &__buttonCancel {
    @include font-text-reduced;
    font-weight: 600;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__button {
    max-width: 244px;
    width: 100%;
    padding: 0;

    @media only screen and (max-width: 450px) {
      max-width: none;
    }
  }
}
