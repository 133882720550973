@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.card {
  @include size(360px, 322px);
  border-radius: 15px;
  border: 1px solid $light-grey;
  background-color: $white;
  box-shadow: 0px 8px 16px rgba(51, 51, 51, 0.08);

  &__info {
    height: 210px;
    cursor: pointer;
  }

  &__wrapper {
    @include size(358px, 210px);
    position: relative;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
  }

  &__imgEmpty {
    @include font-text;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__img {
    width: 358px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    transition: 500ms transform;

    &:hover {
      transform: scale((1.2));

      @media (max-width: $adaptive) {
        transform: none;
      }
    }
  }

  &__price {
    position: absolute;
    top: 16px;
    left: 16px;
    background-color: rgba(0, 0, 0, 0.48);
    color: $white;
    border-radius: 35px;
    padding: 2px 8px;
  }

  &__labels {
    display: flex;
    position: absolute;
    bottom: 8px;
    left: 8px;
  }

  &__desc {
    margin-top: 16px;
    padding-left: 17px;
  }

  &__buttons {
    display: flex;
    margin-top: 7px;
  }

  &__button {
    @include btn(32px, 8px 16px);
    font-size: 10px;
    letter-spacing: 0.28em;
    color: $white;
  }

  &__request {
    color: $grey;
    font-size: 10px;
    line-height: 12.19px;
    margin-top: 11px;
    margin-left: 24px;
    letter-spacing: 0.08em;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover {
      opacity: 0.8;
    }
  }

  &__modalSave {
    max-width: 420px;
    line-height: 48px;
    margin-top: 24px;
  }
}
