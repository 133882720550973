@import "./styles/index.scss";

.app {
  display: flex;
  width: 100%;
  max-width: 1480px;
  overflow: hidden;

  &__aside {
    width: 80px;
    background-image: url("./assets/images/aside.png");
    background-size: contain;
    background-repeat: repeat-y;
    z-index: 10;

    @media only screen and (max-width: $advantages-xl) {
      display: none;
    }
  }

  &__wrapper {
    width: calc(100% - 80px);

    @media only screen and (max-width: $advantages-xl) {
      width: 100%;
    }
  }
}
