@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.basket {
  position: relative;
  max-width: $main-container;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__contentWrapper {
    width: 100%;
    min-height: calc(100vh - $footer-height - $header-height);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__contentHeader {
    width: 100%;
    max-width: 1160px;
    height: max-content;
    margin-top: 48px;
    margin-bottom: 16px;

    @media only screen and (max-width: 1330px) {
      padding-left: 15px;
    }

    @media only screen and (max-width: $adaptive) {
      display: none;
    }
  }

  &__backButton {
    position: relative;

    @media only screen and (max-width: $adaptive) {
      display: none;
    }
  }

  &__empty {
    max-width: 1160px;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loader {
    top: auto;
    left: auto;
  }

  &__content {
    max-width: 1160px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 128px;

    @media only screen and (max-width: 1330px) {
      padding-left: 15px;
    }

    @media only screen and (max-width: 1040px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      padding-left: 0;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      padding: 0 7px;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__flats {
    max-width: 760px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 33px;

    @media only screen and (max-width: 1040px) {
      margin-top: 52px;
      margin-bottom: 34px;
    }

    @media only screen and (max-width: 785px) {
      padding: 0px 15px;
    }

    @media only screen and (max-width: $adaptive) {
      margin-top: 32px;
      gap: 15px;
      padding: 0 0;
    }
  }

  &__flatsEmpty {
    align-self: center;
    margin-top: 261px;
    margin-right: 40px;

    @media only screen and (max-width: 1040px) {
      margin: 50px 0 100px 0;
    }
  }

  &__flatsEmptyButton {
    @include font-text($white, 24px);
    width: 260px;
    margin-top: 35px;
    text-transform: uppercase;
    letter-spacing: normal;
  }

  &__buttonWrapper {
    display: none;

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;
    }
  }

  &__button {
    display: none;

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
      width: 100%;
      display: block;
      color: $white;

      &_disabled {
        @include buttonDisabled;
        pointer-events: none;
      }
    }
  }

  &__error {
    display: none;

    @media only screen and (max-width: $adaptive) {
      @include font-text-reduced($text-color-error);
      display: flex;
      align-items: center;
      line-height: 15px;
      align-self: center;
      margin-top: 8px;
    }
  }

  &__servicesMobile {
    display: none;

    @media only screen and (max-width: $adaptive) {
      display: block;
      margin-top: 20px;
      margin-left: 0;
    }
  }

  &__services {
    margin-top: -29px;

    @media only screen and (max-width: 1330px) {
      margin-left: 20px;
      padding-right: 15px;
    }

    @media only screen and (max-width: 1040px) {
      width: 380px;
      display: flex;
      flex-direction: column;
    }

    @media only screen and (max-width: $adaptive) {
      display: none;
    }
  }

  &__footer {
    max-width: 1160px;

    @media only screen and (max-width: $adaptive) {
      margin-top: 20px;
    }
  }

  &__modalText {
    @include font-text-big;
    line-height: 40px;
  }
}
