@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.review {
  max-width: 1160px;
  width: 100%;
  border-radius: 15px;
  background-color: $white;
  border: 1px solid $light-grey;
  padding: 32px 40px;

  @media only screen and (max-width: $adaptive) {
    max-width: 360px;
    width: 100%;
    height: auto;
    padding: 16px 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 24px;

    @media only screen and (max-width: $adaptive) {
      display: flex;
      align-items: center;
      margin-top: 4px;
    }
  }

  &__text {
    font-size: 18px;
    color: $dark-grey;
    margin-top: 24px;

    @media only screen and (max-width: $adaptive) {
      font-size: 16px;
      margin-top: 21px;
    }
  }

  &__readMoreButton {
    font-size: 18px;
    color: $dark-grey;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }
  }

  &__imgWrapper {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;

    @media only screen and (max-width: $adaptive) {
      @include size(72px, 72px);
      margin-right: 0px;
      margin-top: -3px;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
  }
}
