@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: $adaptive) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 56px;
  }

  &__title {
    font-size: 24px;
    line-height: 40px;
    margin-top: 32px;
    color: $main-black;
  }
}
