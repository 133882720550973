@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.container {
  width: 100%;
  height: $footer-height;

  @media only screen and (max-width: $adaptive-footer) {
    max-width: 375px;
    height: $footer-height-adaptive;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    position: relative;

    @media only screen and (max-width: $adaptive-footer) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__line {
    position: absolute;
    top: 0px;
    height: 1px;
    width: 100%;
    background-color: $light-grey;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: $adaptive-footer) {
      display: none;
    }

    &::before {
      width: 64px;
      height: 1px;
      content: "";
      background-color: $bg;
    }
  }

  &__copyBottom {
    display: none;

    @media only screen and (max-width: $adaptive-footer) {
      display: block;
      color: $grey;
      margin-top: 30px;
      margin-left: 3px;
    }
  }

  &__copyTop {
    padding-top: 34px;
    line-height: 20px;
    color: $grey;
    font-size: 16px;

    @media only screen and (max-width: 1287px) {
      padding-left: 15px;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      padding-left: 10px;
      max-width: 230px;
    }

    @media only screen and (max-width: $adaptive-footer) {
      display: none;
    }
  }

  &__dev {
    text-align: center;
    position: relative;
    right: 16px;
    cursor: pointer;

    @media only screen and (max-width: $breakpoint-sm) {
      right: 0;
    }

    @media only screen and (max-width: $adaptive-footer) {
      display: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__devText {
    font-size: 14px;
    line-height: 14px;
    color: $grey;
    margin-top: -3px;
    cursor: pointer;
  }

  &__design {
    display: none;

    @media only screen and (max-width: $adaptive-footer) {
      display: block;
      text-align: center;
    }
  }

  &__designText {
    @media only screen and (max-width: $adaptive-footer) {
      font-size: 16px;
      line-height: 24px;
      color: $grey;
      margin-left: -3px;
    }
  }

  &__agreement {
    padding-top: 34px;
    line-height: 20px;
    color: $grey;
    cursor: pointer;

    &:hover {
      color: $dark-grey;
    }

    @media only screen and (max-width: 1287px) {
      padding-right: 15px;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      padding-right: 10px;
      max-width: 230px;
      text-align: end;
    }

    @media only screen and (max-width: $adaptive-footer) {
      display: none;
    }
  }

  &__personal {
    display: none;

    @media only screen and (max-width: $adaptive-footer) {
      display: block;
      color: $grey;
      cursor: pointer;
      margin-top: 10px;
      margin-left: -3px;
    }
  }
}
