@import "../../../../../styles/variables.scss";

.slider-photo {
  position: relative;
  width: 98vw;
  max-width: 1480px;
  height: 100%;
  display: flex;
  align-items: center;
}

.sliderWrapper {
  width: 100%;
  height: min-content;
  position: relative;
}

.swiper-pagination-fraction {
  bottom: auto;
  top: 7%;
  height: max-content;
}

.sliderWrapper_onePhoto .swiper-button-prev {
  display: none;
}

.sliderWrapper_onePhoto .swiper-button-next {
  display: none;
}

.photos {
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: 900px;

  @media only screen and (max-width: $breakpoint-xl) {
    max-height: 750px;
  }

  @media only screen and (max-width: $breakpoint-lg) {
    height: 90vh;
  }

  .photo-block {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 75px 0 35px 0;

    @media only screen and (max-width: $breakpoint-sm) {
      width: 97%;
      margin: 0 auto;
    }
  }

  .photo {
    width: 100%;
    height: 100%;
    max-height: 551px;
    object-fit: contain;
  }

  .swiper-pagination-bullet {
    position: relative;
    left: 0;
    bottom: 220px;
    border: none;
    font-size: 34px;
    line-height: 40px;
    padding: 0 74px;
    width: auto;
    background: none;
    color: $white;

    @media only screen and (max-width: $adaptive) {
      height: 32px;
      position: relative;
      left: 0;
      bottom: 310px;
      border: none;
      font-size: 24px;
      line-height: 32px;
      padding: 0 0;
      width: auto;
      background: none;
      color: $white;
    }
  }

  .swiper-pagination-bullet-active {
    font-size: 34px;
    line-height: 40px;
    color: $white;
    border-radius: 0;

    @media only screen and (max-width: $adaptive) {
      font-size: 24px;
      line-height: 32px;
      color: $white;
      border-radius: 0;
      z-index: 9990;
    }
  }
}

.swiper-pagination {
  font-size: 24px;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: $adaptive) {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

.navigation {
  position: absolute;
  width: 100%;
  max-width: 98vw;
  height: min-content;
  display: flex;
  justify-content: space-between;

  .button-prev {
    width: 48px;
    height: 56px;
    background-image: url("../../../../../assets/images/prevSlide.svg");
    background-repeat: no-repeat;
    z-index: 100;

    &:hover {
      opacity: 0.9;
    }

    @media only screen and (max-width: $breakpoint-xxl) {
      top: 56px;
    }

    @media only screen and (max-width: 1840px) {
      margin-left: 0px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      display: none;
    }
  }

  .button-next {
    width: 48px;
    height: 56px;
    background-image: url("../../../../../assets/images/nextSlide.svg");
    background-repeat: no-repeat;
    z-index: 100;

    &:hover {
      opacity: 0.9;
    }

    @media only screen and (max-width: $breakpoint-xxl) {
      top: 56px;
    }

    @media only screen and (max-width: 1840px) {
      margin-right: 0px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      display: none;
    }
  }

  .swiper-button-prev:after {
    display: none;
  }

  .swiper-button-next:after {
    display: none;
  }
}
