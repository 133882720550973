@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

$time-animate: 0.7s;
$breakpoint-finance: 600px;

.historyCard {
  position: relative;
  width: 100%;
  max-width: 760px;
  border-radius: 16px;
  border: 1px solid $light-grey;
  background-color: $white;
  overflow: hidden;
  user-select: none;

  &__mainContent {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $white;
    padding: 16px 60px 14px 16px;
    z-index: 3;

    &_withBorder {
      animation: $time-animate ease showBorder;
      border-bottom: 1px solid $light-grey;
    }

    @media screen and (max-width: $breakpoint-xm) {
      padding-right: 48px;
    }

    @media screen and (max-width: $adaptive) {
      padding: 15px 60px 15px 16px;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__titlePriceWrapper {
    @include font-text-middle;
    line-height: 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $breakpoint-xm) {
      @include font-text($main-black, 24px);
      gap: 8px;
    }
  }

  &__numberStatusWrapper {
    @include font-text-reduced($grey, 16px);
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    user-select: text;

    @media screen and (max-width: $breakpoint-xm) {
      @include font-text-reduced-mini($grey);
      margin-top: 0;
      gap: 8px;
    }
  }

  &__icon {
    margin-right: 16px;
    width: 48px;
    height: 48px;
    flex-shrink: 0;

    @media screen and (max-width: $adaptive) {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }

    @media screen and (max-width: 350px) {
      width: 30px;
      height: 30px;
    }
  }

  &__triangleBtn {
    position: absolute;
    right: 7px;
    margin-top: -2px;
    cursor: pointer;
    transition: 0.3s;
    -webkit-tap-highlight-color: transparent;
    transform: rotate(180deg);
    transition: transform $time-animate;

    &:hover {
      opacity: 0.8;
    }

    &_rotate {
      transform: rotate(0deg);
    }

    @media screen and (max-width: $breakpoint-xm) {
      right: 12px;
    }

    @media screen and (max-width: $adaptive) {
      right: 3px;
    }
  }

  &__additionalContent {
    position: relative;
    width: calc(100% - 76px);
    max-width: 669px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background-color: $white;
    margin-left: 76px;
    padding: 16px 1px 16px 0;
    row-gap: 24px;
    z-index: 1;
    max-height: max-content;
    animation: $time-animate ease showHeight;

    @media screen and (max-width: $breakpoint-xxl) {
      padding-right: 10px;
    }

    @media screen and (max-width: $breakpoint-finance) {
      padding-right: 24px;
    }

    @media screen and (max-width: $adaptive) {
      width: calc(100% - 10px);
      max-width: 333px;
      margin-left: 10px;
      row-gap: 16px;
      padding-right: 0;
      flex-direction: column;
    }

    @media screen and (max-width: 370px) {
      padding-right: 8px;
    }
  }

  &__propsItemsWrapper {
    width: 46%;
    max-width: 304px;
    min-width: max-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;

    @media screen and (max-width: $breakpoint-finance) {
      max-width: none;
      width: 100%;
    }

    @media screen and (max-width: $adaptive) {
      max-width: 248px;
      align-self: center;
      margin-left: 19px;
    }
  }

  &__propsItem {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    &:last-child {
      @media screen and (max-width: $adaptive) {
        align-items: flex-end;
      }
    }
  }

  &__propsTitle {
    @include font-text-reduced($grey);

    @media screen and (max-width: $adaptive) {
      @include font-text-reduced-mini($grey);
    }
  }

  &__propsValue {
    @include font-text($main-black, 24px);
  }

  &__payButton {
    max-width: 167px;
    width: 100%;
    height: 48px;
    padding: 0;

    @media screen and (max-width: $breakpoint-finance) {
      max-width: none;
    }
  }
}

@keyframes showHeight {
  0% {
    position: absolute;
    opacity: 0;
    z-index: -1;
    max-height: 0;
    margin-top: -90px;
  }

  100% {
    position: relative;
    opacity: 1;
    z-index: 1;
    max-height: max-content;
    margin-top: 0px;
  }
}

@keyframes showBorder {
  0% {
    border: none;
  }

  100% {
    border-bottom: 1px solid $light-grey;
  }
}
