@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.modal {
  height: 100%;
  width: 100%;
  height: 100%;
  background-color: $light-grey;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $popup-z-index;
  display: flex;
  justify-content: center;
  align-items: center;

  &__popup {
    position: relative;
    width: 100%;
    max-width: 1480px;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  &__close {
    position: absolute;
    top: 7%;
    right: 60px;
    z-index: 9999;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: 1539px) {
      top: 7%;
      right: 60px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      top: 7%;
      right: 12px;
    }
  }

  &__content {
    position: relative;
    max-width: 1480px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
      opacity: 0;
    }
  }
}
