@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.acts {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;

  &__loadingBlock {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 375px;
  }

  &__loader {
    top: 0;
  }

  &__cardsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 24px 40px;

    @media screen and (max-width: $breakpoint-xs) {
      gap: 16px;
    }
  }

  &__empty {
    @include font-text-medium;
    line-height: 40px;
    position: relative;
    width: 100%;
    height: 472px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__reviseActs {
    margin-top: 52px;
  }

  &__title {
    @include font-text-middle($yellow);
    line-height: 24px;
    margin-bottom: 16px;
  }

  &__buttonWrapper {
    width: 100%;
    max-width: 760px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 23px 0 44px 0;

    @media screen and (max-width: 599px) {
      justify-content: flex-end;
    }
  }

  &__reviseButton {
    @include font-text($white);
    position: relative;
    padding: 6px 16px;
    background-color: $yellow;
    border-radius: 15px;
    cursor: pointer;
    flex-shrink: 0;

    &:hover {
      opacity: 0.8;
    }
  }

  &__line {
    width: 71%;
    max-width: 537px;
    height: 1px;
    border-bottom: 1px solid $light-grey;

    @media screen and (max-width: 1312px) {
      width: 60%;
    }

    @media screen and (max-width: 599px) {
      display: none;
    }
  }
}
