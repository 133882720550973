@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.button {
  @include size(200px, 32px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;

  &_disabled {
    opacity: 0.7;
    user-select: none;
    cursor: default;
  }

  &__icon {
    &_black {
      filter: invert(1);
    }
  }

  &__title {
    margin-left: 12px;
    font-size: 16px;

    &_white {
      color: $white;
    }
  }

  &_gold {
    background-color: $yellow;
    border: none;

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.16),
          rgba(255, 255, 255, 0.16)
        ),
        $yellow;
    }
  }

  &_white {
    background-color: $white;
    border: 1px solid $light-grey;

    &:hover {
      opacity: 0.8;
    }
  }
}
