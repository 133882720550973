@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.resident {
  position: relative;
  max-width: 760px;
  margin-bottom: 47px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: $adaptive) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
  }

  &__person {
    color: $yellow;
    line-height: 0;
  }

  &__trashCan {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &_hide {
      visibility: hidden;
      user-select: none;
    }

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: 1350px) {
      margin-right: 20px;
    }

    @media only screen and (max-width: $adaptive) {
      margin-right: 0;
    }
  }

  &__agreement {
    font-size: 16px;
    margin-bottom: 8px;
    color: $grey;
  }

  &__inputs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px 15px;
    margin-top: 8px;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }

    @media only screen and (max-width: $adaptive) {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0px;
      gap: 16px 16px;
      margin-bottom: 9px;
    }
  }

  &__name {
    width: 243px;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: calc(50% - 8px);
    }

    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &__phone {
    width: 259px;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: calc(50% - 8px);
    }

    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &_renterMode &__phone {
    width: 327px;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: calc(70% - 8px);
    }

    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &__placeBirth {
    width: 485px;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: 100%;
    }
  }

  &__gender {
    width: 74px;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: calc(30% - 8px);
    }

    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &__attitude {
    width: 210px;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: calc(50% - 8px);
    }

    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &__inputsRow {
    display: flex;
    gap: 0 16px;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: 100%;
    }
  }

  &__serial {
    width: 122px;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: calc(50% - 8px);
    }

    @media only screen and (max-width: $breakpoint-sm) {
      width: 50%;
    }
  }

  &__numberPassport {
    width: 122px;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: calc(50% - 8px);
    }

    @media only screen and (max-width: $breakpoint-sm) {
      width: 50%;
    }
  }

  &__date {
    width: 327px;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: calc(50% - 8px);
    }

    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &__dateInput {
    width: 100%;
  }

  &__dateIssued {
    width: 153px;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: 100%;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &_renterMode &__dateIssued {
    @media only screen and (max-width: $breakpoint-xxl) {
      width: calc(50% - 8px);
    }

    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &__dateInput {
    background-color: $white;
  }

  &__reg {
    max-width: 759px;
    width: 100%;
    margin-top: -2px;
    margin-bottom: -4px;

    @media only screen and (max-width: $breakpoint-sm) {
      max-width: none;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  &__divisionCode {
    width: 155px;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: calc(50% - 8px);
    }

    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &_renterMode &__reg {
    width: 592px;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: 100%;
    }
  }

  &__address {
    width: 590px;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: 100%;
    }

    @media only screen and (max-width: 1070px) {
      padding-right: 6px;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      padding-right: 0;
    }
  }
}

@keyframes slowShow {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}
