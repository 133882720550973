@import "../../../../../../styles/mixins.scss";
@import "../../../../../../styles/variables.scss";

.enterPassword {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: center;
  }

  &__desc {
    @include font-text-subtitle-calendar($dark-grey);
    max-width: 367px;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 32px;
    line-height: 24px;

    @media only screen and (max-width: $breakpoint-xm) {
      text-align: center;
      margin-bottom: 46px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      text-align: start;
      align-self: flex-start;
    }
  }

  &__number {
    color: $grey;
    line-height: 24px;
    margin-bottom: 56px;
  }

  &__input {
    max-width: 367px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xm) {
      max-width: 100%;
    }
  }

  &__accept {
    max-width: 367px;
    width: 100%;
    margin-top: 40px;
    font-size: 14px;

    span {
      color: $main-black;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__button {
    max-width: 367px;
    width: 100%;
    margin-top: 32px;
    color: $white;

    @media only screen and (max-width: $breakpoint-xm) {
      max-width: 100%;
      margin-top: 24px;
      margin-bottom: 160px;
    }
  }

  &__login {
    margin-top: 32px;
    text-align: center;
    cursor: pointer;
  }

  &__contentInputPasswordTwo {
    margin: 50px 0 24px 0;
  }
}
