@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.slide {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__slideImg {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @media only screen and (max-width: $adaptive) {
      @include size(375px, 392px);
    }
  }
}
