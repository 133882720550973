@import "../../../../../../styles/mixins.scss";
@import "../../../../../../styles/variables.scss";

.content {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $breakpoint-xs) {
    padding: 0 12px;
  }

  @media screen and (max-width: $adaptive) {
    padding: 0;
    margin-bottom: 77px;
  }

  &__mainLoad {
    position: relative;
    width: 100%;
    height: 400px;
  }

  &__agreements {
    height: max-content;
    max-height: 745px;
    max-width: 800px;
    width: 100%;
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: scroll;
    padding-bottom: 108px;

    @media only screen and (max-width: $breakpoint-lg) {
      padding-right: 8px;
    }
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.08) #ffffff00;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      width: 7px;
      background: rgba(0, 0, 0, 0.08);
      border-radius: 15px;
    }

    @media screen and (max-width: $breakpoint-xm) {
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    @media screen and (max-width: $breakpoint-xs) {
      margin-top: 22px;
      gap: 16px;
    }
  }

  &__agreementsEmpty {
    position: relative;
    width: 100%;
    height: 350px;
  }

  &__empty {
    @include font-text-medium;
    line-height: 40px;
    position: relative;
    width: 100%;
    height: 375px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }

  &__loader {
    top: 0;
  }
}
