@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.polygonItem {
  position: absolute;
  display: inline-block;
  opacity: 1;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &__svg {
    &_blocked {
      fill: $main-black !important;
      fill-opacity: 0.2;

      &:hover {
        fill: $main-black;
        fill-opacity: 0.2;
      }
    }
  }

  &__card {
    display: block;
    position: absolute;
    width: max-content;
    max-width: 119px;
    max-height: max-content;
    height: -moz-max-content;
    background-color: #f7f7f8;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 12px;
    padding: 8px;
    z-index: 1;
  }

  &__cardSubtitle {
    @include font-text-card-floor-subtitle;
    display: block;
  }

  &__cardTitle {
    @include font-text-card-floor-title;
    margin: 0;
  }
}
