@import "../../../styles/variables.scss";

.apartments {
  max-width: $main-container;
  width: 100%;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: $adaptive) {
    max-width: 375px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    max-width: 1160px;
    width: 100%;
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 655px) {
      margin-top: 24px;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
      width: 97%;
    }
  }

  &__wrapper {
    min-height: 100vh;
  }

  &__filters {
    @media only screen and (max-width: $breakpoint-xm) {
      display: none;
    }
  }

  &__filtersBtn {
    display: none;

    @media only screen and (max-width: $breakpoint-xm) {
      display: block;
      width: 100%;
      max-width: 360px;
      margin-top: 24px;
      margin: 24px auto 0 auto;
    }
  }

  &__empty {
    position: relative;
    width: 100%;
    height: 472px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }

  &__flats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 32px;
    gap: 40px;
    position: relative;

    @media only screen and (max-width: $breakpoint-sm) {
      gap: 20px;
    }

    @media only screen and (max-width: $adaptive) {
      width: 360px;
      margin-top: 24px;
      gap: 24px;
    }
  }

  &__flatsEmpty {
    position: relative;
    width: 100%;
    height: 1046px;

    @media only screen and (max-width: 1239px) {
      height: 1770px;
    }
  }

  &__loader {
    left: 48%;
  }

  &__pagination {
    margin-top: 34px;
    margin-bottom: 34px;
    text-align: center;

    @media only screen and (max-width: $adaptive) {
      margin-top: 24px;
    }
  }

  &__footer {
    margin-top: 46px;
    flex-grow: 1;

    @media only screen and (max-width: $adaptive) {
      display: flex;
      margin-top: 24px;
      margin-left: 0;
    }
  }

  &__sorting {
    display: none;

    @media only screen and (max-width: $breakpoint-xm) {
      display: flex;
      justify-content: center;
    }

    @media only screen and (max-width: $adaptive) {
      justify-content: flex-start;
    }
  }

  &__modalSave {
    max-width: 420px;
    line-height: 48px;
    margin-top: 24px;
  }
}
