@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;

  &__clearList {
    @include font-text($grey, 24px);
    align-self: flex-end;
    text-decoration-line: underline;
    cursor: pointer;
    text-align: end;
    margin-bottom: 5px;

    &:hover {
      opacity: 0.8;
    }
  }

  &__servicesText {
    @include font-text-middle($white);
    width: 80%;
    margin-bottom: 20px;
  }

  &__content {
    width: 360px;
    height: max-content;
    min-height: 368px;
    border-radius: 15px;
    background-image: url("../../../../assets/images/greyBack.svg");
    position: relative;
    padding: 25px 15px 16px 32px;

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
      width: 98%;
      margin: 0 auto;
    }
  }

  &__title {
    font-size: 34px;
    line-height: 40px;
    color: $white;
  }

  &__services {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
  }

  &__service {
    margin-right: 16px;
    margin-bottom: 16px;
  }

  &__allServices {
    display: flex;
    width: 100%;
    margin-top: 3px;
    color: $white;
    font-size: 18px;
    text-decoration-line: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &:visited {
      color: $white;
    }
  }

  &__addService {
    margin-top: 12px;
    color: $yellow;
    font-size: 18px;
    text-decoration-line: underline;
    cursor: pointer;
    display: block;

    &:visited {
      color: $yellow;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__price {
    position: relative;
    margin-top: 24px;
  }

  &__total {
    font-size: 18px;
    color: $white;
    margin-bottom: 8px;
  }

  &__button {
    @include btn(56px, 16px 104.5px);
    margin-top: 24px;
    color: $white;

    &_disabled {
      @include buttonDisabled;
      pointer-events: none;
    }

    @media only screen and (max-width: $adaptive) {
      display: none;
    }
  }

  &__error {
    @include font-text-reduced($text-color-error);
    display: flex;
    align-items: center;
    line-height: 15px;
    align-self: center;
    margin-top: 8px;

    @media only screen and (max-width: $adaptive) {
      display: none;
    }
  }
}
