@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.button {
  @include size(72px, 72px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border-radius: 15px;
  border: 1px solid $light-grey;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &__count {
    @include size(24px, 24px);
    position: relative;
    left: 52px;
    bottom: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $yellow;
    border-radius: 38px;
    color: $white;
    user-select: none;
    font-weight: 700;
    padding: 2px 0 0 1px;
  }
}
