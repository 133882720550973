@import "../../../../../../../styles/mixins.scss";
@import "../../../../../../../styles/variables.scss";

.agreement {
  position: relative;
  max-width: 762px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background-color: $white;
  padding: 16px 24px;
  gap: 16px;

  @media screen and (max-width: $main-part) {
    flex-direction: column;
    align-items: flex-end;
    width: 90%;
  }

  @media screen and (max-width: $breakpoint-lg) {
    width: 100%;
    flex-direction: row;
    align-items: center;
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: flex-end;
    width: 90%;
  }

  @media screen and (max-width: $breakpoint-xs) {
    padding: 24px 16px;
    width: 100%;
  }

  &__numberStatusWrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 57%;

    @media screen and (max-width: $main-part) {
      width: 100%;
    }

    @media screen and (max-width: $breakpoint-lg) {
      width: 57%;
    }

    @media screen and (max-width: $breakpoint-sm) {
      width: 60%;
    }

    @media screen and (max-width: 640px) {
      width: 100%;
    }
  }

  &__number {
    max-width: 250px;
    @include font-subtitle-bold;

    @media screen and (max-width: $breakpoint-xs) {
      @include font-text-middle;
      line-height: 24px;
    }
  }

  &__status {
    position: relative;
    display: flex;
    width: 114px;

    @media screen and (max-width: $main-part) {
      justify-content: flex-end;
    }

    @media screen and (max-width: $breakpoint-lg) {
      justify-content: flex-start;
    }

    @media screen and (max-width: 640px) {
      justify-content: flex-end;
    }

    @media screen and (max-width: $breakpoint-xs) {
      font-size: 14px;
    }

    @media screen and (max-width: $adaptive) {
      line-height: 0;
      margin-bottom: 5px;
    }
  }

  &__dissolved {
    color: $grey;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: $breakpoint-sm) {
      width: 40%;
      gap: 0;
      justify-content: flex-end;
    }

    @media screen and (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }

  &__button {
    border: 1px solid $light-grey;
    font-weight: bold;
    @include btn(48px, 12px 26.5px);
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $breakpoint-sm) {
      display: none;
    }

    &:hover {
      background-color: transparent;
      border: 1px solid $light-grey;
      opacity: 0.8;
    }
  }

  &__downloadAdaptive {
    display: none;
    @media screen and (max-width: $breakpoint-sm) {
      @include btn(56px, 16px 40px);
      height: 48px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: $main-black;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      border: 1px solid $light-grey;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      background-color: $white;

      &:hover {
        background-color: transparent;
        border: 1px solid $light-grey;
        opacity: 0.8;
      }
    }

    @media screen and (max-width: $breakpoint-xs) {
      width: 100%;
      max-width: 360px;
    }
  }

  &__menuWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__menuLoading {
    position: relative;
    width: 48px;
    height: 100%;

    @media screen and (max-width: $breakpoint-sm) {
      height: 56px;
    }
  }

  &__loader {
    top: 0;
  }

  &__menu {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media screen and (max-width: $breakpoint-sm) {
      display: none;
    }
  }

  &__dropList {
    top: 53px;
    right: 0;
  }
}
