@import "../../../../../../../styles/variables.scss";
@import "../../../../../../../styles/mixins.scss";

.enterNamePhone {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: center;
  }

  &__content {
    width: 479px;
    min-height: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 56px;
    position: absolute;
    top: 0;
    right: 0;
  }
  &__desc {
    max-width: 367px;
    width: 100%;
    margin-top: 16px;
    color: $dark-grey;
    margin-bottom: 32px;

    @media only screen and (max-width: $breakpoint-xm) {
      text-align: center;
      line-height: 24px;
      margin-bottom: 24px;
      margin-top: 24px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      text-align: start;
      align-self: flex-start;
    }
  }

  &__number {
    color: $grey;
    line-height: 24px;
    margin-bottom: 56px;
  }

  &__input {
    max-width: 372px;
    width: 100%;
    margin-bottom: 16px;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-bottom: 36px;
    }
  }

  &__accept {
    margin-top: 16px;
    font-size: 14px;
    max-width: 367px;
    width: 100%;
    span {
      color: $main-black;
      text-decoration: underline;
      cursor: pointer;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 13px;
    }
  }

  &__signup {
    align-self: center;
    margin-top: 32px;
  }

  &__button {
    margin-top: 32px;
    max-width: 367px;
    width: 100%;
    color: $white;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 24px;
    }

    &_disabled {
      @include buttonDisabled;

      &:hover {
        @include buttonDisabled;
        opacity: 1;
      }
    }

    &_noEvents {
      pointer-events: none;
      @include buttonDisabled;

      &:hover {
        @include buttonDisabled;
        opacity: 1;
      }
    }
  }

  &__login {
    margin-top: 32px;
    text-align: center;
    cursor: pointer;
    align-self: center;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 24px;
    }
  }

  &__sendAgainBtn {
    @include font-text;
    position: relative;
    margin: 0 auto;
    background-color: transparent;
    border: none;
    cursor: pointer;
    pointer-events: none;

    &_active {
      pointer-events: all;
      &:hover {
        opacity: 0.85;
      }
    }
  }

  &__sendAgainSeconds {
    @include font-text($dark-grey);

    &_hide {
      display: none;
    }
  }

  &__contentInputPasswordTwo {
    margin-top: 52px;
    margin-bottom: 50px;
  }
}
