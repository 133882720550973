@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.success {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $popup-z-index;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-height: 465px) and (min-width: $breakpoint-xm) {
    align-items: flex-start;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__popupWrapper {
    @include popupSpecialWrapper(560px);
  }

  &__popup {
    position: relative;
    width: 100%;
    background-color: $white;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 32px;

    @media only screen and (max-width: $breakpoint-xm) {
      @include popupScrollAdaptive;
      padding: 32px 8px 24px 8px;
    }
  }

  &__content {
    width: 100%;
    max-width: 496px;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $breakpoint-xm) {
      justify-content: space-between;
      height: 100%;
    }
  }

  &__close {
    position: relative;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 3px;
    margin-left: 3px;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      margin-right: 15px;
    }
  }

  &__title {
    color: $main-black;
    max-width: 400px;
    width: 100%;
    font-size: 32px;
    line-height: 48px;
    margin-top: 28px;
    margin-bottom: 34px;
  }

  &__img {
    max-width: 496px;
    width: max-content;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 55px;
    }

    @media only screen and (max-width: $adaptive) {
      margin-top: -8px;
    }
  }

  &__button {
    margin-top: 19px;
    max-width: 496px;
    width: 100%;
    color: $white;
  }
}
