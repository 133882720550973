@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/variables.scss";

.modal {
  &__contentWrapper {
    width: 100%;
    min-height: 100%;
    height: max-content;
    background-color: $white;

    @media only screen and (max-width: $breakpoint-xm) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__descsiption {
    @include font-text($dark-grey);
    width: 280px;
    margin-top: 24px;
    color: $dark-grey;
    margin-bottom: 56px;

    @media only screen and (max-width: $breakpoint-xm) {
      text-align: center;
      line-height: 24px;
      margin-bottom: 47px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      text-align: start;
      align-self: flex-start;
    }
  }

  &__input {
    max-width: 367px;
    width: 100%;
    margin-bottom: 32px;
  }

  &__recovery {
    @include font-text-subtitle-calendar($main-black);
    display: block;
    width: max-content;
    height: 24px;
    margin: 70px auto 0 auto;
    text-align: center;
    cursor: pointer;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 36px;
    }
  }

  &__button {
    margin-top: 36px;
    max-width: 367px;
    width: 100%;
    color: $white;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 24px;
    }
  }

  &__signup {
    @include font-text;
    display: block;
    width: max-content;
    height: 24px;
    margin: 32px auto 0 auto;
    text-align: center;
    cursor: pointer;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 24px;
      margin-bottom: 40px;
    }
  }

  &__contentTitle {
    @include font-title-big;

    @media only screen and (max-width: $breakpoint-xs) {
      text-align: start;
      align-self: flex-start;
    }
  }
}
