@import "../../../../../styles/mixins.scss";

.container {
  @include size(40px, 40px);
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
