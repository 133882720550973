@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.feedback {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $popup-z-index;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-height: 570px) and (min-width: $breakpoint-xm) {
    align-items: flex-start;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__popupWrapper {
    @include popupSpecialWrapper(560px);
  }

  &__popup {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 560px;
    width: 100%;
    background-color: $white;
    border-radius: 24px;
    padding: 40px 32px;

    @media only screen and (max-width: $breakpoint-xm) {
      @include popupScrollAdaptive;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 36px 8px;
    }
  }

  &__contentWrapper {
    position: relative;
    width: 100%;
    height: max-content;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__input {
    width: 100%;
    max-width: 496px;
    height: 96px;
    margin-bottom: 32px;
    @media only screen and (max-width: $breakpoint-xm) {
      margin-bottom: 24px;
    }
  }
  &__close {
    align-self: flex-end;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      margin-right: 17px;
    }
  }

  &__title {
    align-self: flex-start;
    color: $main-black;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.04em;
    margin-top: 20px;
    margin-bottom: 32px;
    text-transform: uppercase;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 11px;
      margin-bottom: 20px;
      text-transform: none;
    }
  }

  &__text {
    width: 362px;
    padding-left: 16px;
    font-size: 20px;
    line-height: 32px;
    color: $main-black;

    &_underline {
      border-bottom: 1px solid $main-black;
      cursor: pointer;
    }
  }

  &__button {
    max-width: 496px;
    width: 100%;
    height: 56px;
    color: $white;

    @media only screen and (max-width: $breakpoint-xm) {
      @include btn(56px, 16px 132px);
    }
  }

  &__politics {
    max-width: 432px;
    width: 97%;
    margin-bottom: 36px;
    font-size: 14px;
    line-height: 20px;
    color: $grey;
    align-self: flex-start;
  }

  &__personal {
    color: $main-black;
    cursor: pointer;
    text-decoration: underline;
  }
}
