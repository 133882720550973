@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.popupAllServices {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 999;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__popupWrapper {
    @include popupSpecialWrapper(744px);
    margin: auto;

    @media only screen and (max-width: $breakpoint-xm) {
      margin: 0;
    }
  }

  &__popup {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 744px;
    background-color: $white;
    border-radius: 24px;
    padding: 32px;

    @media screen and (max-width: $breakpoint-xm) {
      @include popupScrollAdaptive;
    }
  }

  &__close {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media screen and (max-width: $breakpoint-xm) {
      display: none;
    }
  }

  &__closeIcon {
    width: 16px;
    height: 16px;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include font-text-big;
    margin-top: 32px;
    margin-bottom: 16px;

    @media screen and (max-width: $breakpoint-xm) {
      margin-top: 0;
    }
  }

  &__servicesList {
    max-width: 641px;
    width: 100%;
    max-height: 300px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 8px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.08) #ffffff00;

    &::-webkit-scrollbar {
      width: 7px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.08);
      border-radius: 15px;
    }

    @media screen and (max-width: $breakpoint-xm) {
      max-height: max-content;
      overflow: visible;
    }
  }

  &__serviceItem {
    @include font-text-reduced($main-black, 16px);
    display: flex;
    align-items: center;
    border: 1px solid $light-grey;
    border-radius: 40px;
    padding: 8px 12px 8px 12px;

    &:hover {
      opacity: 0.85;
      border: 1px solid $grey;
      cursor: pointer;
    }
  }

  &__serviceTitle {
    width: max-content;
  }

  &__serviceDelete {
    display: block;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }
  }

  &__deleteIcon {
    width: 8px;
    height: 8px;
  }

  &__button {
    @include font-text-button-bold;
    width: 100%;
    max-width: 284px;
    margin-top: 32px;
    align-self: flex-end;

    @media screen and (max-width: $breakpoint-xm) {
      align-self: center;
      max-width: 100%;
    }
  }
}
