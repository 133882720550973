@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  max-width: $main-container;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: $adaptive) {
    max-width: 375px;
  }

  &__content {
    max-width: 1160px;
    width: 100%;
    padding: 88px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - $footer-height - $header-height);

    @media only screen and (max-width: $adaptive) {
      margin-top: 68px;
      height: calc(100vh - $footer-height-adaptive - $header-height-adaptive);
    }
  }

  &__button {
    width: 260px;
    color: $white;
    margin-top: 25px;
  }

  &__title {
    max-width: 500px;
    width: 98%;
    color: $main-black;
    text-align: center;
    margin-top: 24px;

    @media only screen and (max-width: $service) {
      font-size: 24px;
    }
  }

  &__description {
    margin-top: 24px;
    color: $dark-grey;

    @media only screen and (max-width: $service) {
      max-width: 290px;
      width: 97%;
      text-align: center;
    }
  }

  &__footer {
    max-width: 1160px;
    flex-grow: 1;

    @media only screen and (max-width: $adaptive) {
      display: flex;
      margin-top: 148px;
      margin-left: 0;
    }
  }

  &__loaderBlock {
    width: 100%;
    height: calc(100vh - $footer-height - $header-height);
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: $adaptive) {
      height: calc(100vh - $footer-height-adaptive - $header-height-adaptive);
    }
  }

  &__loader {
    top: auto;
    bottom: auto;
  }
}
