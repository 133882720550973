@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.modal {
  height: 100%;
  width: 100%;
  max-width: 1480px;
  background-color: rgba(0, 0, 0, 0.48);
  position: fixed;
  top: 0;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: $popup-z-index;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__contentWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 479px;
    height: max-content;
    min-height: 100%;
    background-color: $white;
    animation: 0.8s ease showModal;

    @media only screen and (max-width: $breakpoint-xm) {
      width: 100%;
      max-width: 100%;
      min-height: auto;
      height: max-content;
      max-height: 90%;
      position: relative;
      border-radius: 24px 24px 0 0;
      animation: 0.8s ease showModalAdaptive;
    }
  }

  &__content {
    width: 100%;
    height: max-content;
    background-color: $white;
    padding: 56px 51px 56px 56px;

    @media only screen and (max-width: $breakpoint-xm) {
      @include popupScrollAdaptive;
      padding: 32px 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__close {
    position: absolute;
    top: 48px;
    right: 511px;
    cursor: pointer;

    @media only screen and (max-width: $breakpoint-xm) {
      display: none;
    }
  }

  &__closeAdaptive {
    display: none;

    @media only screen and (max-width: $breakpoint-xm) {
      display: block;
      align-self: flex-end;
      margin-bottom: 15px;
      margin-right: 15px;
    }
  }
}

@keyframes showModal {
  0% {
    right: -100%;
  }

  100% {
    right: 0;
  }
}

@keyframes showModalAdaptive {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0;
  }
}
