@import "../../../styles/variables.scss";

.personal {
  max-width: $main-container;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 96px);

  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: $breakpoint-xxl) {
      justify-content: flex-start;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      justify-content: center;
      margin: 0 auto;
    }
  }

  &__menu {
    max-width: 480px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: 35%;
      min-width: 300px;
    }
  }

  &__content {
    max-width: 920px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: 90%;
    }

    @media only screen and (max-width: $adaptive) {
      width: 100%;
    }
  }

  &__footer {
    max-width: 1160px;
    width: 100%;
    margin-top: 16px;
    margin-left: 120px;

    @media only screen and (max-width: 1380px) {
      margin-left: 40px;
    }

    @media only screen and (max-width: 1300px) {
      margin-left: 15px;
      margin-top: 40px;
    }

    @media only screen and (max-width: 1256px) {
      margin-left: 0;
    }

    @media only screen and (max-width: $adaptive) {
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: $breakpoint-lg) {
  .personal {
    &__menu {
      display: none;
    }
  }
}

@media only screen and (max-width: $adaptive) {
  .personal {
    &__menu {
      display: none;
    }
  }
}
