@import "../../../../../../../styles/variables.scss";
@import "../../../../../../../styles/mixins.scss";

.enterPhone {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__desc {
    max-width: 367px;
    width: 100%;
    margin-top: 16px;
    color: $dark-grey;
    margin-bottom: 56px;

    @media only screen and (max-width: $breakpoint-xm) {
      text-align: center;
      margin-top: 24px;
      margin-bottom: 48px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      text-align: start;
      align-self: flex-start;
    }
  }

  &__input {
    max-width: 367px;
    width: 100%;
  }

  &__accept {
    max-width: 367px;
    width: 100%;
    margin-top: 56px;
    font-size: 14px;

    span {
      color: $main-black;
      text-decoration: underline;
      cursor: pointer;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 44px;
    }
  }

  &__signup {
    align-self: center;
    margin-top: 30px;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 24px;
      margin-bottom: 90px;
    }
  }

  &__button {
    max-width: 367px;
    width: 100%;
    margin-top: 31px;
    color: $white;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 24px;
    }
  }
}
