@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.payOrder {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__empty {
    position: relative;
    width: 100%;
    height: 400px;
  }

  &__loader {
    top: 80%;
    left: 48%;
  }

  &__stepsWrapper {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: $breakpoint-xm) {
      width: 100%;
      padding: 0 7px 0 8px;
    }
  }

  &__tenantsWrapper {
    max-width: 1190px;
    padding: 0 15px;

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
      padding: 0;
    }
  }

  &__title {
    font-size: 34px;
    line-height: 40px;

    @media only screen and (max-width: $adaptive) {
      font-size: 32px;
      line-height: 48px;
    }
  }

  &__description {
    margin-top: 24px;
  }

  &__tenants {
    margin-top: 38px;

    @media only screen and (max-width: $adaptive) {
      margin-top: -3px;
    }
  }

  &__addTenant {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: -9px;
    position: relative;
    margin-right: 8px;

    &::before {
      content: "";
      height: 1px;
      min-width: 73%;
      background-color: $light-grey;
      position: absolute;
      left: 10px;
      font-size: 64px;
      line-height: 64px;
      color: $light-grey;

      @media only screen and (max-width: 872px) {
        display: none;
      }
    }

    @media only screen and (max-width: $adaptive) {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__buttonWrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__commonError {
    @include font-text-reduced($text-color-error);
    display: flex;
    align-items: center;
    position: absolute;
    top: 5px;
  }

  &__button {
    width: 311px;
    margin-top: 40px;
    margin-bottom: 24px;
    color: $white;

    @media only screen and (max-width: $adaptive) {
      text-align: center;
      margin-top: 48px;
      margin-bottom: 24px;
      margin-left: 0;
    }
  }

  &__modalReuse {
    padding: 44px 32px 40px 32px;
    width: 560px;
  }

  &__modalReuseTitle {
    @include font-text-big;
    margin-top: 18px;
  }

  &__footer {
    max-width: 1160px;
    margin-top: 78px;

    @media screen and (max-width: $breakpoint-xm) {
      margin-top: 56px;
    }
  }
}
