@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.stepTenants {
  max-width: 1161px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 49px;

  @media screen and (max-width: $breakpoint-xm) {
    margin-top: 25px;
  }

  &__backBtn {
    align-self: flex-start;
    margin-bottom: 24px;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    row-gap: 23px;

    @media screen and (max-width: $breakpoint-xm) {
      row-gap: 24px;
    }
  }

  &__title {
    @include font-text-big;
    line-height: 40px;
    margin-right: 24px;

    @media screen and (max-width: $breakpoint-xm) {
      width: 87%;
    }
  }

  &__stepNum {
    @include font-subtitle-light($yellow);
  }

  &__mainContent {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 28px;
  }

  &__subtitle {
    @include font-text-middle($yellow);
    margin-bottom: 12px;

    @media screen and (max-width: $breakpoint-xm) {
      margin-bottom: 20px;
    }

    &_children {
      @media screen and (max-width: $breakpoint-xm) {
        margin-bottom: 12px;
      }
    }
  }

  &__adultsBlock {
    width: 100%;
  }

  &__childrenBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 45px;

    @media screen and (max-width: $breakpoint-sm) {
      margin-top: 23px;
    }
  }

  &__counter {
    color: $main-black;
  }

  &__counterButton {
    color: $main-black;
    background: transparent;
    border: 1px solid $main-black;
  }

  &__counterNum {
    color: $main-black;
  }

  &__counterBlock {
    width: 100%;
    max-width: 1160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
    background-color: $white;
    border: 1px solid $light-grey;
    border-radius: 8px;
    padding: 27px 32px;

    @media screen and (max-width: $breakpoint-xs) {
      padding: 16px;
      gap: 6px;
    }
  }

  &__counterBlockText {
    @include font-text-medium;
    line-height: 40px;

    @media screen and (max-width: $breakpoint-xs) {
      @include font-text($main-black, 24px);
    }
  }

  &__tenantsCards {
    position: relative;
    width: 100%;
    max-width: 1160px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $breakpoint-xm) {
      margin-bottom: 24px;
    }
  }

  &__tenantCard {
    margin-top: 80px;
  }

  &__addTenantContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 39px;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      max-width: 860px;
      height: 1px;
      border-top: 1px solid $light-grey;
      left: 0;

      @media only screen and (max-width: $breakpoint-xlg) {
        width: 75%;
      }

      @media only screen and (max-width: $breakpoint-lg) {
        display: none;
      }
    }

    @media screen and (max-width: $breakpoint-xm) {
      margin: 0;
    }
  }

  &__mainError {
    @include font-text-reduced($text-color-error);
    display: flex;
    align-items: center;
    position: absolute;
    text-align: end;
    line-height: 15px;
    bottom: 60px;
  }

  &__button {
    width: 100%;
    max-width: 311px;
    margin-top: 63px;

    @media screen and (max-width: $breakpoint-xm) {
      max-width: 365px;
      margin-top: 29px;
    }
  }

  &__addTenantsBtn {
    &_disabled {
      @include buttonDisabled;
      cursor: default;

      &:hover {
        @include buttonDisabled;
        opacity: 1;
      }
    }
  }

  //-------- no tenants

  &__noTenantsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $white;
    border: 1px solid $light-grey;
    border-radius: 8px;
    padding: 32px 24px;

    @media screen and (max-width: $breakpoint-xs) {
      padding: 16px;
    }
  }

  &__noTenantsParagraph {
    @include font-text-medium;
    line-height: 40px;
    align-self: flex-start;

    @media screen and (max-width: $breakpoint-xs) {
      @include font-text($main-black, 24px);
    }
  }

  &__noTenantsButton {
    width: 100%;
    max-width: 311px;
    margin-top: 31px;

    @media screen and (max-width: $breakpoint-xm) {
      max-width: 365px;
      margin-top: 24px;
    }
  }

  &__loader {
    position: fixed;
    top: 40%;
    bottom: auto;
  }
}
