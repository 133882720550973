.navigate {
  display: flex;
  align-items: center;

  &__button {
    cursor: pointer;

    &:hover {
      filter: invert(1);
      transition: 400ms ease-in-out;
    }
  }

  &__text {
    font-size: 20px;
    margin-left: 16px;
    margin-top: 1px;
  }
}
