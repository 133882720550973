@import "../../../../../styles/variables.scss";

.list {
  width: 200px;
  border-radius: 16px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.24);
  position: absolute;
  background-color: $white;
  display: flex;
  flex-direction: column;
  z-index: 1;

  &__item {
    height: 48px;
    text-align: center;
    border-bottom: 1px solid $light-grey;
    padding: 12px 0;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      color: $main-black;
    }
  }
}
