@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.facilities {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $popup-z-index;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__contentWrapper {
    @include popupSpecialWrapper(744px);
  }

  &__content {
    width: 100%;
    height: 528px;
    background-color: $white;
    border-radius: 24px;
    padding: 36px 32px 32px 32px;
    position: relative;

    @media only screen and (max-width: $breakpoint-xm) {
      @include popupScrollAdaptive;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 35px 8px 0 8px;
    }
  }

  &__display {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @media only screen and (max-width: $breakpoint-xm) {
      width: 100%;
      align-items: center;
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 36px;

    @media only screen and (max-width: $breakpoint-xm) {
      right: 25px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__filtersM {
    display: none;

    @media only screen and (max-width: $breakpoint-xm) {
      display: block;
    }
  }

  &__titleBtnWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 26px;
    }
  }

  &__title {
    margin-top: 32px;
    line-height: 48px;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 12px;
    }
  }

  &__secondTitle {
    margin-top: 24px;
    line-height: 48px;
  }

  &__thirdTitle {
    margin-top: 32px;
    line-height: 48px;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 8px;
    }
  }

  &__listFirst,
  &__listSecond {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    row-gap: 16px;
    column-gap: 8px;
    max-height: 80px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.08) #ffffff00;

    &::-webkit-scrollbar {
      width: 7px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.08);
      border-radius: 15px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
      overflow-y: visible;
      max-height: max-content;
    }
  }

  &__listFirst {
    @media only screen and (max-width: $breakpoint-xm) {
      margin-bottom: 24px;
    }
  }

  &__listSecond {
    @media only screen and (max-width: $breakpoint-xm) {
      margin-bottom: 21px;
    }
  }

  &__button {
    @include btn(56px, 16px 95px);
    color: $white;
    position: relative;
    align-self: flex-end;

    @media only screen and (max-width: $breakpoint-xm) {
      width: 100%;
      color: $white;
      margin-top: 7px;
      position: relative;
      margin-bottom: 24px;
    }
  }

  &__buttonClearAll {
    @include font-text($main-black, 24px);
    cursor: pointer;
    margin-top: 7px;
  }

  &__checkbox {
    margin: 0;
  }

  &__conditionsListsWrapper {
    margin-top: 27px;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 0;
      width: 100%;
    }
  }

  &__inputs {
    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 16px;
    }
  }

  &__checkboxContainer {
    display: none;

    @media only screen and (max-width: $breakpoint-xm) {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: 24px;
      align-self: flex-start;
    }
  }

  &__select {
    @media only screen and (max-width: $breakpoint-xm) {
      width: 100%;
    }
    @media only screen and (max-width: $breakpoint-xm) {
      margin-bottom: 24px;
    }
  }

  &__checkboxContainer {
    display: none;

    @media only screen and (max-width: $breakpoint-xm) {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: 24px;
      align-self: flex-start;
    }
  }

  &__fromTo {
    position: relative;
    @media only screen and (max-width: $breakpoint-xm) {
      display: flex;
      justify-content: space-between;
      gap: 18px;
    }
  }

  &__fromToinput {
    @media only screen and (max-width: $breakpoint-xm) {
      width: 168px;
    }
  }

  &__pricesError {
    @include font-text-reduced($text-color-error);
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    bottom: -25%;
    z-index: 10;
  }

  &__buttonClearFacilities {
    @include font-text($main-black, 24px);
    display: block;
    position: absolute;
    bottom: 47px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
    }

    @media screen and (max-width: 576px) {
      display: none;
    }
  }
}

@keyframes showModalAdaptive {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0;
  }
}
