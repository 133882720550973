@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.sort {
  color: $grey;
  display: flex;
  @media only screen and (max-width: $adaptive) {
    align-self: flex-start;
  }
  &__sorting {
    cursor: pointer;
    position: relative;
  }
  &__type {
    color: $yellow;
    margin-left: 4px;
    &:hover {
      opacity: 0.7;
    }
  }
  &__arrow {
    margin-left: 10px;
    width: 10px;
  }
  &__rotate {
    transform: rotate(180deg);
  }
}
