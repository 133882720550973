@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.textPage {
  max-width: 1330px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 55px;
  padding: 0 15px;

  &__empty {
    position: relative;
    width: 100%;
    height: 100vh;
  }

  &__loader {
    left: 50%;
    top: 40%;
  }

  &__title {
    @include font-title-big;
    max-width: 560px;
    margin-bottom: 8px;
    text-transform: uppercase;
    text-align: center;

    @media screen and (max-width: $breakpoint-xm) {
      @include font-text-medium;
      width: 98%;
    }
  }

  &__text {
    position: relative;
    max-width: 960px;
    width: 100%;
    margin-bottom: 168px;

    @media screen and (max-width: 1285px) {
      max-width: 1160px;
      padding-right: 20px;
    }

    @media screen and (max-width: $breakpoint-xm) {
      padding-right: 8px;
    }
  }

  h6 {
    font-weight: bold;
    text-align: center;
    margin: 24px 0;

    @media screen and (max-width: 1285px) {
      position: relative;
      left: 0;
      margin-bottom: 10px;
    }
  }

  p {
    @include font-text-reduced($dark-grey, 20px);
  }

  &__noContent {
    margin-top: 84px;
    margin-bottom: 225px;
    margin-left: 20px;

    @media screen and (max-width: $breakpoint-xxl) {
      margin-left: 0;
    }
  }

  &__footer {
    max-width: 1160px;
    padding: 0 15px;
    margin: 0 auto;
  }
}
