@import "../../../styles/variables.scss";

.loader {
  position: absolute;
  width: min-content;
  height: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  left: 0;
  right: 0;
  top: 300px;
  bottom: 0;
  margin: auto;

  &__inner {
    width: 48px;
    height: 48px;
    border: 5px solid $dark-grey;
    border-bottom-color: $yellow;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
