@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.counter {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    @include font-subtitle-bold($white);
    font-weight: 400;

    @media only screen and (max-width: $breakpoint-xm) {
      color: $main-black;
    }
  }

  &__subtitle {
    @include font-text-reduced($grey, 20px);
  }

  &__buttonsWrapper {
    display: flex;
    align-items: center;
    gap: 17px;

    @media only screen and (max-width: $breakpoint-xm) {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  &__button {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.24);
    border: 1px solid $white;
    border-radius: 50%;
    color: $white;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover {
      opacity: 0.8;
    }

    &_disabled {
      opacity: 0.7;

      &:hover {
        opacity: 0.7;
      }
    }

    @media only screen and (max-width: $breakpoint-xm) {
      color: $main-black;

      background: rgba(34, 34, 34, 0.08);
      @include size(40px, 40px);
      border-radius: 50%;
      border: 1px solid $main-black;
    }
  }

  &_bigMode &__button {
    width: 48px;
    height: 48px;
    font-size: 20px;

    @media screen and (max-width: $breakpoint-xs) {
      width: 41px;
      height: 41px;
    }
  }

  &_bigMode &__plus {
    font-size: 24px;
    margin-top: 4px;
  }

  &__count {
    line-height: 20px;
    color: $white;
    width: 14px;
    text-align: center;

    @media only screen and (max-width: $breakpoint-xm) {
      color: $main-black;
    }
  }

  &_bigMode &__count {
    @include font-text-medium;
    line-height: 48px;

    @media only screen and (max-width: $breakpoint-xm) {
      line-height: 40px;
    }
  }
}
