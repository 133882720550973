@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.textArea {
  display: flex;
  flex-direction: column;
  position: relative;

  &__label {
    color: $grey;
    font-size: 14px;
    line-height: 20px;
  }

  &__area {
    @include size(432px, 144px);
    resize: none;
    border-radius: 15px;
    background-color: $white;
    border: 1px solid $light-grey;
    padding-top: 17px;
    padding-left: 15px;
    font-size: 24px;
    line-height: 32px;

    &::placeholder {
      color: $grey;
    }

    @media (max-width: $breakpoint-xm) {
      @include size(360px, 144px);
    }
  }
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.08) #ffffff00;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #dfdfdf;
    border-radius: 100px;
  }

  &__errorText {
    @include font-text-reduced($text-color-error);
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: -25px;
  }
}
