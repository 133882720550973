@import "../../../../../../../styles/variables.scss";
@import "../../../../../../../styles/mixins.scss";

.enterFio {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: center;
  }

  &__input {
    max-width: 372px;
    width: 100%;
    margin-bottom: 16px;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-bottom: 36px;
    }
  }

  &__button {
    margin-top: 15px;
    max-width: 372px;
    width: 100%;
    color: $white;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 24px;
    }

    &_disabled {
      @include buttonDisabled;

      &:hover {
        @include buttonDisabled;
        opacity: 1;
      }
    }

    &_noEvents {
      pointer-events: none;
      @include buttonDisabled;

      &:hover {
        @include buttonDisabled;
        opacity: 1;
      }
    }
  }

  &__desc {
    max-width: 367px;
    width: 100%;
    margin-top: 16px;
    color: $dark-grey;
    margin-bottom: 32px;

    @media only screen and (max-width: $breakpoint-xm) {
      text-align: center;
      line-height: 24px;
      margin-bottom: 24px;
      margin-top: 24px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      text-align: start;
      align-self: flex-start;
    }
  }

  &__radioButtonsWrapper {
    margin-bottom: 33px;

    @media only screen and (max-width: $breakpoint-xm) {
      max-width: 367px;
      margin: 0 auto 51px auto;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
  }
}
