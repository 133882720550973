@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.account {
  max-width: 480px;
  width: 100%;
  min-height: 792px;
  background-color: $yellow;
  position: relative;

  @media (max-width: 1180px) {
    max-width: 420px;
    width: 420px;
  }

  @media (max-width: $adaptive) {
    display: none;
  }

  &__menu {
    padding-top: 40px;
    position: relative;

    &::before {
      content: "";
      height: 1px;
      min-width: 100%;
      background-color: rgba(0, 0, 0, 0.16);
      position: absolute;
      top: 90.7%;
    }
  }

  &__navTop {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: $account-menu-paddingLeft;

    @media only screen and (max-width: $breakpoint-xxl) {
      padding-left: $account-menu-paddingLeft-min;
    }
  }

  &__navBottom {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: $account-menu-paddingLeft;
    margin-top: 49px;
    position: relative;

    @media only screen and (max-width: $breakpoint-xxl) {
      padding-left: $account-menu-paddingLeft-min;
    }

    &::before {
      content: "";
      height: 1px;
      min-width: 38.2%;
      background-color: $white;
      position: absolute;
      left: $account-menu-paddingLeft;
      top: -27px;
      opacity: 0.64;

      @media only screen and (max-width: $breakpoint-xxl) {
        left: $account-menu-paddingLeft-min;
      }
    }
  }

  &__navTitle {
    @include font-subtitle-bold($white);
    font-weight: 400;
    cursor: pointer;
    user-select: none;
    color: rgba(255, 255, 255, 0.64);
    font-size: 20px;
    width: 60%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover {
      color: $white;
    }

    &_active {
      color: $white;
      font-weight: 700;
    }
  }

  &__subMenu {
    cursor: pointer;
    user-select: none;
    color: rgba(255, 255, 255, 0.64);
    padding-left: 24px;
    display: flex;
    font-size: 20px;
    width: 50%;
    margin-top: 16px;

    &:hover {
      color: $white;
    }
  }

  &__openDocumnts {
    color: $white;
  }

  &__profileEmpty {
    position: relative;
    max-width: 440px;
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    padding-left: 25.2%;
    margin-bottom: 48px;
    padding: 30px 0;

    @media only screen and (max-width: $advantages-xl) {
      padding-left: 7%;
    }
  }

  &__profile {
    position: relative;
    max-width: 440px;
    display: flex;
    align-items: center;
    padding-left: $account-menu-paddingLeft;
    gap: 16px;
    margin-bottom: 48px;

    @media only screen and (max-width: $breakpoint-xxl) {
      padding-left: $account-menu-paddingLeft-min;
    }
  }

  &__profileAvatarWrapper {
    @include size(64px, 64px);
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
  }

  &__profileAvatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__profileName {
    color: $white;
    font-size: 24px;
    line-height: 32px;

    &_small {
      font-size: 20px;
      line-height: 23px;
    }

    &_supersmall {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__exit {
    padding-left: $account-menu-paddingLeft;
    margin-top: 217px;
    margin-bottom: 104px;

    &_legalMode {
      margin-top: 169px;
    }

    @media only screen and (max-width: $breakpoint-xxl) {
      padding-left: $account-menu-paddingLeft-min;
    }
  }

  &__quitBtn {
    color: $white;
    font-size: 18px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
