@import "../../../../../../../styles/mixins.scss";
@import "../../../../../../../styles/variables.scss";

$breakpoint-bills-middle: 1160px;

.bill {
  max-width: 762px;
  width: 100%;
  border-radius: 16px;
  background-color: $white;
  padding-bottom: 16px;

  @media screen and (max-width: $main-part) {
    width: 97%;
  }

  @media screen and (max-width: $breakpoint-xs) {
    width: 100%;
  }

  &__top {
    max-width: 762px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    position: relative;
    border-bottom: 1px solid $light-grey;

    @media screen and (max-width: $breakpoint-bills-middle) {
      flex-direction: column;
    }

    @media screen and (max-width: $breakpoint-xs) {
      padding: 20px 16px 15px 16px;
    }
  }

  &__numberStatusWrapper {
    width: 57%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $breakpoint-bills-middle) {
      width: 100%;
    }
  }

  &__number {
    @include font-subtitle-bold;
    font-weight: 600;

    @media screen and (max-width: $breakpoint-xs) {
      font-weight: 400;
    }
  }

  &__bottom {
    max-width: 762px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px 0 24px;
    position: relative;

    @media screen and (max-width: $breakpoint-bills-middle) {
      flex-wrap: wrap;
      gap: 15px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      padding: 13px 16px 3px 16px;
    }

    @media screen and (max-width: $adaptive) {
      flex-wrap: wrap;
      row-gap: 16px;
    }
  }

  &__title {
    color: $grey;

    @media screen and (max-width: $breakpoint-xm) {
      @include font-text-reduced(16px);
    }
  }

  &__info {
    font-size: 18px;
    width: max-content;

    @media screen and (max-width: $breakpoint-xm) {
      @include font-text-reduced(16px);
    }
  }

  &__status {
    position: relative;
    width: 35%;
    max-width: 190px;
    display: flex;

    &_paid {
      color: $paid;
    }

    &_unpaid {
      color: $expired;
    }

    &_pending {
      color: $main-black;
    }

    &_particallyPaid {
      color: $yellow;
    }

    @media screen and (max-width: $breakpoint-xl) {
      margin-left: 16px;
    }

    @media screen and (max-width: $breakpoint-bills-middle) {
      justify-content: flex-end;
    }
  }

  &__expired {
    color: $expired;
  }

  &__paid {
    color: $paid;
  }

  &__payTill {
    margin-left: 70px;

    @media screen and (max-width: $breakpoint-xl) {
      margin-left: 0;
    }
  }

  &__price {
    max-width: 210px;
    width: 30%;

    @media screen and (max-width: $breakpoint-lg) {
      width: max-content;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: $breakpoint-bills-middle) {
      align-self: flex-end;
      margin-top: 16px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      width: 100%;
      margin-top: 12px;
    }
  }

  &__button {
    border: 1px solid $light-grey;
    font-weight: bold;
    @include btn(48px, 12px 26.5px);
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: transparent;
      border: 1px solid $light-grey;
      opacity: 0.8;
    }

    @media screen and (max-width: $breakpoint-xs) {
      width: 90%;
      max-width: 263px;
    }

    &_disabled {
      border: 1px solid $light-grey;
      opacity: 0.4;
      background-color: $white;

      &:hover {
        opacity: 0.4;
      }
    }
  }

  &__menuWrapper {
    position: relative;
    width: max-content;
    height: 48px;
  }

  &__menu {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__dropList {
    position: absolute;
    top: 58px;
    right: 0px;
  }

  &__menuLoading {
    position: relative;
    width: 48px;
    height: 100%;

    @media screen and (max-width: $breakpoint-sm) {
      height: 56px;
    }
  }

  &__loader {
    top: 0;
  }
}
