@import "variables";
@import "mixins";
@import "./fonts/stylesheet.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-width: 320px;
  background-color: $bg;
  font-family: "Muller", sans-serif;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden auto;
  &.overflow-hidden {
    overflow: hidden;
  }
  &.overflow-Y-hidden {
    overflow-y: hidden;
  }
}

.app {
  width: 100%;
  max-width: 1480px;
  margin: 0 auto;
}

.wrapper {
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
}

p {
  font-family: Muller;
  color: $main-black;
}

textarea {
  font-family: Muller;
}

input {
  font-family: Muller;
  outline: none;
  &:focus {
    border: 1px solid $main-black;
  }
}

a {
  text-decoration: none;
  &:focus,
  &:active {
    text-decoration: none;
  }
}

img {
  height: auto;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

ul {
  list-style: none;
}

.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll-y {
  overflow-y: scroll;
}

html {
  scroll-behavior: smooth;
}

h1 {
  font-family: Muller;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  color: $main-black;
}

h2 {
  font-family: Muller;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: $main-black;
}

h3 {
  font-family: Muller;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: $main-black;
}

h4 {
  font-family: Muller;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.16em;
  color: $main-black;
}

h5 {
  font-family: Muller;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: $main-black;
}

h6 {
  font-family: Muller;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $main-black;
}

button {
  font-family: Muller;
  border: none;
  background-color: transparent;
  font-family: Muller;
}
