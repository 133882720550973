@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.advantages {
  max-width: $main-container;
  width: 100%;
  background-color: $bg;
  padding: 65px 30px;

  @media (max-width: $breakpoint-xm) {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 65px 0;
  }

  @media (max-width: $breakpoint-xs) {
    width: 100%;
    padding: 32px 0;
  }

  &__empty {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loader {
    top: auto;
    left: auto;
  }

  &__content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: $adaptive) {
      display: block;
      display: flex;
      justify-content: center;
      position: relative;
    }
  }

  &__buttonBlock {
    margin-top: 8px;

    @media (max-width: $advantages) {
      width: 100%;
      margin-left: 0;
      margin-top: 36px;
      display: flex;
      justify-content: center;
    }

    @media (max-width: $adaptive) {
      width: 100%;
      margin-top: 23px;
    }
  }

  &__buttonWrapper {
    @media (max-width: $advantages) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__button {
    @include btn(56px, 16px 40px);
    color: $white;
    font-weight: bold;

    @media (max-width: $breakpoint-xs) {
      width: 100%;
      max-width: 360px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }

    @media (max-width: $adaptive) {
      width: 98%;
      max-width: 360px;
    }
  }

  &__textContentAnimate {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__textContent {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: $breakpoint-xxl) {
      margin: 0;
    }

    @media screen and (max-width: $breakpoint-xl) {
      margin: 0;
    }

    h2 {
      position: relative;
      @include font-title-very-big;
      display: block;
      text-transform: uppercase;
      margin-bottom: 34px;

      @media (max-width: $breakpoint-xl) {
        font-size: 42px;
      }

      @media (max-width: $breakpoint-xm) {
        font-size: 40px;
      }
      @media (max-width: $advantages) {
        margin-left: 0;
        text-align: center;
      }
      @media (max-width: $adaptive) {
        width: 90%;
        padding-top: 0;
        font-size: 40px;
        letter-spacing: 0.04em;
        margin-bottom: 26px;
      }
    }

    section {
      width: 100%;
      display: flex;
      justify-content: center;
      column-gap: 36px;

      @media screen and (max-width: $breakpoint-xl) {
        width: 650px;
        flex-wrap: wrap;
      }

      @media screen and (max-width: 630px) {
        width: 290px;
      }

      @media (max-width: $adaptive) {
        width: 100%;
      }

      div {
        max-width: 264px;

        &:first-child {
          position: relative;
          margin-top: 190px;

          &::before {
            content: "";
            display: block;
            position: absolute;
            width: 325px;
            height: 200px;
            background-image: url("../../../../assets/images/advantages/oneWithLine.png");
            background-repeat: no-repeat;
            top: -200px;
            left: -32px;
          }

          @media screen and (max-width: 630px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-top: 167px;

            &::before {
              content: "";
              display: block;
              position: absolute;
              width: 340px;
              height: 178px;
              background-image: url("../../../../assets/images/advantages/oneAdaptive.png");
              background-repeat: no-repeat;
              top: -177px;
              left: 62px;
            }
          }

          @media (max-width: $adaptive) {
            max-width: 286px;
          }
        }

        &:nth-child(2) {
          position: relative;
          margin-top: 238px;

          &::before {
            content: "";
            display: block;
            position: absolute;
            width: 322px;
            height: 190px;
            background-image: url("../../../../assets/images/advantages/twoWithLine.png");
            background-repeat: no-repeat;
            background-position: 100%;
            top: -207px;
            left: -30px;
          }

          @media screen and (max-width: $breakpoint-xl) {
            &::before {
              content: "";
              display: block;
              position: absolute;
              width: 202px;
              height: 190px;
              background-image: url("../../../../assets/images/advantages/two.png");
              background-repeat: no-repeat;
              background-position: 95%;
              top: -207px;
              left: auto;
            }
          }

          @media screen and (max-width: 630px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-top: 199px;

            &::before {
              content: "";
              display: block;
              position: absolute;
              width: 522px;
              height: 190px;
              background-image: url("../../../../assets/images/advantages/twoAdaptive.png");
              background-repeat: no-repeat;
              background-position: 0;
              top: -213px;
            }
          }

          h3 {
            max-width: 170px;

            @media screen and (max-width: $adaptive) {
              max-width: 250px;
            }
          }
        }

        &:nth-child(3) {
          position: relative;
          margin-top: 190px;

          p {
            max-width: 242px;

            @media (max-width: $adaptive) {
              max-width: 255px;
            }
          }

          &::before {
            content: "";
            display: block;
            position: absolute;
            width: 320px;
            height: 170px;
            background-image: url("../../../../assets/images/advantages/threeWithLine.png");
            background-repeat: no-repeat;
            background-position: 100%;
            left: -18px;
            top: -180px;
          }

          @media screen and (max-width: 630px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-top: 224px;

            &::before {
              content: "";
              display: block;
              position: absolute;
              width: 520px;
              height: 200px;
              background-image: url("../../../../assets/images/advantages/threeAdaptive.png");
              background-repeat: no-repeat;
              background-position: 100%;
              left: auto;
              top: -195px;
            }
          }
        }

        &:nth-child(4) {
          position: relative;
          margin-top: 238px;
          margin-left: 22px;

          h3 {
            max-width: 200px;
            @media (max-width: $adaptive) {
              max-width: 260px;
            }
          }

          p {
            max-width: 200px;
            @media (max-width: $adaptive) {
              max-width: 255px;
            }
          }

          &::before {
            content: "";
            display: block;
            position: absolute;
            width: 210px;
            height: 160px;
            background-image: url("../../../../assets/images/advantages/four.png");
            background-repeat: no-repeat;
            background-position: 180%;
            top: -185px;
            left: -17px;
          }

          @media screen and (max-width: 630px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-top: 200px;
            margin-left: 0;

            &::before {
              content: "";
              display: block;
              position: absolute;
              width: 520px;
              height: 200px;
              background-image: url("../../../../assets/images/advantages/fourAdaptive.png");
              background-repeat: no-repeat;
              background-position: 0;
              top: -224px;
              left: auto;
            }
          }
        }
      }
    }

    p {
      @include font-text($grey, 24px);
      margin-bottom: 24px;

      @media (max-width: $adaptive) {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
      }
    }

    h3 {
      @include font-text-medium;
      margin-bottom: 8px;

      @media (max-width: $adaptive) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}
