@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.leaveRequest {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $popup-z-index;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-height: 820px) and (min-width: $breakpoint-xm) {
    align-items: flex-start;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__popupWrapper {
    @include popupSpecialWrapper(560px);
  }

  &__popup {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 560px;
    width: 100%;
    background-color: $white;
    border-radius: 15px;
    padding: 19px 19px 56px 64px;

    @media only screen and (max-width: $breakpoint-xm) {
      @include popupScrollAdaptive;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 19px 16px 48px 16px;
    }
  }

  &__contentWrapper {
    position: relative;
    width: 100%;
    height: max-content;
    background-color: $white;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $breakpoint-xm) {
      align-items: center;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      align-items: flex-start;
    }
  }

  &__input {
    width: 100%;
    max-width: 432px;
    height: 96px;
    margin-bottom: 16px;
    @media only screen and (max-width: $breakpoint-xm) {
      margin-bottom: 15px;
    }
  }

  &__close {
    align-self: flex-end;
    cursor: pointer;
    transform: scale(0.7);

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      margin-right: 10px;
    }
  }

  &__title {
    @include font-title-big;
    margin-top: 21px;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 13px;
    }
  }

  &__subtitle {
    @include font-text-medium($dark-grey);
    margin-top: 15px;
    max-width: 430px;
    width: 100%;

    margin-bottom: 32px;
  }

  &__text {
    width: 362px;
    padding-left: 16px;
    font-size: 20px;
    line-height: 32px;
    color: $main-black;
    &_underline {
      border-bottom: 1px solid $main-black;
      cursor: pointer;
    }
  }

  &__button {
    max-width: 432px;
    width: 100%;
    height: 56px;
    color: $white;
    margin-top: 11px;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 14px;
    }
  }

  &__politics {
    @include font-text-reduced($grey, 20px);
    max-width: 432px;
    width: 97%;
    margin-top: 16px;

    @media only screen and (max-width: $breakpoint-xm) {
      width: 100%;
    }
  }

  &__personal {
    color: $main-black;
    cursor: pointer;
    text-decoration: underline;
  }
}
