@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  display: flex;
  max-width: $main-container;
  width: 100%;

  @media only screen and (max-width: $breakpoint-lg) {
    display: block;
  }

  @media only screen and (max-width: $adaptive) {
    width: 100%;
  }

  &__empty {
    position: relative;
    width: 70%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loader {
    top: auto;
    left: auto;
  }

  &__left {
    position: relative;
    max-width: 720px;
    width: 100%;
    background-color: $yellow;
    padding: 153px 0 150px 120px;

    @media only screen and (max-width: $main-part) {
      max-width: 100%;
    }

    @media only screen and (max-width: $advantages) {
      padding: 80px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media only screen and (max-width: $adaptive) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px 0 32px 0;
    }
  }

  &__textBlockAnimate {
    width: 100%;

    @media only screen and (max-width: $main-part) {
      display: flex;
      justify-content: center;
    }
  }

  &__textBlock {
    @media only screen and (max-width: $main-part) {
      width: 100%;
    }
    h2 {
      @include font-title-very-big($white);
      text-transform: uppercase;
      max-width: 70%;

      @media only screen and (max-width: $main-part) {
        max-width: 100%;
      }

      @media only screen and (max-width: $breakpoint-xl) {
        font-size: 42px;
      }

      @media only screen and (max-width: $breakpoint-lg) {
        text-align: center;
        width: max-content;
      }
      @media only screen and (max-width: $breakpoint-xm) {
        max-width: 270px;
        width: 97%;
        font-size: 40px;
        letter-spacing: 0.04em;
        margin-bottom: 16px;
      }
    }

    ul {
      margin-top: 24px;
      margin-left: 54px;
      display: flex;
      flex-direction: column;
      color: $white;
      list-style: disc;
      user-select: none;

      @media only screen and (max-width: $main-part) {
        width: 100%;
        align-items: center;
        margin-left: 0;
      }

      @media only screen and (max-width: $breakpoint-xm) {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 0 0 0 10px;
      }

      li {
        position: relative;
        font-size: 20px;
        line-height: 40px;
        width: max-content;
        max-width: 90%;

        @media only screen and (max-width: $breakpoint-xm) {
          width: max-content;
          max-width: 90%;
          text-align: center;
          font-size: 16px;
          line-height: 32px;
        }
      }
    }
  }

  &__buttonWrapper {
    @media only screen and (max-width: $breakpoint-xm) {
      max-width: 360px;
      width: 97%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }
  }

  &__button {
    margin-top: 40px;
    @include btn(56px, 16px 40px);
    font-weight: bold;

    @media only screen and (max-width: $breakpoint-xm) {
      width: 100%;
      margin-top: 16px;
    }
    &:hover {
      color: $white;
    }
  }
  &__right {
    max-width: 680px;
    width: 50%;
    height: 720px;

    @media only screen and (max-width: $main-part) {
      display: none;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      display: flex;
      width: 100%;
      max-width: none;
      height: 600px;
    }
    @media only screen and (max-width: $breakpoint-xm) {
      height: 500px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      height: 392px;
    }
  }
}
