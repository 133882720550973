@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.stepArrivalDate {
  position: relative;
  max-width: 1161px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 49px;

  @media screen and (max-width: $breakpoint-xm) {
    margin-top: 24px;
  }

  @media screen and (max-height: 920px) {
    min-height: 700px;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    row-gap: 23px;

    @media screen and (max-width: $breakpoint-xm) {
      margin-top: 18px;
      row-gap: 24px;
    }
  }

  &__title {
    @include font-text-big;
    line-height: 40px;
    margin: 24px 24px 16px 0;

    @media screen and (max-width: $breakpoint-xm) {
      width: 87%;
      margin-top: 0;
      margin-bottom: 24px;
    }
  }

  &__stepNum {
    @include font-subtitle-light($yellow);
    margin-top: 7px;
  }

  &__description {
    @include font-text($grey, 24px);
    margin-bottom: 23px;
  }

  &__inputDate {
    max-width: 360px;
    width: 100%;
  }

  &__input {
    width: 100%;
  }

  &__button {
    max-width: 311px;
    width: 100%;
    height: 56px;
    margin-top: 63px;
    margin-right: 81px;
    align-self: center;

    @media screen and (max-width: $advantages-xl) {
      margin-right: 0;
    }

    @media screen and (max-width: $breakpoint-xm) {
      margin-top: 31px;
      max-width: none;
    }
  }

  &__calendar {
    top: 10px;
    left: auto;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.24);
    border-radius: 24px;

    @media screen and (max-width: $breakpoint-xs) {
      left: -5px;
    }
  }

  &__calendarContainer {
    @media screen and (max-width: $breakpoint-xs) {
      width: 355px;
    }
  }
}
