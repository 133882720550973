@import "../../../../../styles/mixins.scss";

.rent {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  @media only screen and (max-width: $breakpoint-sm) {
    align-items: center;
  }

  @media only screen and (max-width: $adaptive) {
    margin-top: 24px;
  }

  &__content {
    width: 100%;
    max-width: 760px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media screen and (max-width: $breakpoint-xs) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
    }
  }

  &__warning {
    margin-top: 24px;
  }

  &__title {
    @include font-text-big;
    line-height: 40px;
    margin-bottom: 40px;

    @media screen and (max-width: $breakpoint-xs) {
      margin-bottom: 23px;
      align-self: flex-start;
      margin-left: 0px;
      padding: 0;
    }
  }

  &__flats {
    display: flex;
    flex-direction: column;
    gap: 23px;

    @media only screen and (max-width: $breakpoint-xs) {
      width: 100%;
      margin-bottom: 16px;
      max-height: 100%;
    }

    @media only screen and (max-width: $adaptive) {
      margin-bottom: 53px;
    }
  }

  &__empty {
    width: 100%;
    max-width: 920px;
    display: flex;
    justify-content: center;
    margin-top: 200px;

    @media only screen and (max-width: $breakpoint-lg) {
      margin-bottom: 130px;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 375px;
      margin: 80px 0 0 8px;
    }
  }

  &__popupTitle {
    @include font-text-big;
    margin-bottom: 20px;
  }

  &__popupSubTitle {
    @include font-text-medium($grey);
    margin-bottom: 20px;
  }

  &__popupPrice {
    @include font-text-medium;
  }

  &__modalText {
    @include font-text-big;
  }
}
