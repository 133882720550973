@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.modal {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $popup-z-index;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-height: 490px) and (min-width: $breakpoint-xm) {
    align-items: flex-start;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__popupWrapper {
    @include popupSpecialWrapper(595px);
  }

  &__review {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 595px;
    width: 100%;
    padding: 36px 32px 34px 32px;
    background-color: $white;
    border-radius: 16px;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: $breakpoint-xm) {
      @include popupScrollAdaptive;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 36px 8px;
    }
  }

  &__loader {
    top: 0;
  }

  &__contentClose {
    width: 16px;
    align-self: flex-end;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      position: relative;
      @include size(16px, 16px);
      margin-right: 15px;
    }
  }

  &__contentStars {
    display: flex;
    justify-content: center;
    margin-top: 23px;
    column-gap: 14px;
    position: relative;
    cursor: pointer;
  }

  &__contentTitle {
    color: $main-black;
    font-size: 24px;
    line-height: 32px;
    margin-top: 26px;
    margin-bottom: 26px;
    align-self: flex-start;

    @media only screen and (max-width: $breakpoint-xm) {
      font-size: 32px;
      line-height: 48px;
      margin-top: 38px;
      margin-bottom: 19px;
    }
  }

  &__contentTextAreaWrap {
    width: 100%;

    @media only screen and (max-width: $breakpoint-xm) {
      max-width: 100%;
    }
  }

  &__contentTextArea {
    max-width: 531px;
    width: 100%;
    height: 146px;

    @media only screen and (max-width: $breakpoint-xm) {
      max-width: 100%;
      height: 144px;
    }
  }

  &__ratingError {
    @include font-text-reduced($text-color-error);
    height: 24px;
    position: absolute;
    top: 45px;
    left: 39%;

    @media only screen and (max-width: $adaptive) {
      left: 33%;
    }
  }

  &__contentButton {
    max-width: 531px;
    width: 100%;
    margin-top: 24px;
    color: $white;

    @media only screen and (max-width: $adaptive) {
      margin-top: 28px;
      color: $white;
    }
  }
}

@keyframes showModalAdaptive {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0;
  }
}
