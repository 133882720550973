@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  max-width: $main-container;
  width: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 63px 0;

  @media only screen and (max-width: $breakpoint-xs) {
    padding-top: 32px;
  }

  @media only screen and (max-width: $adaptive) {
    width: 100%;
    padding: 33px 0 65px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__cardsColumns {
    max-width: 959px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 910px) {
      flex-direction: column;
      row-gap: 29px;
      align-items: center;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      row-gap: 36px;
    }
  }

  &__cardsWrapperLeft,
  &__cardsWrapperRight {
    max-width: 50%;
    width: max-content;
    row-gap: 29px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media only screen and (max-width: $breakpoint-sm) {
      row-gap: 36px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      max-width: 80%;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 90%;
    }
  }

  &__contentAnimateTitle {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 59px;
    margin-bottom: 67px;

    @media only screen and (max-width: $breakpoint-xxl) {
      margin-right: 0;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      margin-bottom: 30px;
    }

    h2 {
      @include font-title-very-big;
      display: block;
      max-width: 580px;
      text-transform: uppercase;
      text-align: center;

      @media only screen and (max-width: $breakpoint-xl) {
        font-size: 42px;
      }

      @media only screen and (max-width: $breakpoint-sm) {
        font-size: 40px;
        letter-spacing: 0.04em;
      }
    }
  }

  &__contentAnimateLeft {
    width: 100%;
    max-width: 460px;
    display: flex;

    h3 {
      margin-bottom: 7px;

      @media only screen and (max-width: $breakpoint-sm) {
        text-align: center;
      }

      @media only screen and (max-width: $breakpoint-xs) {
        font-size: 20px;
        line-height: 24px;
        margin-top: 13px;
      }
    }

    p {
      line-height: 20px;
      @media only screen and (max-width: $breakpoint-sm) {
        text-align: center;
      }
    }

    &:first-child {
      @media only screen and (max-width: $breakpoint-sm) {
        flex-direction: column;
        align-items: center;
      }

      &::before {
        content: "";
        display: block;
        position: relative;
        width: 75px;
        height: 75px;
        background-image: url("../../../../assets/images/services/parking.svg");
        background-repeat: no-repeat;
        background-position-y: center;
        flex-shrink: 0;
        margin-right: 25px;
        padding-top: 5px;

        @media only screen and (max-width: $breakpoint-sm) {
          margin-right: 0;
        }

        @media only screen and (max-width: $breakpoint-xs) {
          width: 72px;
          height: 72px;
        }
      }
    }

    &:nth-child(2) {
      @media only screen and (max-width: $breakpoint-sm) {
        flex-direction: column;
        align-items: center;
      }

      &::before {
        content: "";
        display: block;
        position: relative;
        width: 75px;
        height: 75px;
        background-image: url("../../../../assets/images/services/service.svg");
        background-repeat: no-repeat;
        background-position-y: center;
        flex-shrink: 0;
        margin-right: 25px;
        padding-bottom: 44px;

        @media only screen and (max-width: $breakpoint-sm) {
          margin-right: 0;
          padding-bottom: 0;
        }

        @media only screen and (max-width: $breakpoint-xs) {
          width: 72px;
          height: 72px;
        }
      }
    }

    &:nth-child(3) {
      @media only screen and (max-width: $breakpoint-sm) {
        flex-direction: column;
        align-items: center;
      }

      &::before {
        content: "";
        display: block;
        position: relative;
        width: 75px;
        height: 75px;
        background-image: url("../../../../assets/images/services/waiting.svg");
        background-repeat: no-repeat;
        flex-shrink: 0;
        margin-right: 25px;

        @media only screen and (max-width: $breakpoint-sm) {
          margin-right: 0;
        }
        @media only screen and (max-width: $breakpoint-xs) {
          width: 72px;
          height: 72px;
        }
      }
    }
  }

  &__contentAnimateRight {
    width: 100%;
    max-width: 460px;
    display: flex;

    h3 {
      margin-bottom: 7px;
      @media only screen and (max-width: $breakpoint-sm) {
        text-align: center;
      }

      @media only screen and (max-width: $breakpoint-xs) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    p {
      line-height: 20px;
      @media only screen and (max-width: $breakpoint-sm) {
        text-align: center;
      }
    }

    &:nth-child(1) {
      @media only screen and (max-width: $breakpoint-sm) {
        flex-direction: column;
        align-items: center;
      }

      &::before {
        content: "";
        display: block;
        position: relative;
        width: 75px;
        height: 75px;
        background-image: url("../../../../assets/images/services/apartments.svg");
        background-repeat: no-repeat;
        background-position-y: center;
        padding-top: 5px;
        flex-shrink: 0;
        margin-right: 25px;

        @media only screen and (max-width: $breakpoint-sm) {
          padding: 0;
          margin-right: 0;
        }

        @media only screen and (max-width: $breakpoint-xs) {
          width: 72px;
          height: 72px;
        }
      }
    }

    &:nth-child(2) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      @media only screen and (max-width: $breakpoint-sm) {
        flex-direction: column;
        align-items: center;
      }

      &::before {
        content: "";
        display: block;
        position: relative;
        width: 75px;
        height: 75px;
        background-image: url("../../../../assets/images/services/bonuses.svg");
        background-repeat: no-repeat;
        background-position-y: center;
        padding-bottom: 44px;
        flex-shrink: 0;
        margin-right: 25px;

        @media only screen and (max-width: $breakpoint-sm) {
          padding: 0;
          margin-right: 0;
        }
        @media only screen and (max-width: $breakpoint-xs) {
          width: 72px;
          height: 72px;
        }
      }
    }

    &:nth-child(3) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 28px;

      @media only screen and (max-width: $main-part) {
        margin-bottom: 32px;
      }

      @media only screen and (max-width: $breakpoint-sm) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
      }

      &::before {
        content: "";
        display: block;
        position: relative;
        width: 75px;
        height: 75px;
        background-image: url("../../../../assets/images/services/playground.svg");
        background-repeat: no-repeat;
        flex-shrink: 0;
        margin-right: 25px;

        @media only screen and (max-width: $breakpoint-sm) {
          padding: 0;
          margin-right: 0;
        }
        @media only screen and (max-width: $breakpoint-xs) {
          width: 72px;
          height: 72px;
        }
      }
    }
  }

  &__empty {
    position: relative;
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loader {
    top: auto;
    left: auto;
  }

  &__content {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto 20px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 60px;

    @media only screen and (max-width: $breakpoint-xl) {
      padding-left: 0;
      max-width: 1000px;
    }

    @media only screen and (max-width: $main-part) {
      max-width: 900px;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      margin: 0 auto;
      padding: 0;
    }
  }
}
