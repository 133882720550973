@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.book {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: $popup-z-index;
  align-items: flex-end;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__popupWrapper {
    @include popupSpecialWrapper(100%);
  }

  &__popup {
    @include popupScrollAdaptive;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $white;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__close {
    cursor: pointer;
    align-self: flex-end;
    margin-right: 10px;
  }
  &__mainTitle {
    color: $main-black;
    line-height: 48px;
  }
  &__range {
    font-size: 18px;
    line-height: 32px;
    color: $yellow;
  }

  &__guestsTitle {
    line-height: 48px;
  }

  &__guests {
    width: 100%;
    margin-top: 16px;
  }

  &__counters {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 8px;
  }

  &__guestsCounter {
    width: 100%;
    margin-top: -3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 8px;
    h6 {
      margin-top: 8px;
    }
  }
  &__guestsCounterKids {
    width: 100%;
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 8px;
    h6 {
      margin-top: 8px;
    }
  }

  &__counterButton {
    width: 32px;
    height: 32px;
  }

  &__arrival {
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__leaving {
    margin-top: 4px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__datepickerTitle {
    align-self: flex-start;
    line-height: 48px;
  }

  &__priceWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 46px auto 0 auto;
  }

  &__error {
    @include font-text-reduced($text-color-error);
    position: absolute;
    width: max-content;
    white-space: nowrap;
    background-color: $light-grey;
    border-radius: 25px;
    padding: 3px 10px;
    top: -130%;
  }

  &__price {
    max-width: 360px;
    width: max-content;
    display: flex;
    justify-content: center;
  }

  &__button {
    max-width: 360px;
    width: 97%;
    color: $white;
    margin: 19px auto 24px auto;

    &_disabled {
      @include buttonDisabled;
      &:hover {
        background-color: $light-grey;
      }
    }
  }
}
