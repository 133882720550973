@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

$breakpoint-rent-middle: 1235px;

.rentItem {
  max-width: 762px;
  width: 100%;
}

.rent {
  max-width: 762px;
  width: 100%;
  border-radius: 16px;
  background-color: $white;
  padding: 24px;

  @media screen and (max-width: $adaptive) {
    padding: 16px;
  }

  &__top {
    position: relative;
    max-width: 762px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    @media screen and (max-width: 676px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    @media screen and (max-width: $adaptive) {
      margin-bottom: 19px;
    }
  }

  &__floor {
    display: flex;
    gap: 16px;
  }

  &__bottom {
    position: relative;
    max-width: 762px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__info {
    display: flex;
    column-gap: 31px;
    row-gap: 16px;
    flex-wrap: wrap;

    @media screen and (max-width: $breakpoint-xs) {
      flex-direction: column;
      row-gap: 16px;
    }
  }

  &__period,
  &__endBooking {
    margin-right: 9px;

    @media screen and (max-width: $breakpoint-xs) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__term,
  &__tenants {
    @media screen and (max-width: $breakpoint-xs) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__title {
    @include font-subtitle-bold;
  }

  &__titleFloor {
    @media screen and (max-width: 676px) {
      font-weight: 400;
      font-size: 18px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      @include font-text(24px);
    }
  }

  &__subTitle {
    color: $grey;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 23px;

    @media screen and (max-width: 617px) {
      flex-direction: column;
    }

    @media screen and (max-width: $breakpoint-xs) {
      margin-top: 24px;
      gap: 24px;
    }
  }

  &__checkInDateButton {
    @include font-text($white);
    @include size(242px, 32px);
    background-color: $yellow;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.16),
          rgba(255, 255, 255, 0.16)
        ),
        $yellow;
    }
  }

  &__checkinDate {
    @include font-text-reduced($main-black, 16px);
  }
}

.additional {
  width: 100%;
  max-width: 760px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 32px;

  @media screen and (max-width: $breakpoint-rent-middle) {
    flex-direction: column;
  }

  @media screen and (max-width: $breakpoint-xs) {
    margin-top: 16px;
  }

  &__parking {
    max-width: 368px;
    width: 50%;
    background-color: $white;
    border-radius: 16px;
    padding: 24px;

    @media screen and (max-width: 1180px) {
      width: 80%;
    }

    @media screen and (max-width: $breakpoint-rent-middle) {
      max-width: 100%;
      width: 100%;
    }

    @media screen and (max-width: $adaptive) {
      padding: 16px;
    }
  }

  &__payments {
    max-width: 368px;
    width: 50%;
    background-color: $white;
    border-radius: 16px;
    padding: 24px;

    @media screen and (max-width: 1180px) {
      min-width: 280px;
    }

    @media screen and (max-width: $breakpoint-rent-middle) {
      max-width: 100%;
      width: 100%;
    }

    @media screen and (max-width: $adaptive) {
      padding: 16px;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 24px;
  }

  &__price {
    display: flex;
    justify-content: flex-end;
  }

  &__bottom {
    max-width: 762px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    gap: 16px;
  }

  &__line {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $breakpoint-xs) {
      flex-wrap: wrap;
    }
  }

  &__lineValue {
    text-align: end;
  }

  &__title {
    @include font-subtitle-bold;
    min-width: max-content;
    margin-right: 16px;
  }

  &__floor {
    min-width: max-content;
  }

  &__subTitle {
    color: $grey;
  }
}
