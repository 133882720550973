@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

$breakpoint-middle: 1074px;

.personal {
  width: 100%;
  margin-top: 56px;

  @media only screen and (max-width: $breakpoint-sm) {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: $adaptive) {
      margin-top: 0;
    }
  }

  &__content {
    width: 100%;
    max-width: 761px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 40px;

    @media only screen and (max-width: 1380px) {
      margin: 0 auto;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      margin-top: 24px;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
    }
  }

  &__title {
    color: $yellow;
    line-height: 24px;
    margin-bottom: 24px;

    @media only screen and (max-width: $adaptive) {
      margin-bottom: 15px;
    }
  }

  &__passportTitle {
    color: $yellow;
    line-height: 24px;
    margin-top: 48px;
    margin-bottom: 20px;

    @media only screen and (max-width: $adaptive) {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }

  &__avatar {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }

  &__avatarWrapper {
    display: flex;
    align-items: center;
  }

  &__avatarImgWrapper {
    @include size(80px, 80px);
    border-radius: 50%;
    margin-right: 20px;
    overflow: hidden;

    @media only screen and (max-width: $adaptive) {
      @include size(64px, 64px);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__span {
    position: relative;
    display: block;
    color: $main-black;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 1px;
      border-top: 1px solid $main-black;
      bottom: 3px;
      left: 0;
    }
  }

  &__spanFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 16px;
    margin-top: 19px;

    @media only screen and (max-width: $breakpoint-middle) {
      flex-direction: column;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      flex-direction: row;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
      max-height: 100%;
    }

    @media only screen and (max-width: $adaptive) {
      flex-direction: column;
      margin-top: 11px;
      gap: 16px 0;
    }
  }

  &__infoMargin {
    margin-top: -5px;

    @media only screen and (max-width: $adaptive) {
      margin-top: -15px;
    }
  }

  &__fioData {
    max-width: 243px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xxl) {
      max-width: none;
      width: calc(50% - 8px);
    }

    @media only screen and (max-width: $breakpoint-middle) {
      width: 100%;
    }
  }

  &__date {
    max-width: 216px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xxl) {
      max-width: none;
      width: calc(50% - 8px);
    }

    @media only screen and (max-width: $breakpoint-middle) {
      width: 100%;
    }
  }

  &__dateInput {
    width: 100%;
  }

  &__datePassport {
    max-width: 153px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-middle) {
      max-width: none;
    }
  }

  &__phone {
    max-width: 256px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: calc(50% - 8px);
      max-width: none;
    }

    @media only screen and (max-width: $breakpoint-middle) {
      width: 100%;
    }
  }

  &__email {
    max-width: 256px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: calc(50% - 8px);
      max-width: none;
    }

    @media only screen and (max-width: $breakpoint-middle) {
      width: 100%;
    }
  }

  &__placeBirth {
    max-width: 760px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-lg) {
      max-width: none;
    }
  }

  &__infoInputs {
    display: flex;
    gap: 0 16px;
  }

  &__gender {
    width: 74px;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-bottom: 16px;
    }

    @media only screen and (max-width: $adaptive) {
      margin-bottom: 0;
    }
  }

  &__serial {
    width: 91px;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-bottom: 16px;
    }

    @media only screen and (max-width: $adaptive) {
      margin-bottom: 0;
    }
  }

  &__numberPassport {
    width: 122px;
  }

  &__placeOfIssue {
    max-width: 669px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xxl) {
      max-width: none;
    }
  }

  &__passportCode {
    max-width: 155px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xxl) {
      max-width: none;
    }
  }

  &__address {
    max-width: 589px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xxl) {
      max-width: none;
      width: 100%;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 64px;
    margin-bottom: 64px;

    @media only screen and (max-width: 1222px) {
      margin-top: 132px;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      flex-direction: column-reverse;
      gap: 36px;
      margin-top: 32px;
    }

    @media only screen and (max-width: $adaptive) {
      margin-top: 31px;
      margin-bottom: 45px;
      gap: 20px;
    }
  }

  &__warning {
    color: $text-error;
  }

  &__deleteBtn {
    width: max-content;
    font-size: 16px;
    color: $grey;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__saveBtn {
    @include btn(56px, 16px 71.5px);
    color: $white;

    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &__modalAvatar {
    max-width: 395px;
  }

  &__modalAvatarText {
    margin-top: 16px;
    color: $dark-grey;
  }
}
