@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.container {
  position: relative;
  @include size(360px, 632px);
  border-radius: 24px;
  background-image: url("../../../../../assets/images/greyBack.svg");

  /*   @media only screen and (max-width: $breakpoint-xxl) {
    min-width: 355px;
    height: 603px;
  } */

  @media only screen and (max-width: $breakpoint-xl) {
    height: 565px;
    width: 350px;
  }

  @media only screen and (max-width: $apartment) {
    width: 100%;
    max-width: 760px;
    height: 400px;
  }

  @media only screen and (max-width: 650px) {
    height: max-content;
  }

  &__error {
    @include font-text-reduced($text-color-error);
    line-height: 16px;
    position: absolute;
    width: max-content;
    max-width: 100%;
    background-color: $light-grey;
    border-radius: 25px;
    padding: 5px 10px;
    bottom: -9%;
    z-index: 2;
  }

  &__datepickerTop {
    position: relative;
    z-index: 4;
  }

  &__datepicker {
    position: relative;
    z-index: 3;
  }

  &__title,
  &__price {
    font-size: 34px;
    line-height: 40px;
    color: $white;

    @media only screen and (max-width: $breakpoint-xl) {
      font-size: 32px;
      line-height: 36px;
    }
  }

  &__title {
    @media only screen and (max-width: $breakpoint-xl) {
      font-size: 34px;
      line-height: 36px;
    }
  }

  &__content {
    padding: 32px;
    margin-top: 2px;

    @media only screen and (max-width: 1319px) {
      margin-top: 0px;
    }

    @media only screen and (max-width: 650px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__countersDateWrapper {
    @media only screen and (max-width: $apartment) {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 36px;
    }

    @media only screen and (max-width: 650px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__button {
    width: 296px;
    margin-top: 22px;
    color: $white;

    &_disabled {
      @include buttonDisabled;

      &:hover {
        background-color: $light-grey;
      }
    }
  }

  &__counters {
    display: flex;
    flex-direction: column;
    margin-top: 22px;
    row-gap: 16px;

    @media only screen and (max-width: $apartment) {
      margin: 0;
    }
  }

  &__duration {
    position: relative;
    width: 100%;
    margin-top: 32px;
    @media only screen and (max-width: $apartment) {
      margin: 0;
    }

    @media only screen and (max-width: 650px) {
      margin-top: 53px;
    }
  }

  &__footer {
    position: absolute;
    bottom: 40px;

    @media only screen and (max-width: $apartment) {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 46px;
    }

    @media only screen and (max-width: 650px) {
      flex-direction: column;
      align-items: center;
      margin-top: 24px;
      bottom: auto;
    }
  }

  &__checkinDate {
    @include font-text-reduced($white, 16px);
    position: absolute;
    margin-top: -15px;
    z-index: 1;
  }
}
