@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.selectedServices {
  max-width: 780px;
  width: 100%;

  &__cardsWrapper {
    width: 100%;
    max-height: 560px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-right: 10px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.08) #ffffff00;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      width: 7px;
      background: rgba(0, 0, 0, 0.08);
      border-radius: 15px;
    }

    @media screen and (max-width: $breakpoint-xm) {
      padding-right: 0;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__cardsEmpty {
    width: 100%;
    max-width: 760px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 63px;

    @media screen and (max-width: $breakpoint-xm) {
      margin-top: 40px;
    }

    @media screen and (max-width: $breakpoint-lg) {
      margin-bottom: 64px;
    }
  }

  &__cardsEmptyTitle {
    @include font-text-medium;
    line-height: 40px;
    text-align: center;
  }

  &__cardsEmptyBtn {
    @include font-text($white, 24px);
    width: 260px;
    height: 56px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }

  &__textButtonWrapper {
    position: relative;
    max-width: 760px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 19px;
    column-gap: 16px;

    @media screen and (max-width: $breakpoint-xm) {
      margin: 29px 0 40px 0;
    }

    @media screen and (max-width: $breakpoint-xs) {
      flex-direction: column-reverse;
      row-gap: 16px;
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }

  &__text {
    @include font-text($grey, 24px);
  }

  &__payButton {
    padding: 0;
    width: 143px;
    height: 48px;

    @media screen and (max-width: $breakpoint-xs) {
      width: 100%;
      max-width: 359px;
      height: 56px;
    }
  }

  &__billButton {
    padding: 12px 28px;
  }

  &__pagination {
    max-width: 310px;
    margin: 32px auto 0 auto;
  }

  &__error {
    @include font-text-reduced($text-color-error);
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: -30px;
  }

  &__loadingBlock {
    position: relative;
    height: 624px;
  }

  &__mainLoader {
    top: 200px;
    bottom: auto;
  }
}
