@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.modal {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: $popup-z-index;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__contentWrapper {
    @include popupSpecialWrapper(560px);
    margin: auto;

    @media only screen and (max-width: $breakpoint-xm) {
      margin: 0;
    }
  }

  &__loader {
    top: 0;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 24px;
    padding: 40px 32px 40px 32px;

    @media only screen and (max-width: $breakpoint-xm) {
      @include popupScrollAdaptive;
      padding-top: 36px;
    }
  }
  &__close {
    cursor: pointer;
    align-self: flex-end;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      @include size(16px, 16px);
      margin-left: 4px;
      cursor: pointer;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 40px;
    margin-top: 24px;
  }

  &__buttonsLeft {
    border: 2px solid $dark-grey;
    font-weight: bold;
  }

  &__buttonsRight {
    @include btn(56px, 16px 64.5px);
    color: $white;
    font-weight: bold;
  }

  &__button {
    max-width: 496px;
    width: 100%;
    margin-top: 23px;
    color: $white;
  }

  &__text {
    max-width: 471px;
    line-height: 48px;
    margin-top: 24px;
    margin-bottom: 16px;
  }
}
