@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

$row-gap-adaptive: 16px;

.tenant {
  position: relative;
  width: 100%;

  &__tenantFormMode {
    animation: 0.6s ease-in-out slowShow;
  }

  &__header {
    position: absolute;
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: flex-end;
    top: -55px;

    @media screen and (max-width: $breakpoint-xm) {
      top: -57px;
    }

    &_noFirstMode {
      top: -30px;

      @media screen and (max-width: $breakpoint-xm) {
        top: -32px;
      }
    }
  }

  &__person {
    color: $yellow;
  }

  &__trashCan {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: 1350px) {
      margin-right: 20px;
    }

    @media screen and (max-width: $breakpoint-xm) {
      margin-right: 0;
    }
  }

  &__inputs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 28px;

    @media only screen and (max-width: $breakpoint-xlg) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      row-gap: $row-gap-adaptive;
    }
  }

  // ----- Первая линия инпутов
  //--обертки
  &__inputsFirstBlock {
    display: flex;
    width: 100%;
    column-gap: 16px;

    @media only screen and (max-width: $breakpoint-xlg) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 24px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      row-gap: $row-gap-adaptive;
    }
  }

  &__fioWrapper {
    width: max-content;
    display: flex;
    column-gap: 16px;

    @media only screen and (max-width: $breakpoint-xlg) {
      width: 100%;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      flex-direction: column;
      row-gap: $row-gap-adaptive;
    }
  }

  &__birthPhoneWrapper {
    width: max-content;
    display: flex;
    column-gap: 16px;

    @media only screen and (max-width: $breakpoint-xlg) {
      width: 100%;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      flex-direction: column;
      row-gap: $row-gap-adaptive;
    }
  }

  //--- 1 --- инпуты
  &__lastName {
    width: 220px;

    @media only screen and (max-width: $breakpoint-xlg) {
      width: calc((100% - 32px) / 3);
    }
    @media only screen and (max-width: $breakpoint-xm) {
      width: 100%;
    }
  }

  &__firstName {
    width: 220px;
    @media only screen and (max-width: $breakpoint-xlg) {
      width: calc((100% - 32px) / 3);
    }
    @media only screen and (max-width: $breakpoint-xm) {
      width: 100%;
    }
  }

  &__middleName {
    width: 220px;
    @media only screen and (max-width: $breakpoint-xlg) {
      width: calc((100% - 32px) / 3);
    }
    @media only screen and (max-width: $breakpoint-xm) {
      width: 100%;
    }
  }

  &__date {
    width: 180px;
    min-width: 180px;

    @media only screen and (max-width: $breakpoint-xlg) {
      width: calc(40% - 8px);
    }

    @media screen and (max-width: $breakpoint-xm) {
      width: 100%;
    }
  }

  &__dateInput {
    width: 100%;
    background-color: $white;
  }

  &__phone {
    width: 256px;

    @media only screen and (max-width: $breakpoint-xlg) {
      width: calc(60% - 8px);
    }

    @media screen and (max-width: $breakpoint-xm) {
      width: 100%;
    }
  }

  //------- Вторая линия инпутов
  //-- обертки
  &__inputsMiddleBlock {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 16px;

    @media only screen and (max-width: $breakpoint-xlg) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 24px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      row-gap: $row-gap-adaptive;
    }
  }

  &__genderPassportWrapper {
    display: flex;
    align-items: center;
    column-gap: 16px;

    @media screen and (max-width: $breakpoint-xlg) {
      justify-content: flex-start;
    }

    @media screen and (max-width: $breakpoint-xm) {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: $row-gap-adaptive;
    }
  }

  &__tenantFormMode &__genderPassportWrapper {
    @media screen and (max-width: $breakpoint-xm) {
      width: 100%;
      flex-direction: row;
      gap: 16px;
    }
  }

  //---2- инпуты
  &__email {
    max-width: 220px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xlg) {
      width: 100%;
      max-width: none;
    }
  }

  &__tenantFormMode &__email {
    display: none;
  }

  &__placeBirth {
    max-width: 500px;
    width: 45%;

    @media only screen and (max-width: $breakpoint-xlg) {
      width: 100%;
      max-width: none;
    }
  }

  &__tenantFormMode &__placeBirth {
    max-width: 729px;
    width: 75%;

    @media screen and (max-width: $breakpoint-xlg) {
      width: 100%;
      max-width: none;
    }
  }

  &__gender {
    width: 74px;
    margin: 0;

    @media screen and (max-width: $breakpoint-xm) {
      width: 100%;
    }
  }

  &__serial {
    width: 91px;

    @media screen and (max-width: $breakpoint-xm) {
      width: calc(50% - 8px);
    }

    &_tenantMode {
      width: 124px;

      @media screen and (max-width: $breakpoint-xm) {
        width: calc(50% - 8px);
      }
    }
  }

  &__passportNumber {
    width: 122px;

    @media screen and (max-width: $breakpoint-xm) {
      width: calc(50% - 8px);
    }
  }

  &__dateOfIssue {
    width: 170px;
    min-width: 170px;

    @media screen and (max-width: $breakpoint-xm) {
      width: 100%;
    }
  }

  &__dateOfIssueInput {
    width: 100%;
  }

  //-------- Третья линия инпутов
  //--- обертки
  &__inputsLastBlock {
    display: flex;
    width: 100%;
    column-gap: 16px;

    @media only screen and (max-width: $breakpoint-xlg) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 24px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      row-gap: $row-gap-adaptive;
    }
  }

  &__codeAddressAttitudeWrapper {
    display: flex;
    column-gap: 16px;
    width: 59%;

    @media only screen and (max-width: $breakpoint-xlg) {
      width: 100%;
      max-width: none;
    }

    @media screen and (max-width: $breakpoint-xm) {
      flex-direction: column;
      row-gap: $row-gap-adaptive;
    }
  }

  //--- инпуты
  &__issuedBy {
    max-width: 489px;
    width: 60%;

    @media only screen and (max-width: $breakpoint-xlg) {
      width: 100%;
      max-width: none;
    }
  }

  &__tenantFormMode &__issuedBy {
    max-width: 459px;
    width: 40%;

    @media screen and (max-width: $breakpoint-xlg) {
      width: 100%;
      max-width: none;
    }
  }

  &__divisionCode {
    width: 155px;

    @media only screen and (max-width: $breakpoint-xlg) {
      width: calc(35% - 8px);
    }

    @media screen and (max-width: $breakpoint-xm) {
      width: 100%;
    }
  }

  &__registrationAddress {
    width: 484px;

    @media only screen and (max-width: $breakpoint-xlg) {
      width: calc(65% - 8px);
    }

    @media screen and (max-width: $breakpoint-xm) {
      width: 100%;
    }
  }

  &__tenantFormMode &__registrationAddress {
    max-width: 459px;
    width: 100%;

    @media screen and (max-width: $breakpoint-xlg) {
      width: calc(65% - 8px);
      max-width: none;
    }

    @media screen and (max-width: $breakpoint-xm) {
      width: 100%;
    }
  }

  &__attitude {
    width: 210px;

    @media screen and (max-width: $breakpoint-xlg) {
      width: calc(35% - 8px);
      max-width: none;
    }

    @media screen and (max-width: $breakpoint-xm) {
      width: 100%;
    }
  }
}

@keyframes slowShow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
