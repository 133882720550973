@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.modal {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $popup-z-index;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  @media only screen and (max-height: 783px) and (min-width: $breakpoint-xm) {
    align-items: flex-start;
  }

  &__contentWrapper {
    @include popupSpecialWrapper(1062px);

    @media only screen and (max-width: 1075px) {
      max-width: 562px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      max-width: 100%;
    }
  }

  &__content {
    width: 100%;
    max-width: 1062px;
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 15px;

    @media only screen and (max-width: $breakpoint-xm) {
      @include popupScrollAdaptive;
      justify-content: center;
      border-radius: 24px 24px 0 0;
      animation: 0.8s ease showModalAdaptive;
    }
  }
  &__img {
    @include size(502px, 783px);
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
    @media only screen and (max-width: 1075px) {
      display: none;
    }
  }
  &__rightContent {
    margin-left: 64px;
    @media only screen and (max-width: 1075px) {
      padding-bottom: 10px;
    }
    @media only screen and (max-width: $breakpoint-xm) {
      position: relative;
      margin-top: 16px;
      margin-left: 0;
      padding: 0;
    }
  }

  &__input {
    height: 96px;
    margin-bottom: 12px;
    width: 432px;
    @media only screen and (max-width: $breakpoint-xm) {
      max-width: 360px;
      width: 100%;
      margin-bottom: 24px;
    }
  }

  &__close {
    @include size(12px, 12px);
    position: relative;
    top: 17px;
    left: 462px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    @media only screen and (max-width: $breakpoint-xm) {
      @include size(18px, 18px);
      position: absolute;
      top: 19px;
      right: 10px;
      left: auto;
    }
  }
  &__title {
    font-size: 34px;
    margin-top: 24px;
    margin-bottom: 24px;
    @media only screen and (max-width: $breakpoint-xm) {
      font-size: 32px;
      line-height: 48px;
      margin-top: 57px;
      margin-bottom: 18px;
    }
  }
  &__buttonTop {
    display: block;
    margin-top: 12px;
    @media only screen and (max-width: $breakpoint-xm) {
      display: none;
    }
  }
  &__buttonBot {
    display: none;
    @media only screen and (max-width: $breakpoint-xm) {
      display: block;
      margin-bottom: 24px;
    }
  }
  &__button {
    width: 432px;
    color: $white;
    margin-top: 24px;
    @media only screen and (max-width: $breakpoint-xm) {
      max-width: 360px;
      width: 100%;
    }
  }
  &__politics {
    width: 432px;
    margin-top: 24px;
    font-size: 14px;
    line-height: 20px;
    color: $grey;
    @media only screen and (max-width: $breakpoint-xm) {
      width: 360px;
    }
  }
  &__personal {
    color: $main-black;
    cursor: pointer;
    text-decoration: underline;
  }
  &__text {
    @media only screen and (max-width: $breakpoint-xm) {
      width: 360px;
      padding-left: 16px;
      font-size: 20px;
      line-height: 32px;
      color: $main-black;
    }
  }
}

@keyframes showModalAdaptive {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0;
  }
}
