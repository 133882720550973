@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.container {
  width: 100%;
  height: 56px;
  border-radius: 48px;
  background-color: $white;
  border: 1px solid #dfdfdf;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &__title {
    margin-left: 8px;
  }
}
