@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 88px;
  background-image: url("./../../../assets/images/darkBack.svg");
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;

  @media only screen and (max-width: $adaptive-footer) {
    max-width: 375px;
    width: 100%;
    height: 816px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 88px;
  }

  &__button {
    width: 188px;
    color: $white;

    @media only screen and (max-width: $adaptive-footer) {
      display: none;
    }
  }

  &__buttonMobile {
    display: none;
    color: $white;

    @media only screen and (max-width: $adaptive-footer) {
      display: block;
      max-width: 359px;
      width: 100%;
      margin-top: 23px;
    }
  }

  &__page404 {
    @include size(521px, 530px);
    margin-top: 143px;

    @media only screen and (max-width: $adaptive-footer) {
      display: none;
    }
  }

  &__page404m {
    display: none;

    @media only screen and (max-width: $adaptive-footer) {
      display: block;
      width: 97%;
      margin-top: 73px;
    }
  }

  &__title {
    display: none;

    @media only screen and (max-width: $adaptive-footer) {
      display: block;
      width: 319px;
      color: $white;
      text-align: center;
      margin-top: 13px;
    }
  }
}
