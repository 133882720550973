@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.documentOthers {
  margin-top: 28px;

  @media screen and (max-width: $breakpoint-xs) {
    margin-top: 24px;
  }

  &__mainLoad {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 350px;
  }

  &__loader {
    top: 0;
  }

  &__empty {
    @include font-text-medium;
    line-height: 40px;
    position: relative;
    width: 100%;
    height: 375px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__cardsContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (max-width: $breakpoint-xs) {
      gap: 16px;
    }
  }
}
