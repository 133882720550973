@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.header {
  max-width: $main-container;
  background-color: $white;
  width: 100%;
  height: $header-height;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);

  &_floatMode {
    animation: floatDown 400ms linear;
    width: calc(100% - 80px);
    position: fixed;
    z-index: $header-z-index;
    height: 72px;
    top: 0;

    @media screen and (max-width: $advantages-xl) {
      width: 100%;
    }
  }

  @keyframes floatDown {
    0% {
      transform: translateY(-72px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @media only screen and (max-width: $breakpoint-lg) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  @media only screen and (max-width: $breakpoint-xs) {
    height: $header-height-adaptive;
    overflow: visible;
  }

  &__content {
    max-width: 1160px;
    width: 100%;
    margin: 0 auto;
    background-color: $white;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media only screen and (max-width: $breakpoint-lg) {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      padding: 0 8px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      height: 100%;
    }
  }

  &__contentLeft {
    display: flex;
    align-items: center;
    position: relative;

    @media only screen and (max-width: 1359px) {
      margin-left: 10px;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      margin-left: 0;
    }
  }

  &__logo {
    @include size(122px, 26px);
    display: flex;
    cursor: pointer;

    @media only screen and (max-width: $adaptive) {
      display: none;
    }
  }

  &__logoM {
    display: none;

    @media only screen and (max-width: $adaptive) {
      display: block;
    }
  }

  &__nav {
    display: flex;
    margin-left: 64px;
    gap: 40px;

    @media only screen and (max-width: 1182px) {
      margin-left: 85px;
      gap: 20px;
    }

    @media only screen and (max-width: 1119px) {
      margin-left: 65px;
    }

    @media only screen and (max-width: 1095px) {
      font-size: 15px;
      gap: 15px;
    }

    @media only screen and (max-width: 1029px) {
      margin-left: 25px;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      display: none;
    }
  }

  &__navTitle {
    letter-spacing: 0.076em;
    cursor: pointer;
    user-select: none;
    color: $main-black;
    transition: color 200ms ease-in;

    &:hover {
      color: $yellow;
    }
  }

  &__contentRight {
    display: flex;
    align-items: center;
    position: relative;
    gap: 16px;

    @media only screen and (max-width: 1359px) {
      margin-right: 10px;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      margin-right: 0;
    }

    @media only screen and (max-width: $adaptive) {
      display: flex;
      gap: 8px;
    }
  }

  &__avatarWrapper {
    @include size(56px, 56px);
    border-radius: 50%;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
    border: 1px solid $light-grey;

    &:hover {
      opacity: 0.85;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      display: none;
    }
  }

  &__avatarImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__enterImg {
    display: flex;
    align-items: center;
    margin-left: 11px;
    height: 100%;
  }

  &__basketBtn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 1px solid $light-grey;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border-radius: 50%;

    &:hover {
      transition: 0.3s;
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      width: 48px;
      height: 48px;
    }
  }

  &__basketIcon {
    @media only screen and (max-width: $breakpoint-sm) {
      transform: scale(0.71);
    }
  }

  &__basketCount {
    @include font-text-counter;
    position: absolute;
    min-width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $yellow;
    border-radius: 50px;
    bottom: 42px;
    left: 41.5px;
    padding: 4px;

    @media only screen and (max-width: $breakpoint-lg) {
      min-width: 12px;
      height: 12px;
      bottom: 35px;
      left: 35.5px;
    }
  }

  &__rightBurger {
    @include size(56px, 56px);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.16);
    gap: 7px;
    transition: 0.3s ease;

    &:hover {
      background-color: $main-black;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      display: none;
    }
  }

  &__rightBurger:hover .header__rightBurgerLine {
    background-color: $white;
  }

  &__rightBurgerLine {
    display: block;
    width: 20px;
    height: 2px;
    background-color: $main-black;
    transition: 0.3s ease;
  }

  &__rightBurgerM {
    display: none;

    @media only screen and (max-width: $breakpoint-lg) {
      display: block;
      @include size(48px, 48px);
    }

    @media only screen and (max-width: $adaptive) {
      margin: 0;
    }
  }
}
