@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  max-width: $main-container;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    max-width: 1160px;
    width: 100%;
    margin-top: 88px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - $footer-height - $header-height);

    @media only screen and (max-width: $adaptive) {
      min-height: 0;
      margin-top: 68px;
    }
  }

  &__loader {
    top: 0;
    left: auto;
    right: auto;
  }

  &__button {
    width: 260px;
    color: $white;
    margin-top: 25px;
  }

  &__title {
    width: 500px;
    color: $main-black;
    text-align: center;
    margin-top: 24px;

    @media only screen and (max-width: $service) {
      width: 360px;
      font-size: 24px;
    }
  }

  &__description {
    margin-top: 24px;
    color: $dark-grey;

    @media only screen and (max-width: $service) {
      width: 290px;
      text-align: center;
    }
  }

  &__footer {
    max-width: 1160px;
    flex-grow: 1;

    @media only screen and (max-width: $service) {
      display: flex;
      margin-top: 148px;
      margin-left: 0;
    }
  }
}
