@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.paymentMethods {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  flex-wrap: wrap;
  row-gap: 16px;

  @media only screen and (max-width: 540px) {
    flex-direction: column;
  }

  @media only screen and (max-width: $breakpoint-xs) {
    margin-top: 23px;
  }

  &__check {
    width: max-content;
    margin: 0 8px 0 0;
    span {
      color: $main-black;
    }

    &_disabled {
      span {
        color: $light-grey;
      }

      border: 1px solid $light-grey;
    }
  }

  &__sberButton {
    gap: 8px;
    width: 100%;
    height: 56px;
    color: #333;
    display: flex;
    font-size: 16px;
    cursor: pointer;
    max-width: 496px;
    font-family: Roboto;
    align-items: center;
    border-radius: 100px;
    justify-content: center;
    transition: opacity 200ms;
    border: 1px solid #d0d7dd;

    &:hover {
      opacity: 0.8;
    }
  }

  &__sberMiniButton {
    gap: 8px;
    height: 32px;
    display: flex;
    padding: 0 12px;
    cursor: pointer;
    user-select: none;
    margin: 0 8px 0 0;
    width: max-content;
    border-radius: 40px;
    align-items: center;
    transition: border 200ms;
    border: 1px solid $light-grey;
    @include font-text-reduced($main-black);

    &:hover {
      border: 1px solid $main-black;
    }

    &_checked {
      color: $main-black;
      background: #d0d7dd;
      border: 1px solid $main-black;
    }
  }

  &__sberMiniButtonText {
    margin-top: 2px;
  }

  &__sberMiniButtonUncheck {
    border: 1px solid grey;
    border-radius: 100%;
    height: 16px;
    width: 16px;
  }
}
