@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.inputBlock {
  position: relative;
  display: flex;
  flex-direction: column;

  &__label {
    color: $grey;
    font-size: 14px;
    line-height: 24px;
  }

  &__inputWrapper {
    position: relative;
    width: 100%;
    height: min-content;
  }

  &__input {
    width: 100%;
    height: 72px;
    border-radius: 15px;
    background-color: $white;
    border: 1px solid $light-grey;
    padding: 0 8px 0 16px;
    font-size: 22px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &::placeholder {
      color: $grey;
    }

    &:read-only {
      color: $dark-grey;
    }
  }

  &__errorText {
    @include font-text-reduced($text-color-error);
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: calc(100% + 4px);
    text-align: end;
    line-height: 15px;
  }

  &__infoIconWrapper {
    position: relative;
    width: min-content;
  }

  &__infoIcon {
    position: relative;
    margin-top: 5px;
    margin-left: 8px;
    cursor: pointer;
  }

  &__infoIconPopup {
    @include font-text-reduced-mini;
    position: absolute;
    width: max-content;
    max-width: 142px;
    height: auto;
    padding: 8px;
    background-color: $white;
    top: 27px;
    right: 0;
    z-index: 500;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.24);
    border-radius: 8px;
    text-align: end;
  }
}
