@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.flatPlan {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $popup-z-index;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__popupWrapper {
    @include popupSpecialWrapper(1480px);
    width: 100%;
  }

  &__popup {
    width: 100%;
    max-width: 1480px;
    height: 100vh;
    display: flex;

    @media screen and (max-width: $breakpoint-xm) {
      @include popupScrollAdaptive;
      border-radius: 24px 24px 0 0;
      animation: 0.8s ease showModalAdaptive;
    }
  }

  &__empty {
    position: relative;
    max-width: 1360px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-width: $breakpoint-xm) {
      height: 80vh;
    }
  }

  &__popupAside {
    width: 80px;
    background-image: url("../../../../assets/images/aside.png");
    background-size: contain;
    background-repeat: repeat-y;
    overflow-y: hidden;

    @media screen and (max-width: $breakpoint-xxl) {
      display: none;
    }
  }

  &__content {
    position: relative;
    display: flex;
    max-width: 1360px;
    width: 100%;
    height: 100%;
    background-color: $white;
    scrollbar-width: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    padding-top: 96px;

    @media screen and (max-width: $breakpoint-lg) {
      flex-direction: column;
      padding-top: 125px;
    }

    @media screen and (max-width: $breakpoint-xm) {
      width: 100%;
      height: max-content;
      border-radius: 24px 24px 0 0;
      margin: 0;
      padding-top: 72px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      padding: 72px 8px 0 4px;
    }
  }

  &__close {
    @include size(24px, 24px);
    position: absolute;
    top: 80px;
    right: 120px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }

    @media screen and (max-width: $breakpoint-xl) {
      top: 70px;
      right: 50px;
    }

    @media screen and (max-width: $breakpoint-lg) {
      right: 15px;
    }

    @media screen and (max-width: $breakpoint-xm) {
      top: 36px;
      right: 22px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      width: 16px;
      height: 16px;
    }
  }

  &__leftSection {
    margin-left: 80px;
    max-width: 285px;

    @media screen and (max-width: $breakpoint-xxl) {
      margin-left: 50px;
    }

    @media screen and (max-width: $breakpoint-xl) {
      width: 28%;
      margin-left: 30px;
    }

    @media screen and (max-width: $breakpoint-lg) {
      width: 98%;
      max-width: 870px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
    }

    @media screen and (max-width: $breakpoint-xm) {
      flex-direction: column;
    }
  }

  &__title {
    @include font-title-very-big;
    max-width: 233px;
    margin-bottom: 30px;

    @media screen and (max-width: $breakpoint-lg) {
      @include font-title-big;
      width: min-content;
    }

    @media screen and (max-width: $breakpoint-xm) {
      max-width: max-content;
      width: max-content;
      margin: 0 auto 30px auto;
    }

    @media screen and (max-width: $breakpoint-xs) {
      @include font-text-big;
      margin: 0;
    }
  }

  &__flatInfo {
    div {
      margin-bottom: 18px;
    }
    p {
      color: #888888;
      margin-bottom: 6px;

      @media screen and (max-width: $breakpoint-xs) {
        margin-bottom: 0;
      }
    }

    h5 {
      @media screen and (max-width: $breakpoint-xs) {
        margin-top: -2px;
      }
    }

    @media screen and (max-width: $breakpoint-xm) {
      max-width: 330px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 auto;
      column-gap: 65px;
      row-gap: 24px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      margin: 16px 0 0 0;
    }

    @media screen and (max-width: $breakpoint-xs) {
      max-width: 255px;
      column-gap: 60px;
      row-gap: 0;
    }
  }

  &__button {
    width: 285px;
    margin-top: 162px;
    color: $white;
    margin-bottom: 40px;

    @media screen and (max-width: $breakpoint-lg) {
      position: relative;
      max-width: 285px;
      width: 20%;
      min-width: 190px;
      padding: 0;
      margin-top: 0;
    }

    @media screen and (max-width: $breakpoint-xm) {
      display: none;
    }
  }

  &__buttonAdaptive {
    display: none;

    @media screen and (max-width: $breakpoint-xm) {
      width: 80%;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      margin: 23px auto 28px auto;
    }

    @media screen and (max-width: $breakpoint-xs) {
      width: 100%;
      margin-top: 35px;
    }
  }

  &__rightSection {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: max-content;
    max-width: 800px;
    max-height: 100vh;
    margin-left: 106px;
    margin-top: 12px;

    @media screen and (max-width: $breakpoint-xl) {
      margin-left: 50px;
    }

    @media screen and (max-width: $breakpoint-lg) {
      margin: 0 auto;
      margin-top: 40px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      margin-top: 28px;
    }
  }

  &__planEmpty {
    @include font-text-medium;
    max-width: 800px;
    width: 100%;
    max-height: 100vh;
    height: 100%;
    margin-top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $breakpoint-xm) {
      height: 150px;
      text-align: center;
    }
  }

  &__photo {
    z-index: 999;
    margin-bottom: 80px;
  }
}

@keyframes showModalAdaptive {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0;
  }
}
