@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.rentalInfo {
  position: relative;
  width: 100%;
  max-width: 1161px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  border: 1px solid $light-grey;
  border-radius: 8px;
  padding: 24px 33px 21px 16px;

  @media screen and (max-width: $breakpoint-xm) {
    padding: 16px 16px 24px 16px;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      border-bottom: 1px solid $light-grey;
      align-self: center;
      top: 69px;
    }
  }

  &__top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 32px;

    @media screen and (max-width: $breakpoint-xm) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 23px;
    }
  }

  &__title {
    @include font-text-medium;
    width: max-content;
    font-weight: 600;
    margin-right: 16px;

    @media screen and (max-width: $breakpoint-xm) {
      @include font-subtitle-bold;
    }
  }

  &__number {
    @include font-subtitle-bold;
    width: max-content;

    @media screen and (max-width: $breakpoint-xm) {
      @include font-text($main-black, 24px);
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;

    @media screen and (max-width: $breakpoint-xm) {
      gap: 16px;
    }
  }

  &__propBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 88px;

    @media screen and (max-width: $breakpoint-sm) {
      column-gap: 40px;
    }

    @media screen and (max-width: $breakpoint-xm) {
      width: 100%;
      flex-direction: column;
      gap: 16px 10px;
    }
  }

  &__propWrapper {
    position: relative;
    width: max-content;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $breakpoint-xm) {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      gap: 16px;
    }
  }

  &__hint {
    position: absolute;
    display: inline-block;

    @media screen and (max-width: $breakpoint-xm) {
      bottom: 2px;
      margin-left: 2px;
    }
  }

  &__hintIcon {
    cursor: pointer;
    width: 16px;
    height: 16px;

    @media screen and (max-width: $breakpoint-xm) {
      transform: scale(1.3);
      bottom: 2px;
    }
  }

  &__hintIcon:hover ~ &__hintTextWrapper {
    display: block;
  }

  &__hintTextWrapper {
    @include font-text-reduced-mini;
    position: absolute;
    display: none;
    width: max-content;
    max-width: 130px;
    padding: 8px;
    background-color: $white;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.24);
    border-radius: 12px;
    top: -39px;
    left: 18px;
  }

  &__propTitle {
    position: relative;
    @include font-text($grey, 24px);
  }

  &__propValue {
    @include font-text-medium;
    line-height: 40px;
    display: inline-block;
    width: max-content;

    @media screen and (max-width: $breakpoint-xm) {
      @include font-text($main-black, 24px);
      text-align: end;
    }
  }
}
