@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.sliderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainSwiper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;

  @media only screen and (max-width: $adaptive) {
    width: 375px;
    height: 392px;
    display: block;
  }

  .swiper-slide {
    display: block;
    width: 100%;
  }

  .swiper-slide img {
    width: 100%;
    height: 720px;
    object-fit: cover;

    @media only screen and (max-width: $breakpoint-sm) {
      height: 600px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      height: 500px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      height: 392px;
    }
  }

  .swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    gap: 0 8px;

    @media only screen and (max-width: $adaptive) {
      margin-left: -2px;
      margin-bottom: 6px;
    }
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    opacity: 1;
    background: transparent;
    border: 2px solid $white;
  }

  .swiper-pagination-bullet-active {
    background: $white;
  }
}

.slideFrame,
.circleWrapper {
  width: 592px;
  height: 592px;
  position: absolute;
  z-index: 10;

  @media only screen and (max-width: 1300px) {
    @include size(510px, 510px);
  }

  @media only screen and (max-width: $breakpoint-sm) {
    @include size(520px, 520px);
  }

  @media only screen and (max-width: $breakpoint-xm) {
    @include size(410px, 410px);
  }

  @media only screen and (max-width: $breakpoint-xs) {
    @include size(304px, 304px);
  }
}
