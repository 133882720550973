@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.stepRenter {
  position: relative;
  max-width: 1161px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 57px;

  @media screen and (max-width: $breakpoint-xm) {
    margin-top: 25px;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    margin-bottom: 32px;
    row-gap: 24px;

    @media screen and (max-width: $breakpoint-xm) {
      margin-bottom: 31px;
    }
  }

  &__title {
    @include font-text-big;
    line-height: 40px;
    margin-right: 24px;

    @media screen and (max-width: $breakpoint-xm) {
      width: 90%;
    }
  }

  &__stepNum {
    @include font-subtitle-light($yellow);
  }

  &__rentalInfo {
    width: 100%;

    @media only screen and (max-width: $breakpoint-xlg) {
      max-width: 816px;
      margin: 0 auto;
    }
  }

  &__rentalTitle {
    @include font-text-middle($yellow);
    margin-bottom: 16px;

    @media screen and (max-width: $breakpoint-xm) {
      display: none;
    }
  }

  //---- инфор-ия по арендатору

  &__infoRenter {
    margin-top: 29px;

    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }

    @media screen and (max-width: $breakpoint-xm) {
      margin-top: 33px;
    }
  }

  &__renterTitle {
    @include font-text-middle($yellow);
    @media screen and (max-width: $breakpoint-xs) {
      line-height: 24px;
    }
  }

  &__renterHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media screen and (max-width: $breakpoint-xs) {
      align-items: flex-start;
      margin-bottom: 15px;
    }
  }

  &__renterFillBtnContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 39px 0 0 0;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      max-width: 860px;
      height: 1px;
      border-top: 1px solid $light-grey;
      left: 0;

      @media only screen and (max-width: $breakpoint-xlg) {
        width: 72%;
      }

      @media only screen and (max-width: $breakpoint-xl) {
        width: 65%;
      }

      @media only screen and (max-width: $breakpoint-lg) {
        display: none;
      }
    }

    @media only screen and (max-width: $breakpoint-xm) {
      display: none;
    }
  }

  &__renterFillBtn {
    @include font-text($white);
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    background-color: $yellow;
    padding: 6px 16px;
    border-radius: 24px;

    &:hover {
      opacity: 0.8;
    }

    @media screen and (max-width: $breakpoint-xm) {
      display: none;
    }

    &_adaptive {
      display: none;

      @media screen and (max-width: $breakpoint-xm) {
        display: flex;
        margin-bottom: 16px;
      }
    }
  }

  &__button {
    width: 100%;
    max-width: 311px;
    margin-top: 64px;

    @media screen and (max-width: $breakpoint-xm) {
      max-width: 365px;
      margin-top: 32px;
    }
  }

  &__requisitesBlock {
    width: 100%;
    margin-top: 29px;

    @media only screen and (max-width: $breakpoint-xlg) {
      max-width: 740px;
      margin: 29px auto 0 auto;
    }
  }

  &__modalReuseTitle {
    @include font-text-big;
  }

  &__loader {
    top: 450px;
    bottom: auto;
  }
}
