@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.myServices {
  max-width: 780px;
  width: 100%;

  &__cardsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__pagination {
    position: relative;
    max-width: 310px;
    margin: 32px auto 0 auto;
  }

  &__cardsEmpty {
    width: 100%;
    max-width: 760px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 63px;

    @media screen and (max-width: $breakpoint-xm) {
      margin-top: 40px;
    }

    @media screen and (max-width: $breakpoint-lg) {
      margin-bottom: 64px;
    }
  }

  &__cardsEmptyTitle {
    @include font-text-medium;
    line-height: 40px;
    text-align: center;
  }

  &__cardsEmptyBtn {
    @include font-text($white, 24px);
    width: 260px;
    height: 56px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }

  &__loadingBlock {
    position: relative;
    height: 624px;
  }

  &__mainLoader {
    top: 200px;
    bottom: auto;
  }
}
