@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.petForm {
  position: relative;
  width: 100%;
  display: flex;

  &__header {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    top: -49px;

    @media screen and (max-width: $breakpoint-xm) {
      top: -57px;
    }
  }

  &__trashCan {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__inputs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $breakpoint-xl) {
      gap: 16px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
    }
  }

  &_tenantsMode &__inputs {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 16px;

    @media screen and (max-width: 1287px) {
      flex-direction: column;
    }
  }

  &__type {
    max-width: 376px;
    width: 33%;

    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &_tenantsMode &__type {
    max-width: 243px;
    width: 33%;

    @media only screen and (max-width: 1287px) {
      max-width: 376px;
      width: 100%;
    }
  }

  &__breed {
    max-width: 376px;
    width: 33%;

    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &_tenantsMode &__breed {
    max-width: 243px;
    width: 33%;

    @media only screen and (max-width: 1287px) {
      max-width: 376px;
      width: 100%;
    }
  }

  &__vetPassport {
    max-width: 376px;
    width: 33%;

    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &_tenantsMode &__vetPassport {
    max-width: 243px;
    width: 33%;

    @media only screen and (max-width: 1287px) {
      max-width: 376px;
      width: 100%;
    }
  }
}
