@import "../../../../styles/variables.scss";

.pages {
  position: relative;
  display: block;
  width: 100%;

  @media only screen and (max-width: $breakpoint-xxl) {
    padding-left: 30px;
  }

  @media only screen and (max-width: $breakpoint-xxl) {
    width: 98%;
  }

  @media only screen and (max-width: $breakpoint-lg) {
    max-width: 1255px;
    width: 98%;
  }

  @media only screen and (max-width: $breakpoint-sm) {
    padding-left: 0;
  }

  @media only screen and (max-width: $breakpoint-xs) {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
  }
}
