@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.menu {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $popup-z-index;
  animation: 0.5s ease showModal;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.08) #ffffff00;

  &::-webkit-scrollbar {
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    opacity: 0;
  }

  &__container {
    max-width: 1480px;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    display: flex;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__aside {
    width: 80px;
    height: 100%;
    background-image: url("../../../../../assets/images/aside.png");
    background-size: contain;
    background-repeat: repeat-y;
    flex-shrink: 0;

    @media only screen and (max-width: $advantages-xl) {
      display: none;
    }
  }

  &__content {
    width: calc(100% - 80px);
    height: 100vh;
    background-color: $white;
    position: relative;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-width: $breakpoint-xl) {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    width: 58%;
    margin: auto 42%;
  }

  &__close {
    border-radius: 50%;
    border: 1px solid $light-grey;
    cursor: pointer;
    margin-top: 24px;
    margin-right: 15%;
  }

  &__mobileBtn {
    margin-top: 24px;
    margin-right: 16px;
    border-radius: 50%;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover {
      filter: invert(1);
      transition: 0.3s ease;
    }
  }

  &__phone {
    margin-top: 58px;

    @media only screen and (max-width: $advantages-xl) {
      margin-left: 0;
    }
  }
}

.main {
  display: flex;
  justify-content: space-between;
  margin-top: 98px;
  background-color: $white;

  @media only screen and (max-height: 700px) {
    padding-bottom: 100px;
    margin-top: 0;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    margin-top: 10.2%;
    margin-left: 120px;
    gap: 48px;

    @media only screen and (max-width: $advantages-xl) {
      margin-left: 3.5%;
    }
  }

  &__titles {
    display: flex;
    align-items: center;
  }

  &__number {
    color: $grey;
  }

  &__title {
    line-height: 40px;
    margin-left: 48px;
    transition: color 200ms ease-in;

    &:hover {
      color: $brown;
    }

    @media only screen and (max-width: 500px) {
      font-size: 34px;
    }
  }

  &__img {
    margin-top: 2.7%;
    margin-left: 1%;

    @media only screen and (max-width: $breakpoint-xxl) {
      margin-left: 50px;
    }

    @media only screen and (max-width: $advantages-xl) {
      margin-top: 0;
    }

    @media only screen and (max-height: 700px) {
      height: 310px;
      margin-top: 4%;
      margin-left: 6%;
    }

    @media only screen and (max-width: 772px) {
      display: none;
    }
  }
}

@keyframes showModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
