@import "../../../styles/variables.scss";

.checkBox {
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }

  &__inactive:hover path {
    fill: $yellow;
    opacity: 1;
  }
}
