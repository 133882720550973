@import "../../../../../../styles/mixins.scss";
@import "../../../../../../styles/variables.scss";

.archiveDocuments {
  width: 100%;
  margin-top: 64px;
  margin-left: 40px;

  @media screen and (max-width: $breakpoint-xxl) {
    margin-left: 0;
  }

  @media screen and (max-width: $breakpoint-sm) {
    margin-top: 24px;
  }

  @media screen and (max-width: $adaptive) {
    padding: 0 7px;
  }

  &__tabsContainer {
    width: 100%;
    margin-top: 33px;

    @media screen and (max-width: 1110px) {
      overflow-x: scroll;
      scrollbar-width: thin;
      scrollbar-color: rgba(0, 0, 0, 0.08) #ffffff00;

      &::-webkit-scrollbar {
        background: transparent;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: $light-grey;
        height: 4px;
        border-radius: 10px;
      }
    }

    @media screen and (max-width: $breakpoint-xm) {
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__tabs {
    width: max-content;
  }

  &__agreements,
  &__bills,
  &__acts {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
