@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.modal {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.48);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  z-index: $popup-z-index;

  .content {
    width: 271px;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    background-color: $main-black;
    padding-left: 16px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &__close {
      @include size(48px, 48px);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 67px;
      border: 1px solid rgba(255, 255, 255, 0.12);
      position: absolute;
      top: 24px;
      right: 7px;
    }

    &__mainNav {
      display: flex;
      flex-direction: column;
      margin-top: 82px;
    }
    &__mainNavTitle {
      font-size: 24px;
      line-height: 32px;
      color: $white;
      margin-bottom: 16px;
    }

    &__subTitle {
      color: $dark-grey;
      margin-top: 12px;
    }

    &__accountNav {
      display: flex;
      flex-direction: column;
      margin-top: 12px;
    }

    &__accountNavTitle {
      font-size: 24px;
      line-height: 32px;
      color: $white;
      margin-bottom: 16px;
    }

    &__logoutBtn {
      font-size: 24px;
      line-height: 32px;
      color: $white;
      margin-bottom: 16px;
      text-align: start;
    }

    &__docsBtn {
      font-size: 24px;
      line-height: 32px;
      color: $white;
      margin-bottom: 16px;
      text-align: start;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    &__docsBtnArrow {
      margin-left: 87px;
      margin-bottom: 2px;
      transform: rotate(180deg);
      transition: 0.3s;

      &_visible {
        transform: rotate(0deg);
      }
    }

    &__docsSubMenu {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      margin-bottom: 16px;
      margin-left: 16px;
    }

    &__subNavItem {
      @include font-text-middle(rgba(255, 255, 255, 0.64));
    }
  }
}
