@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/variables.scss";

.myBooking {
  width: 100%;
  padding: 48px 0 0 40px;

  @media screen and (max-width: $breakpoint-xxl) {
    padding-left: 0;
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding-top: 24px;
  }

  @media screen and (max-width: $adaptive) {
    padding: 24px 7px 0 7px;
  }

  &__backBtn {
    margin-bottom: 33px;
  }

  &__tabsContainer {
    width: 100%;

    @media screen and (max-width: 1110px) {
      overflow-x: scroll;
      scrollbar-width: thin;
      scrollbar-color: rgba(0, 0, 0, 0.08) #ffffff00;

      &::-webkit-scrollbar {
        background: transparent;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: $light-grey;
        height: 4px;
        border-radius: 10px;
      }
    }

    @media screen and (max-width: $breakpoint-xm) {
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__tabs {
    width: max-content;
  }

  &__menuItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 40px;
    background-color: $yellow;
    color: $white;
    transition: 0.3s;
    padding: 8px 16px;
    border-radius: 40px;
    margin-bottom: 16px;

    &:hover {
      opacity: 0.8;
    }
  }
}
