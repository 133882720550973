@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.footer {
  max-width: $main-container;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;

  @media only screen and (max-width: $breakpoint-sm) {
    display: none;
  }

  @media only screen and (max-width: $adaptive) {
    display: none;
  }

  &__copyTop {
    margin-left: 45%;
    width: 100%;
    color: $grey;

    @media only screen and (max-width: $main-part) {
      margin-left: 10%;
    }
  }

  &__dev {
    margin-left: -2.3%;
    text-align: center;

    &:hover {
      opacity: 0.8;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 14px;
    color: $grey;
  }

  &__agreement {
    margin-left: -50.5%;
    color: $grey;
    cursor: pointer;

    &:hover {
      color: $dark-grey;
    }

    @media only screen and (max-width: $main-part) {
      margin-left: -15%;
    }
  }
}
