.inputPassword {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 72px;
  -webkit-appearance: none;

  &__eyeBtn {
    position: absolute;
    border: none;
    background-color: transparent;
    right: 24px;
    top: 44px;
    cursor: pointer;
  }
}
