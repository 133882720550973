@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

$favorite-adaptive: 660px;
$max-width-photo: 114px;

.serviceCard {
  width: 100%;
  max-width: 760px;
  max-height: max-content;
  display: flex;
  background-color: $white;
  border: 1px solid $light-grey;
  border-radius: 16px;
  overflow: hidden;

  &__photoWrapper {
    max-width: $max-width-photo;
    width: 30%;
    height: 174px;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    overflow: hidden;

    @media only screen and (max-width: $breakpoint-lg) {
      border-radius: 16px 0px 8px 0;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 84px;
    }

    &_roundCorners {
      border-radius: 16px 0px 8px 0px;
    }
  }

  &_availableMode &__photoWrapper {
    @media only screen and (max-width: $breakpoint-xm) {
      min-width: 84px;
      height: 128px;
      min-height: auto;
    }
  }

  &_myServicesMode &__photoWrapper {
    @media only screen and (max-width: $breakpoint-xm) {
      min-width: 84px;
      height: 126px;
      min-height: auto;
    }
  }

  &_selectedMode &__photoWrapper {
    @media only screen and (max-width: $breakpoint-xm) {
      min-width: 84px;
      height: 126px;
      min-height: auto;
    }
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 500ms transform;
  }

  &__photoEmpty {
    @include font-text;
    max-width: $max-width-photo;
    width: 30%;
    min-height: 174px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px;
    text-align: center;
  }

  &_availableMode &__photoEmpty {
    @media only screen and (max-width: $breakpoint-xm) {
      min-width: 84px;
      width: 35%;
      height: 128px;
      min-height: auto;
      border-radius: 16px 0px 8px 0px;
      font-size: 14px;
    }
  }

  &__infoContainer {
    position: relative;
    width: 100%;
  }

  &_selectedMode &__infoContainer {
    padding: 24px 64px 16px 24px;

    @media only screen and (max-width: $breakpoint-xxl) {
      padding-right: 24px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      padding: 16px 16px 0 16px;
    }
  }

  &_availableMode &__infoContainer {
    padding: 24px 24px 16px 24px;

    @media only screen and (max-width: $breakpoint-xxl) {
      padding-right: 32px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      padding: 16px 16px 14px 16px;
    }
  }

  &_myServicesMode &__infoContainer {
    padding: 24px 24px 0 24px;

    @media only screen and (max-width: $breakpoint-xxl) {
      padding-right: 32px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      padding: 16px 16px 12px 16px;
    }
  }

  &__serviceTitle {
    @include font-text-big;
    line-height: 40px;

    @media only screen and (max-width: $breakpoint-xm) {
      @include font-text-middle;
      line-height: 24px;
    }
  }

  &_selectedMode &__serviceTitle {
    max-width: calc(100% - 64px);
  }

  &__description {
    @include font-text($dark-grey);
    margin-top: 9px;

    @media only screen and (max-width: $breakpoint-xs) {
      @include font-text-reduced($grey, 16px);
    }

    @media only screen and (max-width: $adaptive) {
      margin-top: 8px;
    }
  }

  &__orderBtn {
    width: 143px;
    height: 48px;
    padding: 0;
  }

  &__propsContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 48px;
    margin: 14px 0 32px 0;

    @media only screen and (max-width: 600px) {
      gap: 12px;
      margin-bottom: 8px;
      justify-content: space-between;
      max-width: 238px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      margin: 8px 0 7px 0;
    }
  }

  &_myServicesMode &__propsContainer {
    @media only screen and (max-width: $breakpoint-xs) {
      margin-bottom: 0;
    }
  }

  &__props {
    display: flex;
    flex-direction: column;

    &_withBtnDate {
      @media only screen and (max-width: $breakpoint-xs) {
        align-items: flex-end;
      }
    }
  }

  &__propsName {
    @include font-text($grey);
    line-height: 24px;

    @media only screen and (max-width: $breakpoint-xm) {
      @include font-text-reduced($grey, 16px);
      margin-bottom: 3px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      position: relative;
      &::after {
        content: ":";
        position: absolute;
        display: block;
        top: 0;
        right: -4px;
      }
    }
  }

  &__propsValue {
    @include font-text-subtitle-small;

    @media only screen and (max-width: $breakpoint-xm) {
      @include font-text;
      line-height: 24px;
    }
  }

  &__morePriceWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 17px;

    @media only screen and (max-width: $breakpoint-xm) {
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 8px;
    }

    @media only screen and (max-width: $adaptive) {
      margin-top: 13px;
    }
  }

  &__price {
    @include font-text-middle;
    margin-top: -5px;
  }

  &__buttonMore {
    @include font-text-reduced;
    font-weight: 600;
    border: 1px solid $light-grey;
    letter-spacing: 0.08em;
    padding: 0;
    width: 143px;
    height: 48px;

    @media only screen and (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }

  &__payButtons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;

    @media only screen and (max-width: $breakpoint-xs) {
      gap: 8px;
    }
  }

  &__buttonPay {
    width: 143px;
    height: 48px;
    padding: 0;
  }

  &__buttonSelectDate {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 153px;
    height: 48px;
    border: 1px solid $light-grey;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.08em;

    &:hover {
      color: $white;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      width: 145px;
    }
  }

  &__buttonCancel {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid $light-grey;
    cursor: pointer;
    top: 24px;
    right: 23px;

    &:hover {
      opacity: 0.8;
    }

    @media screen and (max-width: $breakpoint-xm) {
      transform: scale(0.6);
      top: 7px;
      right: 7px;
    }
  }
}
