.buttonSberBusiness {
  gap: 8px;
  width: 100%;
  height: 56px;
  color: #333;
  display: flex;
  font-size: 16px;
  cursor: pointer;
  max-width: 496px;
  font-family: "Roboto";
  align-items: center;
  border-radius: 100px;
  justify-content: center;
  transition: opacity 200ms;
  border: 1px solid #d0d7dd;

  &:hover {
    opacity: 0.8;
  }
}
