@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/variables.scss";

.floor {
  position: relative;
  transition: opacity 400ms ease-in-out;
  &__info {
    width: 195px;
    height: max-content;
    background-color: #3a3c40;
    border: 1px solid rgba(255, 255, 255, 0.24);
    border-radius: 12px;
    padding: 8px;
    margin-left: 280px;

    @media screen and (max-width: 1325px) {
      margin-left: 250px;
    }
  }
  &__number {
    font-size: 24px;
    line-height: 32px;
  }
  &__free {
    font-size: 16px;
    color: #8f939c;
  }
}
