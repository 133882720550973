@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.inputBlock {
  position: relative;
  width: 100%;
  // min-width: 200px;
  display: flex;
  flex-direction: column;

  &__inputWrapper {
    width: 100%;
    position: relative;
    height: min-content;
  }

  &__errorText {
    @include font-text-reduced($text-color-error);
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    text-align: end;
    top: calc(100% + 4px);
    line-height: 15px;
  }

  &__label {
    color: $grey;
    font-size: 14px;
    line-height: 24px;
  }

  &__input {
    position: relative;
    width: 100%;
    height: 72px;
    border-radius: 15px;
    background-color: $white;
    border: 1px solid $light-grey;
    padding-left: 16px;
    font-size: 24px;
    line-height: 40px;

    &::placeholder {
      font-size: 20px;
      color: $grey;
    }

    &:read-only {
      color: $dark-grey;
    }

    @media only screen and (max-width: $adaptive) {
      @include size(360px, 72px);
    }
  }

  &__iconCalendar {
    position: absolute;
    right: 16px;
    top: 17px;

    &_blackMode {
      path {
        fill: black;
      }
    }
  }
}
