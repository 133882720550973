@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.tabs {
  display: flex;
  gap: 40px;
  width: max-content;
  border-bottom: 1px solid $light-grey;

  &_buttonMode {
    border-bottom: none;
    gap: 11px;
  }

  &__tab {
    position: relative;
    @include font-text-medium;
    line-height: 40px;
    color: $dark-grey;
    cursor: pointer;
    text-align: center;
    padding-bottom: 5px;
    flex-shrink: 0;
    -webkit-tap-highlight-color: transparent;

    &_selected {
      color: $main-black;
      border-bottom: 2px solid $yellow;
    }
  }

  &_buttonMode &__tab {
    @include font-text-reduced($dark-grey, 16px);
    display: flex;
    align-items: center;
    border: 1px solid $light-grey;
    border-radius: 24px;
    padding: 7px 16px;

    &_selected {
      background: rgba(207, 176, 35, 0.08);
      border: 1px solid $yellow;
      color: $main-black;
    }
  }

  &__hint {
    position: relative;
  }

  &__hintIcon {
    width: 17px;
    height: 17px;
    fill: $yellow;
    margin-left: 2px;
  }

  &__hintText {
    max-width: 250px;
    top: 20px;
    text-align: start;
    height: max-content;

    @media screen and (max-width: 1245px) {
      left: auto;
      right: 0;
    }

    @media screen and (max-width: 476px) {
      left: 0;
      right: auto;
    }

    @media screen and (max-width: 350px) {
      max-width: 200px;
    }
  }

  &__upperText {
    @include font-text-reduced($main-black, 16px);
    position: absolute;
  }
}
