@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.hint {
  position: relative;
  display: inline-flex;
  width: max-content;
  height: max-content;

  &__icon {
    width: 12px;
    height: 12px;
    fill: $grey;

    path {
      fill: inherit;
    }
  }

  &__text {
    @include font-text-reduced-mini;
    position: absolute;
    width: max-content;
    display: none;
    flex-direction: column;
    max-width: 250px;
    background-color: $white;
    padding: 8px;
    border-radius: 12px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.24);
    left: 3px;
    bottom: 0;
    gap: 4px;

    &_visible {
      display: flex;
    }
  }
}
