@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.input {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $white;
  padding-bottom: 14px;
  padding-left: 0;
  margin-bottom: 32px;
  font-size: 20px;
  border-radius: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: $white;
  background: url("../../../../../../assets/images/calendarBook.svg") no-repeat
    right;
  background-position: top 8px right 9px;
  caret-color: transparent;

  &:focus {
    border: 0;
    border-bottom: 1px solid $white;
  }

  &::placeholder {
    color: $white;
  }
}
