@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.warning {
  max-width: 762px;
  width: 100%;
  border-radius: 16px;
  background-color: $white;
  padding: 16px;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__leftContent {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__title {
    width: 321px;
    @include font-subtitle-light;
    line-height: 24px;
  }

  &__button {
    height: 48px;
  }
}