@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  max-width: $main-container;
  width: 100%;
  background-image: url("../../../../assets/images/greyBack.svg");
  padding: 65px 0 80px 0;
  @media only screen and (max-width: $adaptive) {
    padding: 32px 0 32px 0;
  }
  &__content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__header {
    color: $white;

    @media only screen and (max-width: $breakpoint-xxl) {
      margin-left: 0;
    }

    @media only screen and (max-width: 456px) {
      font-size: 34px;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 237px;
      letter-spacing: 0.04em;
      padding-top: 0;
      text-align: center;
      font-size: 40px;
    }
  }

  &__flats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 71px;

    @media only screen and (max-width: $breakpoint-xxl) {
      margin-left: 0;
      display: flex;
      justify-content: center;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
    }

    @media only screen and (max-width: $adaptive) {
      gap: 16px;
      margin-top: 15px;
    }
  }

  &__buttonWrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 78px;

    @media only screen and (max-width: $adaptive) {
      margin-top: 34px;
    }
  }

  &__button {
    position: relative;
    width: 97%;
    max-width: 312px;
    color: $white;
    font-weight: 600;
    @media only screen and (max-width: $breakpoint-xxl) {
      margin-right: 0;
    }
    @media only screen and (max-width: $adaptive) {
      width: 100%;
      max-width: 360px;
    }
  }
}
