@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.floorPlan {
  position: relative;
  width: max-content;
}

.eightApartments {
  &__polygon {
    position: absolute;
    background-color: transparent;
    fill: transparent;
    cursor: pointer;

    &:hover {
      fill: $yellow;
      opacity: 0.2;
      svg {
        fill-opacity: 1;
      }
    }
    &_one {
      width: 164.5px;
      left: 44px;
    }

    &_two {
      width: 199px;
      left: 150px;
    }

    &_three {
      width: 137px;
      left: 360px;
    }

    &_four {
      width: 243px;
      right: 0;
      top: 30px;
    }

    &_five {
      width: 234.5px;
      right: 6px;
      top: 197px;
    }

    &_six {
      width: 343.5px;
      right: 8px;
      bottom: 30px;
    }

    &_seven {
      width: 146.5px;
      right: 352px;
      bottom: 3px;
    }

    &_eight {
      width: 207.5px;
      left: 0;
      bottom: 32px;
    }
  }

  &__empty {
    @include font-text-medium;
    width: 700px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.eightApartmentsSpace {
  &__polygon {
    position: absolute;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      fill: $yellow;
      opacity: 0.2;
      svg {
        fill-opacity: 1;
      }
    }
    &_one {
      fill: transparent;
      width: 150px;
      left: 35px;
      top: 3px;
    }

    &_two {
      fill: transparent;
      width: 190px;
      left: 128px;
      top: -3px;
    }

    &_three {
      fill: transparent;
      width: 132px;
      left: 315px;
    }

    &_four {
      fill: transparent;
      width: 210.5px;
      right: 0;
      top: 29px;
    }

    &_five {
      fill: transparent;
      width: 193px;
      right: 11px;
      top: 190px;
    }

    &_six {
      fill: transparent;
      width: 193px;
      right: 11px;
      bottom: 202px;
    }

    &_seven {
      fill: transparent;
      width: 212.5px;
      right: 0;
      bottom: 30px;
    }

    &_eight {
      fill: transparent;
      width: 132.5px;
      left: 315px;
      bottom: 3px;
    }

    &_nine {
      fill: transparent;
      width: 133.5px;
      left: 182px;
      bottom: 3px;
    }

    &_ten {
      fill: transparent;
      width: 179.5px;
      left: 1px;
      bottom: 32px;
    }

    &_blocked {
      fill: $main-black;
      opacity: 0.2;
    }
  }
}

.tenApartments {
  &__polygon {
    position: absolute;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      fill: $yellow;
      opacity: 0.2;
      svg {
        fill-opacity: 1;
      }
    }

    &_one {
      fill: transparent;
      width: 150px;
      left: 35px;
      top: 3px;
    }

    &_two {
      fill: transparent;
      width: 190px;
      left: 128px;
      top: -3px;
    }

    &_three {
      fill: transparent;
      width: 132px;
      left: 315px;
    }

    &_four {
      fill: transparent;
      width: 210.5px;
      right: 0;
      top: 29px;
    }

    &_five {
      fill: transparent;
      width: 193px;
      right: 11px;
      top: 190px;
    }

    &_six {
      fill: transparent;
      width: 193px;
      right: 11px;
      bottom: 195px;
    }

    &_seven {
      fill: transparent;
      width: 212.5px;
      right: 0;
      bottom: 30px;
    }

    &_eight {
      fill: transparent;
      width: 132.5px;
      left: 315px;
      bottom: 3px;
    }

    &_nine {
      fill: transparent;
      width: 133.5px;
      left: 182px;
      bottom: 2px;
    }

    &_ten {
      fill: transparent;
      width: 179.5px;
      left: 1px;
      bottom: 32px;
    }

    &_blocked {
      fill: $main-black;
      opacity: 0.2;
    }
  }
}
