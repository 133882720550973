@import "../../../styles/variables.scss";

.faq {
  display: flex;
  max-width: $main-container;
  width: 100%;
  min-height: calc(100vh - $footer-height - $header-height);

  @media only screen and (max-width: $adaptive) {
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (max-width: $adaptive-footer) {
    min-height: calc(100vh - $footer-height-adaptive - $header-height-adaptive);
  }

  &__rightColumn {
    width: 72%;
    max-width: 980px;

    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
      padding-bottom: 56px;
    }

    @media only screen and (max-width: $adaptive) {
      padding-bottom: 0;
    }
  }

  &__faqBlock {
    max-width: 380px;
    width: 28%;
    height: 802px;
    background-color: $yellow;
    padding-top: 80px;
    padding-left: 120px;

    @media only screen and (max-width: $breakpoint-xxl) {
      padding-left: 30px;
      width: 26%;
    }

    @media only screen and (max-width: $advantages-xl) {
      padding-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      display: none;
    }
  }

  &__title {
    line-height: 48px;
    color: $white;
    letter-spacing: 0.1em;
  }

  &__header {
    display: none;

    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 29px;
      padding: 0 15px;
    }
  }

  &__questions,
  &__questionsEmpty {
    max-width: 794px;
    width: 100%;
    height: max-content;
    max-height: 746px;
    overflow-y: scroll;
    margin-top: 16px;
    margin-left: 101px;
    padding-right: 15px;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.08) #ffffff00;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      width: 7px;
      background: rgba(0, 0, 0, 0.08);
      border-radius: 15px;
    }

    @media only screen and (max-width: $breakpoint-xxl) {
      margin-left: 50px;
      width: 95%;
    }

    @media only screen and (max-width: $breakpoint-xl) {
      width: 85%;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      margin-left: 10px;
      width: 97%;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      margin: 11px auto 0 auto;
      max-height: max-content;
    }

    @media only screen and (max-width: $advantages-xl) {
      padding: 0 15px;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
      width: 98%;
      margin-top: 11px;
    }
  }

  &__questionsEmpty {
    position: relative;
    width: 100%;
    height: 400px;
  }

  &__loader {
    left: auto;
    top: auto;
  }

  &__footerBlock {
    max-width: 1320px;
    display: flex;
    justify-content: center;
  }

  &__footer {
    margin-top: 15px;
    max-width: 1160px;

    @media only screen and (max-width: $adaptive) {
      margin-top: 56px;
    }
  }
}
