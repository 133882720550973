@import "../../../styles/variables.scss";

.container {
  height: 32px;
  border-radius: 40px;
  user-select: none;
  padding: 0 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: 8px;
  margin-bottom: 16px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.black {
  font-size: 14px;
  color: $main-black;
  line-height: 16px;
}

.grey {
  font-size: 14px;
  color: $grey;
  line-height: 16px;
}

.yellow {
  border: 1px solid $yellow;
  background-color: rgba(207, 176, 35, 0.08);
}

.pale {
  border: 1px solid $light-grey;
  background-color: $white;
}
