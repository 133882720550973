@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.list {
  width: 145px;
  border-radius: 16px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.24);
  position: absolute;
  background-color: $white;
  display: flex;
  flex-direction: column;
  z-index: 1;

  &__item {
    @include font-text($main-black, 24px);
    height: 48px;
    border-bottom: 1px solid $light-grey;
    padding: 12px 16px;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__itemLink {
    @include font-text($main-black, 24px);
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  &__itemBtn {
    @include font-text($main-black, 24px);
    display: flex;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
