@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/variables.scss";

.content {
  max-width: $main-container;
  width: 100%;
  margin-top: 56px;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: $breakpoint-xm) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (max-width: $adaptive) {
    margin-top: 28px;
    margin-left: 0;
  }

  &__count {
    display: none;

    @media only screen and (max-width: $breakpoint-xm) {
      display: block;
      align-self: flex-start;
      margin: 1px 0 19px 8px;
    }
  }

  &__color {
    color: $grey;
  }

  &__archives {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: max-content;
    max-height: 745px;
    overflow-y: scroll;
    padding-left: 40px;

    @media only screen and (max-width: $breakpoint-xxl) {
      padding-left: 0px;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      max-height: none;
    }

    @media only screen and (max-width: $adaptive) {
      padding-left: 7px;
    }
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.08) #ffffff00;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      width: 7px;
      background: rgba(0, 0, 0, 0.08);
      border-radius: 15px;
    }
  }

  &__empty {
    width: 100%;
    max-width: 920px;
    display: flex;
    justify-content: center;
    margin-top: 200px;

    @media only screen and (max-width: $breakpoint-lg) {
      margin-bottom: 130px;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 375px;
      margin: 80px 0 0 0;
    }
  }
}
