@import "../../../../../styles/variables.scss";

.documents {
  position: relative;
  &__tabs {
    margin-top: 23px;
    width: 100%;

    @media screen and (max-width: $breakpoint-sm) {
      overflow-x: scroll;
      scrollbar-width: thin;
      scrollbar-color: rgba(0, 0, 0, 0.08) #ffffff00;

      &::-webkit-scrollbar {
        background: transparent;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: $light-grey;
        height: 4px;
        border-radius: 10px;
      }
    }
  }

  &__loader {
    top: 250px;
  }
}
