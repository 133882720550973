@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.popupService {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $popup-z-index;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-height: 482px) and (min-width: $breakpoint-xm) {
    align-items: flex-start;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__popupWrapper {
    @include popupSpecialWrapper(1080px);
  }

  &__popup {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 1080px;
    width: 100%;
    background-color: $white;
    border-radius: 30px;

    @media only screen and (max-width: $breakpoint-xm) {
      @include popupScrollAdaptive;
      align-items: center;
    }
  }

  &__contentWrapper {
    position: relative;
    width: 100%;
    display: flex;

    @media screen and (max-width: 650px) {
      justify-content: center;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      flex-direction: column;
      align-items: center;
      height: max-content;
    }
  }

  &__close {
    position: absolute;
    width: 12px;
    height: 12px;
    right: 22px;
    top: 22px;
    cursor: pointer;
    z-index: 10;

    @media screen and (max-width: $breakpoint-xs) {
      right: 30px;
    }
  }

  &__imgWrapper {
    max-width: 326px;
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px 0 0 30px;
    overflow: hidden;

    @media screen and (max-width: $breakpoint-lg) {
      height: 298px;
      border-radius: 30px 0 8px 0;
    }

    @media screen and (max-width: 650px) {
      display: none;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__imgEmpty {
    @include font-text;
    text-align: center;
  }

  &__loader {
    top: 0;
  }

  &__rightContent {
    position: relative;
    width: 72%;
    padding: 32px 25px 33px 32px;
    max-height: 500px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.08) #ffffff00;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      width: 7px;
      background: rgba(0, 0, 0, 0.08);
      border-radius: 15px;
    }

    @media screen and (max-width: $breakpoint-lg) {
      max-height: 494px;
    }

    @media screen and (max-width: 650px) {
      width: 100%;
      max-height: none;
    }

    @media screen and (max-width: $breakpoint-xm) {
      padding: 48px 8px 48px 16px;
    }
  }

  &__error {
    @include font-text-reduced($text-color-error);
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    bottom: 90px;
    text-align: end;
    line-height: 15px;

    @media screen and (max-width: $breakpoint-lg) {
      bottom: 85px;
    }

    @media screen and (max-width: $breakpoint-xm) {
      bottom: 100px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      bottom: 110px;
    }
  }

  &__title {
    @include font-text-big;
    line-height: 40px;
    max-width: 690px;
  }

  &__description {
    @include font-text($dark-grey);
    max-width: 690px;
    margin: 16px 0 24px 0;

    @media screen and (max-width: $breakpoint-xs) {
      margin: 24px 0;
    }
  }

  &__inputDate {
    max-width: 193px;
    margin-bottom: 26px;

    @media screen and (max-width: $breakpoint-xm) {
      width: 100%;
      max-width: none;
    }
  }

  &__input {
    width: 100%;
  }

  &__inputComment {
    width: 100%;
    max-width: 690px;
  }

  &__priceButtonWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 51px;

    @media screen and (max-width: $breakpoint-lg) {
      margin-top: 24px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      flex-direction: column;
      align-items: flex-start;
      gap: 23px;
      margin-top: 25px;
    }
  }

  &__price {
    @include font-text-medium;
    line-height: 40px;
  }

  &__buttonOrder {
    width: 143px;
    height: 48px;
    padding: 0;

    @media screen and (max-width: $breakpoint-xs) {
      width: 100%;
      height: 56px;
    }
  }
}
