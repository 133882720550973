@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.wrapperDate {
  position: fixed;
  top: -30px;
  left: -130px;
  z-index: 999;

  &_popupMode {
    position: relative;
    top: auto;
    left: auto;
    width: 100vw;
    max-width: none;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-height: 575px) {
      align-items: flex-start;
    }

    @media screen and (max-width: $breakpoint-xm) and (min-height: 575px) {
      align-items: flex-end;
    }
  }

  &__container {
    position: relative;
    width: 400px;
    border-radius: 24px;
    background-color: $white;
    z-index: 1000;

    &_popupMode {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: $breakpoint-xm) {
        @include popupScrollAdaptive;
      }
    }
  }
  &__content {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_popupMode {
      @media screen and (max-width: $breakpoint-xm) {
        width: 100vw;
      }
    }
  }
  &__header {
    align-self: flex-start;
    padding-top: 32px;
    padding-left: 32px;
    p {
      font-size: 18px;
      line-height: 32px;
      color: $yellow;
    }
  }
  &__calendar {
    margin-top: 16px;

    &_popupMode {
      padding-bottom: 50px;
    }
  }
  &__button {
    margin-bottom: 32px;
    width: 336px;
    color: $white;
  }
}

.select {
  :global(.react-datepicker__header) {
    width: 336px;
    height: 48px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    background-color: transparent;
    border-radius: 48px !important;
  }

  :global(.react-datepicker__day-names) {
    display: none;
  }

  :global(.react-datepicker__day--selected) {
    position: relative;
    z-index: 0;
    background-color: transparent;
    color: $white;
    &:hover {
      position: relative;
      z-index: 0;
      background-color: $white;
    }
    &:before {
      content: "";
      position: absolute;
      width: 48px;
      height: 48px;
      top: 0;
      right: 0;
      z-index: -1;
      background-color: $main-black;
      border-radius: 50%;
    }
  }

  :global(.react-datepicker__day--keyboard-selected) {
    background-color: transparent;
    color: $main-black;
  }
  :global(.react-datepicker__navigation--previous),
  :global(.react-datepicker__navigation--next) {
    width: 32px;
    height: 32px;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 4px 0 4px;
  }

  :global(.react-datepicker__navigation--previous_disabled) {
    display: none;
  }

  :global(.react-datepicker__navigation-icon) {
    &:before {
      border-color: $main-black;
      border-style: solid;
      border-top-width: 2px;
      border-right-width: 2px;
      border-bottom-width: 0;
      border-left-width: 0;
    }
  }

  :global(.react-datepicker__current-month) {
    font-size: 16px;
    display: block;
    margin-top: 4px;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  :global(.react-datepicker__day) {
    height: 48px;
    width: 48px;
    padding: 12px;
    margin: 0;
    font-weight: bold;
    margin-top: 7px;

    &:hover {
      background-color: $white;
    }
  }

  :global(.react-datepicker__day--disabled) {
    color: $grey;
  }

  :global(.react-datepicker__day--outside-month) {
    visibility: hidden;
  }

  :global(.react-datepicker__week) {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
  }

  :global(.react-datepicker__month-container) {
    width: 336px;
  }

  &_popupMode {
    :global(.react-datepicker__portal) {
      background-color: transparent;
      font-size: 16px;
    }

    :global(.react-datepicker__month) {
      margin-top: 10px;
      margin-bottom: 17px;
    }

    :global(.react-datepicker__current-month) {
      font-size: 16px !important;
      display: block;
      margin-top: 4px;
      &::first-letter {
        text-transform: uppercase;
      }
    }

    :global(.react-datepicker__day) {
      position: relative;
      width: 48px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    :global(.react-datepicker__day--selected) {
      position: relative;
      z-index: 0;
      background-color: transparent;
      color: $white;

      &:hover {
        position: relative;
        z-index: 0;
        background: rgba(207, 176, 35, 0.12);
        color: $white;
      }
      &:before {
        content: "";
        position: absolute;
        width: 48px;
        height: 48px;
        z-index: -1;
        top: -5px;
        background-color: $main-black;
        border-radius: 50%;
      }
    }
  }
}
