@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/variables.scss";

.modal {
  &__contentWrapper {
    width: 100%;
    min-height: 100%;
    height: max-content;
    background-color: $white;

    @media only screen and (max-width: $breakpoint-xm) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__contentTitle {
    @include font-title-big;

    @media only screen and (max-width: $breakpoint-xs) {
      text-align: start;
      align-self: flex-start;
    }
  }
}
