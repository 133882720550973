@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.partners {
  max-width: $main-container;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: $adaptive) {
    justify-content: center;
  }

  &__wrapper {
    display: flex;
    width: 100%;
    padding-bottom: 64px;

    @media only screen and (max-width: $breakpoint-xm) {
      padding-bottom: 0;
    }
  }

  &__building {
    position: relative;
    z-index: -99;
    margin-right: 0px;

    @media only screen and (max-width: $breakpoint-lg) {
      display: none;
    }
  }

  &__rightColumn {
    position: relative;
    width: 100%;
    @media only screen and (max-width: $breakpoint-lg) {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 7px;
    }
  }

  &__info {
    margin-top: 57px;
    margin-left: 40px;

    @media only screen and (max-width: $breakpoint-lg) {
      margin-left: 0;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media only screen and (max-width: $adaptive) {
      align-items: flex-start;
    }
  }

  &__title {
    letter-spacing: 0.04em;

    @media only screen and (max-width: $breakpoint-sm) {
      font-size: 32px;
      line-height: 48px;
      letter-spacing: 0em;
    }
  }

  &__text {
    width: 100%;
    margin-top: 19px;
    line-height: 20px;

    @media only screen and (max-width: $breakpoint-xs) {
      display: flex;
      max-width: 360px;
      flex-direction: column;
    }

    @media only screen and (max-width: $adaptive) {
      width: 340px;
      margin-top: 16px;
    }
  }

  &__button {
    margin-top: 24px;
    color: $white;
    @include btn(56px, 16px 40px);

    @media only screen and (max-width: $breakpoint-xs) {
      max-width: 360px;
      width: 100%;
      margin-top: 16px;
    }
  }

  &__partners {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 40px;
    max-width: 760px;
    margin-top: 56px;
    margin-left: 40px;

    @media only screen and (max-width: $breakpoint-lg) {
      width: 100%;
      margin-left: 0;
      gap: 16px;
      justify-content: flex-start;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      margin-top: 32px;
      justify-content: center;
    }
  }

  &__moreButton {
    @include font-text-reduced;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.12px;
    width: 100%;
    max-width: 760px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 16px 40px;
    border: 1px solid $yellow;
    border-radius: 100px;
    margin-top: 14px;
    transition: 0.3s;

    &:hover {
      background-color: $yellow;
      color: $white;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      margin-top: 8px;
    }
  }

  &__modalSave {
    max-width: 420px;
    line-height: 48px;
    margin-top: 24px;
  }

  &__footer {
    max-width: 1160px;
    margin-top: 8px;

    @media screen and (max-width: $breakpoint-xxl) {
      margin-top: 16px;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      margin-top: 56px;
    }
  }

  &__loadingBlock {
    position: relative;
    width: 100%;
    height: 300px;
  }

  &__loader {
    top: calc(50% - 48px / 2);
    bottom: auto;
  }
}
