@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.news {
  max-width: 360px;
  width: 100%;
  height: 448px;
  border: 1px solid $light-grey;
  border-radius: 24px;
  background-color: $white;
  position: relative;
  overflow: hidden;

  &__imgBlock {
    @include size(100%, 192px);
    overflow: hidden;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 500ms transform;

    &:hover {
      transform: scale((1.2));

      @media (max-width: $adaptive) {
        transform: none;
      }
    }
  }

  &__content {
    max-width: 360px;
    width: 100%;
    height: auto;
    padding: 24px;
  }

  &__date {
    font-size: 14px;
    color: $grey;
  }

  &__title {
    max-width: 290px;
    width: 100%;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__button {
    width: 180px;
    border: 1px solid $yellow;
    position: absolute;
    left: 24px;
    bottom: 24px;
    color: $yellow;

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.16),
          rgba(255, 255, 255, 0.16)
        ),
        $yellow;
      color: $white;
      border: none;
    }
  }
}
