@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.main {
  max-width: $main-container;
  width: 100%;
  display: flex;

  @media only screen and (max-width: $main-part) {
    flex-direction: column;
  }

  @media only screen and (max-width: $breakpoint-sm) {
    display: block;
  }

  &__modalSave {
    max-width: 420px;
    line-height: 48px;
    margin-top: 24px;
  }
}

.leftColumn {
  max-width: 720px;
  width: 51.4%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $yellow;
  position: relative;

  @media only screen and (max-width: $breakpoint-xxl) {
    width: 50%;
  }

  @media only screen and (max-width: $main-part) {
    max-width: 100%;
    width: 100%;
    text-align: center;
    padding: 60px 0;
  }

  @media only screen and (max-width: $breakpoint-xs) {
    padding: 29px 0;
  }

  &__empty {
    width: 100%;
    height: 408px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &__loader {
    top: auto;
    left: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 27px;
    margin-top: 57px;

    @media only screen and (max-width: 1263px) {
      margin-left: 10px;
    }

    @media only screen and (max-width: $main-part) {
      align-items: center;
      margin: 0;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      width: 100%;
      align-items: center;
      margin-right: 0;
    }

    @media only screen and (max-width: $adaptive) {
      width: 100%;
    }
  }

  &__textBlockAnimate {
    max-width: max-content;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  &__textBlock {
    @media only screen and (max-width: $main-part) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    h1 {
      @include font-title-h1($white);
      max-width: 506px;
      margin: 16px 0;
      text-transform: uppercase;

      @media only screen and (max-width: $breakpoint-xxl) {
        font-size: 56px;
        line-height: 58px;
      }

      @media only screen and (max-width: 1263px) {
        font-size: 46px;
        line-height: 48px;
      }

      @media only screen and (max-width: $main-part) {
        font-size: 56px;
        line-height: 58px;
      }

      @media only screen and (max-width: 928px) {
        text-align: center;
      }

      @media only screen and (max-width: $breakpoint-xm) {
        max-width: 98%;
        font-size: 40px;
        line-height: 48px;
      }

      @media only screen and (max-width: $adaptive) {
        max-width: 346px;
        width: 100%;
        font-size: 40px;
        line-height: 48px;
        margin: 12px 0;
        letter-spacing: 0.04em;
        text-align: center;
      }
    }

    h3 {
      @include font-text-middle($white);
      text-transform: uppercase;
      letter-spacing: 0.16em;

      @media only screen and (max-width: 1263px) {
        font-size: 16px;
      }

      @media only screen and (max-width: $main-part) {
        font-size: 18px;
      }

      @media only screen and (max-width: $breakpoint-xm) {
        font-size: 16px;
      }
    }

    p {
      @include font-text-middle($white);
      max-width: 444px;

      @media only screen and (max-width: 928px) {
        text-align: center;
      }

      @media only screen and (max-width: $breakpoint-sm) {
        max-width: 490px;
      }

      @media only screen and (max-width: $breakpoint-xm) {
        @include font-text($white, 24px);
        max-width: 80%;
        margin: 0 auto;
      }

      @media only screen and (max-width: $adaptive) {
        width: 100%;
        max-width: 352px;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin-top: 2px;
      }
    }
  }

  &__buttonsWrapper {
    @media only screen and (max-width: $main-part) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__buttons {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 32px;
    @media only screen and (max-width: $main-part) {
      align-items: center;
    }

    @media only screen and (max-width: $adaptive) {
      margin-top: 25px;
    }
  }

  &__button {
    max-width: 506px;
    width: 100%;
    color: $main-black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-wrap: nowrap;
    @include btn(56px, 16px 40px);

    font-weight: bold;
    &:hover {
      color: $white;
    }

    @media only screen and (max-width: $breakpoint-xl) {
      width: 98%;
    }

    @media only screen and (max-width: $main-part) {
      max-width: 360px;
    }

    @media only screen and (max-width: 1263px) {
      flex-direction: column;
    }

    @media only screen and (max-width: $adaptive) {
      width: 98%;
    }
  }
  &__request {
    @include font-text-reduced($white);
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.08em;
    margin-left: 16px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $main-part) {
      margin-left: 0;
    }
    @media only screen and (max-width: $adaptive) {
      font-size: 12px;
      text-align: center;
      letter-spacing: 0.14em;
      height: 24px;
    }
  }
  &__modal {
    position: absolute;
    top: 200px;
    left: 37%;
    z-index: 99;
  }
}

.rightColumn {
  max-width: 680px;
  width: 48.6%;
  overflow: hidden;

  @media only screen and (max-width: $breakpoint-xxl) {
    width: 50%;
  }

  @media only screen and (max-width: $main-part) {
    max-width: 100%;
    width: 100%;
  }

  @media only screen and (max-width: $adaptive) {
    @include size(375px, 392px);
    display: block;
  }

  &__buildingMobile {
    display: none;
    @media only screen and (max-width: $adaptive) {
      display: block;
    }
  }
  &__building {
    width: auto;
    height: 100%;
    object-fit: contain;

    @media only screen and (max-width: 1265px) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media only screen and (max-width: $main-part) {
      width: 100%;
      height: auto;
    }
  }
  &__buttons {
    width: max-content;
    height: 720px;
    position: relative;

    @media only screen and (max-width: 1263px) {
      width: 100%;
    }

    @media only screen and (max-width: $main-part) {
      height: max-content;
    }

    @media only screen and (max-width: $adaptive) {
      display: none;
    }
  }
  &__parking {
    position: absolute;
    top: 670px;
    left: 21.1%;
    z-index: 11;

    @media only screen and (max-width: 1263px) {
      left: 18%;
    }
    @media only screen and (max-width: $main-part) {
      display: none;
    }
  }
  &__kids {
    position: absolute;
    top: 580px;
    left: 19.2%;
    z-index: 10;
    @media only screen and (max-width: 1263px) {
      left: 15%;
    }
    @media only screen and (max-width: 1180px) {
      left: 12%;
    }
    @media only screen and (max-width: $main-part) {
      display: none;
    }
  }
  &__fit {
    position: absolute;
    top: 625px;
    left: 47.7%;
    z-index: 10;
    @media only screen and (max-width: $main-part) {
      display: none;
    }
  }
  &__supermarket {
    position: absolute;
    top: 618px;
    left: 69%;
    z-index: 12;
    @media only screen and (max-width: 1263px) {
      left: 73%;
    }
    @media only screen and (max-width: $main-part) {
      display: none;
    }
  }
  &__cleaning {
    position: absolute;
    top: 518px;
    left: 81.4%;
    z-index: 10;
    @media only screen and (max-width: 1263px) {
      left: 90%;
    }
    @media only screen and (max-width: $main-part) {
      display: none;
    }
  }

  &__cleaningDesc {
    z-index: 10;
    @media only screen and (max-width: 1425px) {
      right: 0px;
    }
  }

  &__btnDescription {
    z-index: 100;
  }
}

.floors {
  position: absolute;
  color: $white;
  top: 10.5px;
  left: 21%;
  display: flex;
  flex-direction: column;
  gap: 0px;
  z-index: 9;

  @media only screen and (max-width: 1263px) {
    left: 15%;
  }

  @media only screen and (max-width: $breakpoint-xl) {
    display: none;
  }

  &__floorsDescriptionsWrapper {
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
  }

  &__floorDescription {
    position: absolute;
    color: $white;
    margin-left: 130px;
  }

  &__cardInfo1 {
    margin-top: 650px;
  }

  &__cardInfo2 {
    margin-top: 612px;
  }

  &__cardInfo3 {
    margin-top: 570px;
  }

  &__cardInfo4 {
    margin-top: 535px;
  }

  &__cardInfo5 {
    margin-top: 500px;
  }

  &__cardInfo6 {
    margin-top: 465px;
  }

  &__cardInfo7 {
    margin-top: 435px;
  }

  &__cardInfo8 {
    margin-top: 405px;
  }

  &__cardInfo9 {
    margin-top: 370px;
  }

  &__cardInfo10 {
    margin-top: 340px;
  }

  &__cardInfo11 {
    margin-top: 310px;
  }

  &__cardInfo12 {
    margin-top: 280px;
  }

  &__cardInfo13 {
    margin-top: 255px;
  }

  &__cardInfo14 {
    margin-top: 225px;
  }

  &__cardInfo15 {
    margin-top: 195px;
  }

  &__cardInfo16 {
    margin-top: 165px;
  }

  &__cardInfo17 {
    margin-top: 135px;
  }

  .floor {
    height: 35px;
    width: 379.5px;
    position: relative;
    cursor: pointer;
  }

  .floor1 {
    top: -1px;
    z-index: 5;
  }
  .floor2 {
    top: -1px;
    z-index: 5;
  }
  .floor3 {
    top: 0px;
    z-index: 5;
  }
  .floor4 {
    top: 5px;
    z-index: 5;
  }
  .floor5 {
    top: 11px;
    z-index: 5;
  }
  .floor6 {
    top: 15px;
    z-index: 5;
  }
  .floor7 {
    top: 24px;
    z-index: 5;
  }
  .floor8 {
    top: 31px;
  }
  .floor9 {
    top: 32px;
  }
  .floor10 {
    top: 35px;
  }
  .floor11 {
    top: 36px;
  }
  .floor12 {
    top: 37px;
  }
  .floor13 {
    top: 40px;
  }
  .floor14 {
    top: 41px;
  }
  .floor15 {
    top: 41px;
  }
  .floor16 {
    top: 41px;
  }
  .floor17 {
    top: 42px;
  }
}
