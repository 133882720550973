@import "../../../../../../styles/mixins.scss";
@import "../../../../../../styles/variables.scss";

$breakpoint-archive-card: 1270px;

.archive {
  max-width: 760px;
  width: 100%;
  border-radius: 15px;
  border: 1px solid $light-grey;
  position: relative;
  background-color: $white;

  @media screen and (max-width: $breakpoint-archive-card) {
    max-width: 500px;
  }

  @media only screen and (max-width: $breakpoint-lg) {
    max-width: 760px;
  }

  &__content {
    display: flex;
    width: 100%;

    @media screen and (max-width: $breakpoint-archive-card) {
      flex-direction: column;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      flex-direction: row;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
    }
  }

  &__close {
    @include size(12px, 12px);
    position: absolute;
    top: 21px;
    right: 21px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xxl) {
      top: 15px;
      right: 15px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      display: none;
    }
  }
  &__closeMobile {
    display: none;

    @media only screen and (max-width: $breakpoint-xm) {
      display: block;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  &__photoEmpty {
    @include font-text;
    max-width: 245px;
    width: 40%;
    min-height: 162px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    padding: 0 5px;

    @media screen and (max-width: $breakpoint-archive-card) {
      width: 100%;
      max-width: none;
      padding: 0;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      width: 35%;
      max-width: 245px;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      max-width: 100%;
      width: 100%;
    }
  }

  &__photoWrapper {
    max-width: 245px;
    width: 40%;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    cursor: pointer;
    overflow: hidden;

    @media only screen and (max-width: $breakpoint-xl) {
      width: 35%;
    }

    @media screen and (max-width: $breakpoint-archive-card) {
      width: 100%;
      max-width: none;
      max-height: 200px;
      border-radius: 14px 14px 0 0;
    }

    @media only screen and (max-width: $breakpoint-lg) {
      max-width: 245px;
      max-height: none;
      border-radius: 14px 0 0 14px;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      max-width: 100%;
      width: 100%;
      max-height: 250px;
      border-radius: 14px 14px 0 0;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      max-width: 100%;
      width: 100%;
      height: 192px;
      min-height: 192px;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      border-bottom-left-radius: 0;
      margin-left: 0px;
    }
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 500ms transform;

    &:hover {
      transform: scale((1.2));

      @media (max-width: $adaptive) {
        transform: none;
      }
    }
  }

  &__info {
    width: 90%;
    max-width: 495px;
    display: flex;
    flex-direction: column;
    padding: 24px 0 0 24px;

    @media only screen and (max-width: $breakpoint-xxl) {
      padding: 15px 15px 0 15px;
    }

    @media screen and (max-width: $breakpoint-archive-card) {
      width: 100%;
      max-width: none;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 14px 16px 23px 16px;
      margin: 0;
    }
  }

  &__title {
    font-size: 22px;
    line-height: 40px;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-bottom: 0;
      font-size: 24px;
      line-height: 40px;
    }
  }

  &__buttonsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 21px;
    flex-wrap: wrap;
    gap: 15px;

    @media only screen and (max-width: $breakpoint-xxl) {
      margin-bottom: 16px;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      margin-bottom: 0;
      flex-direction: column-reverse;
      margin-top: 24px;
      gap: 16px;
      margin-bottom: 8px;
    }
  }

  &__docsButton {
    @include font-text-reduced($grey);
    display: flex;
    align-items: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    gap: 8px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: $yellow;
    }
  }

  &__docsButtonTitle {
    line-height: 23px;
  }

  &__review {
    @include font-text-reduced($yellow);
    font-weight: 500;
    cursor: pointer;
    letter-spacing: 0.08em;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      display: none;
    }
  }

  &__leftReview {
    color: $yellow;
    letter-spacing: 0.08em;
  }

  &__reviewBtn {
    display: none;

    @media only screen and (max-width: $breakpoint-xm) {
      position: relative;
      display: block;
      width: 100%;
      max-width: 328px;
      color: $white;
    }
  }

  &__reviewBlockMobile {
    display: none;

    @media only screen and (max-width: $breakpoint-xm) {
      display: block;
      margin: 16px;
      text-align: center;
      color: $yellow;
      letter-spacing: 0.08em;
    }
  }

  &__modalDelete {
    max-width: 495px;
    line-height: 48px;
    margin-top: 24px;
    margin-bottom: 16px;
  }
}

.daysBlock {
  display: flex;
  margin-top: 15px;
  gap: 16px;

  &__days {
    color: $grey;
  }

  &__arrow {
    margin-top: 4px;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    padding-bottom: 0;
    margin-top: 17px;
  }
}
