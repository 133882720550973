@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

$min-height-tablet: 700px;

.floor {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $main-container;
  width: 100%;

  &__mainContent {
    display: flex;
    flex-direction: column;
    max-width: 1160px;
    width: 100%;
  }

  &__backBtn {
    margin-top: 48px;

    @media screen and (max-width: $breakpoint-xxl) {
      margin-left: 50px;
    }

    @media screen and (max-width: $floor),
      not (min-height: $min-height-tablet) {
      display: none;
    }
  }

  &__noApartments {
    @include font-text-medium;
    line-height: 40px;
    width: 800px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 258px;

    @media screen and (max-width: $breakpoint-xxl) {
      width: 746px;
      padding-right: 0px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;

      span {
        text-align: center;
      }
    }
  }

  /* Desktop до 767px */

  &__content {
    display: flex;
    width: 100%;
    margin: 26px 0 48px 0;

    &_noApartments {
      flex-direction: column;
      align-items: flex-end;
      padding-right: 40px;

      @media screen and (max-width: $breakpoint-sm) {
        padding: 0;
      }
    }

    @media screen and (max-width: 1450px) {
      padding-left: 25px;
    }

    @media screen and (max-width: $breakpoint-xxl) {
      flex-direction: column;
      align-items: center;
      padding-left: 0;
      margin: 56px auto 0 auto;
    }

    @media screen and (max-width: $breakpoint-sm) {
      margin-top: 32px;
    }
  }

  &__leftSection {
    position: relative;
    width: 280px;
    z-index: 100;

    @media screen and (max-width: $breakpoint-xxl) {
      width: 100%;
      padding: 0 15px;
      display: flex;
      /*  justify-content: space-between; */
      justify-content: space-around;
      align-items: center;
    }

    /*     @media screen and (max-width: $breakpoint-xl) {
      width: 98%;
    } */

    @media screen and (max-width: $floor),
      not (min-height: $min-height-tablet) {
      display: none;
    }
  }

  &__numberFloor {
    @include font-title-very-big;
    text-transform: uppercase;
    width: max-content;
    margin-bottom: 31px;
  }

  &__calendarBlock {
    @media screen and (max-width: $breakpoint-xxl) {
      display: flex;
    }

    @media screen and (max-width: $breakpoint-xl) {
      flex-direction: column;
    }
  }

  &__calendarLoader {
    bottom: 50%;
  }

  &__selectsWrapper {
    @media screen and (max-width: $breakpoint-xxl) {
      margin-right: 35px;
    }
  }

  &__calendarSelect {
    @include font-text-middle;
    line-height: 24px;
    padding-bottom: 10px;
    padding-left: 0;
    width: 270px;
    height: 40px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $grey;
    background-color: transparent;
    background-image: url("../../../assets/images/calendarGrey.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 7px);
    cursor: pointer;
    caret-color: transparent;

    &:focus {
      border: 0;
      border-bottom: 1px solid $grey;
    }

    &::placeholder {
      @include font-text-middle($grey);
      line-height: 24px;
    }

    &_first {
      margin-bottom: 25px;

      @media screen and (max-width: $breakpoint-xxl) {
        margin-bottom: 10px;
      }
    }

    @media screen and (max-width: $breakpoint-xxl) {
      width: 200px;
    }

    @media screen and (max-width: $breakpoint-xl) {
      width: 250px;
    }
  }

  &__calendarPicker {
    top: auto;
    left: -50px;
  }

  &__calendarButtonTextWrap {
    position: relative;
  }

  &__calendarButton {
    width: 270px;
    color: $white;
    margin-top: 32px;

    @media screen and (max-width: $breakpoint-xxl) {
      width: 230px;
    }

    @media screen and (max-width: $breakpoint-xl) {
      width: 250px;
    }
  }

  &__calendarText {
    @include font-text-reduced($grey, 16px);
    margin-top: 16px;
    max-width: 207px;
    margin-left: 4px;
  }

  &__calendarError {
    position: absolute;
    @include font-text-reduced($text-color-error);
    width: 100%;
    display: flex;
    justify-content: center;
    top: 5px;
    text-align: center;
  }

  &__statusDescription {
    margin-top: 100px;

    @media screen and (max-width: $breakpoint-xxl) {
      margin: 10px 0 0 0;
    }
  }

  &__statusItem {
    @include font-text($main-black, 24px);
    height: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  &__statusCircle {
    width: 8px;
    height: 8px;
    background: $main-black;
    opacity: 0.2;
    border-radius: 50%;
    margin-right: 8px;

    &_free {
      border: 0.5px solid $main-black;
      background-color: transparent;
    }
  }

  &__rightSection {
    position: relative;
    z-index: 10;

    @media screen and (max-width: $breakpoint-xxl) {
      margin-top: 120px;
    }

    @media screen and (max-width: $floor),
      not (min-height: $min-height-tablet) {
      display: none;
    }
  }

  &__sliderWrapper {
    position: relative;
    max-width: 940px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 56px;
    margin-left: 72px;

    @media screen and (max-width: $breakpoint-xxl) {
      margin: 0;
    }

    @media screen and (max-width: $breakpoint-lg) {
      flex-direction: column;
    }

    &_noApartments {
      max-width: max-content;
      margin-top: 55px;

      @media screen and (max-width: $breakpoint-xxl) {
        margin-top: 136px;
      }

      @media screen and (max-width: $breakpoint-xl) {
        margin-top: 258px;
      }
    }

    &_tenPlan {
      margin-left: 113px;
      margin-top: 55px;
    }
  }

  &__sliderError {
    position: absolute;
    @include font-text-reduced($text-color-error);
    right: 0;
    top: -45px;
    text-align: end;
  }

  &__sliderFloor {
    position: relative;
    width: 100%;
    height: 588px;
  }

  &__streetsWrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 816px;
    height: 688px;
    left: -42px;
    top: -48px;

    @media screen and (max-width: $breakpoint-xxl) {
      width: 785px;
      left: -20px;
    }

    &_tenPlan {
      left: -84px;
      @media screen and (max-width: $breakpoint-xxl) {
        width: 698px;
        left: -20px;
      }
    }
  }

  &__streetGorizontal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__streetsVerticalWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__streetVertical {
    width: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 13px;

    span {
      transform: rotate(-90deg);
      width: max-content;
    }
  }

  &__slide {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 706px;
    height: auto;

    &_noApartments {
      max-width: max-content;
    }
  }

  &__sliderNavigation {
    position: relative;
    width: max-content;
    min-width: 48px;
    height: min-content;
    display: flex;
    flex-direction: column;
    margin: 5px 0 0 62px;

    @media screen and (max-width: 1450px) {
      margin-left: 40px;
    }

    @media screen and (max-width: $breakpoint-xxl) {
      margin-left: 69px;
    }

    @media screen and (max-width: $breakpoint-lg) {
      transform: rotate(-90deg);
      margin: 0;
    }
  }

  &__btnPrev,
  &__btnNext {
    display: flex;
    position: relative;
    min-width: 48px;
    height: 24px;
    background-color: $yellow;
    background-image: url("../../../assets/images/arrowWhite.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border-radius: 8px 8px 0 0;

    &_disabled {
      opacity: 0.8;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__btnNext {
    transform: rotate(180deg);
  }

  &__bulletsWrap {
    min-width: 48px;
    height: 240px;
  }

  &__bulletSlide {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      @media screen and (max-width: $breakpoint-lg) {
        display: block;
        transform: rotate(90deg);
      }
    }
  }

  &__bulletLink {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__bullet {
    text-decoration: none;
    color: $white;
  }

  &__sliderLoader {
    top: -130%;
  }

  /* После 767px */

  &__adaptive {
    display: none;

    @media screen and (max-width: $floor),
      not (min-height: $min-height-tablet) {
      width: 97%;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    @media screen and (max-width: $breakpoint-xs) {
      width: 100%;
      padding: 0 8px;
    }
  }

  &__adaptiveLoader {
    top: 80%;
  }

  &__adaptiveBack {
    margin-bottom: 32px;
  }

  &__calendarError {
    &_adaptive {
      top: 77px;
    }
  }

  &__adaptiveInfoWrapper {
    position: relative;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  &__adaptiveSelectFloor {
    position: relative;
    width: 100%;
    max-width: 400px;
    z-index: 10;

    @media screen and (max-width: $breakpoint-xs) {
      max-width: 360px;
    }
  }

  &__backgroundFloor {
    position: relative;
    width: 98%;
    height: 580px;
    border-radius: 16px;
    background: rgba(0, 0, 0, 0.05);
    margin: 16px auto 0 auto;
    padding: 8px;
    overflow: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
      opacity: 0;
    }

    @media screen and (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }

  &__adaptiveButton {
    width: 80%;
    max-width: 400px;
    color: $white;
    margin-top: 32px;

    @media screen and (max-width: $breakpoint-xs) {
      width: 100%;
      max-width: 360px;
    }
  }

  &__calendarText {
    &_adaptive {
      width: 100%;
      max-width: 400px;
      margin-top: 16px;

      @media screen and (max-width: $breakpoint-xs) {
        width: 100%;
        max-width: 340px;
        align-self: flex-start;
      }
    }
  }

  &__statusDescription {
    &_adaptive {
      height: max-content;
      display: flex;
      align-items: center;
      column-gap: 16px;
      margin-top: 32px;

      @media screen and (max-width: $breakpoint-xs) {
        margin-top: 24px;
      }
    }
  }

  &__direction {
    &_adaptive {
      display: flex;

      @media screen and (max-width: $breakpoint-xs) {
        margin-top: 16px;
        margin-bottom: -3px;
      }
    }
  }

  &__planAdaptiveWrapper {
    position: relative;
    width: max-content;
    height: max-content;
    left: 48px;
    top: 42px;

    &_noApartments {
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  /* footer */

  &__footer {
    max-width: 1160px;
    width: 95%;
    margin-top: 50px;
    margin: 50px auto 0 auto;

    @media screen and (max-width: $breakpoint-xxl) {
      margin: 100px auto 0 auto;
    }

    @media screen and (max-width: $breakpoint-xm) {
      margin: 52px auto 0 auto;
    }
  }
}
