@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/variables.scss";

.planWithPoints {
  position: relative;
  max-width: min-content;
  height: max-content;
  max-height: 100vh;

  &__grid {
    position: absolute;
    max-width: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__cell {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% / 10);
    height: calc(100% / 10);
  }

  &__pointBtn {
    position: absolute;
    width: 60px;
    height: 60px;

    @media screen and (max-width: $breakpoint-xm) {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }

    @media screen and (max-width: $breakpoint-xs) {
      width: 29px;
      height: 29px;
    }
  }

  &__planImg {
    max-width: 680px;
    width: max-content;
    height: auto;
    max-height: 100vh;
    object-fit: contain;

    @media screen and (max-width: $breakpoint-xl) {
      max-width: 600px;
    }

    @media screen and (max-width: $advantages-xl) {
      max-width: 560px;
    }

    @media screen and (max-width: $breakpoint-xm) {
      max-width: 450px;
    }

    @media screen and (max-width: $service) {
      max-width: 400px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      max-width: 360px;
    }

    @media screen and (max-width: $adaptive) {
      max-width: 320px;
    }
  }

  &__emptyImg {
    @include font-text-medium;
    max-width: 680px;
    width: 680px;
    height: 500px;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media screen and (max-width: $breakpoint-xl) {
      width: 600px;
    }

    @media screen and (max-width: $advantages-xl) {
      width: 560px;
    }

    @media screen and (max-width: $breakpoint-xm) {
      width: 450px;
      height: 200px;
    }

    @media screen and (max-width: $service) {
      width: 400px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      width: 360px;
    }

    @media screen and (max-width: $adaptive) {
      width: 320px;
    }
  }
}
