@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.askQuestion {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $popup-z-index;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-height: 697px) and (min-width: $breakpoint-xm) {
    align-items: flex-start;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__popupWrapper {
    @include popupSpecialWrapper(560px);
  }

  &__popup {
    @include size(560px, 696px);
    position: relative;
    display: flex;
    justify-content: center;
    background-color: $white;
    border-radius: 15px;
    padding-top: 22px;

    @media only screen and (max-width: $breakpoint-xm) {
      width: 100%;
      @include popupScrollAdaptive;
      border-radius: 24px 24px 0 0;
      padding: 36px 8px 0 8px;
      animation: 0.8s ease showModalAdaptive;
    }
  }

  &__close {
    position: absolute;
    right: 22px;
    @include size(14px, 14px);
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      width: 16px;
      height: 16px;
    }
  }

  &__content {
    width: 100%;
    padding: 0 64px 48px 64px;
    background-color: $white;
    border-radius: 0 0 24px 24px;

    @media only screen and (max-width: $breakpoint-xm) {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: max-content;
      border-radius: 0;
      padding: 7px 0 48px 0;
    }
  }

  &__inputName {
    max-width: 432px;
    width: 100%;
    margin-bottom: 17px;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-bottom: 27px;
    }
  }

  &__inputEmail {
    max-width: 432px;
    width: 100%;
    margin-bottom: 17px;
    @media only screen and (max-width: $breakpoint-xm) {
      margin-bottom: 28px;
    }
  }

  &__textareaWrap {
    width: 100%;
    max-width: 432px;
  }

  &__textarea {
    width: 100%;
  }

  &__title {
    color: $main-black;
    font-size: 34px;
    line-height: 40px;
    margin-top: 28px;
    margin-bottom: 24px;

    @media only screen and (max-width: $breakpoint-xm) {
      @include font-text-big;
      margin-bottom: 20px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      align-self: flex-start;
    }
  }

  &__buttonPoliticsWrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $breakpoint-xm) {
      flex-direction: column-reverse;
      max-width: 432px;
      margin: 20px auto 0 auto;
    }
  }

  &__button {
    max-width: 432px;
    width: 100%;
    display: block;
    margin-top: 24px;
    color: $white;
  }

  &__politics {
    max-width: 432px;
    width: 100%;
    margin-top: 32px;
    font-size: 14px;
    line-height: 20px;
    color: $grey;

    span {
      color: $main-black;
      cursor: pointer;
      text-decoration: underline;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      margin-top: 0;
    }
  }
}

@keyframes showModalAdaptive {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0;
  }
}
