@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  max-width: $main-container;
  width: 100%;
  display: flex;
  height: min-content;
  min-height: 720px;

  background-color: $yellow;

  @media only screen and (max-width: $main-part) {
    flex-direction: column;
    min-height: max-content;
  }

  &__leftColumn {
    max-width: 720px;
    width: 100%;
    padding: 60px 0;
    min-height: 720px;
    background-color: $yellow;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: $main-part) {
      max-width: 100%;
      min-height: max-content;
      padding: 80px 0;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      padding: 0;
    }

    @media only screen and (max-width: $adaptive) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px 0;
    }
  }

  &__content {
    max-width: 526px;
    width: 80%;
    margin: 2px 0 0 48px;

    @media only screen and (max-width: $main-part) {
      margin: 0;
    }

    @media (max-width: $breakpoint-xm) {
      width: 100%;
      max-width: 100%;
      padding: 80px 2px;
    }

    @media only screen and (max-width: $adaptive) {
      padding: 0 2px;
    }
  }

  &__empty {
    position: relative;
    width: 70%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loader {
    top: auto;
    left: auto;
  }

  &__textBlock {
    position: relative;

    h2 {
      @include font-title-very-big($white);
      text-transform: uppercase;
      max-width: 70%;
      margin-bottom: 16px;

      @media (max-width: $breakpoint-xl) {
        font-size: 42px;
      }

      @media only screen and (max-width: $main-part) {
        text-align: center;
        max-width: 100%;
      }

      @media (max-width: $breakpoint-xm) {
        font-size: 36px;
        text-align: center;
        letter-spacing: 0.04em;
      }

      @media (max-width: 370px) {
        font-size: 34px;
      }
    }

    p {
      @include font-text-middle($white);
      max-width: 526px;
      width: 100%;

      @media only screen and (max-width: $main-part) {
        text-align: center;
      }

      @media (max-width: $breakpoint-xm) {
        text-align: center;
        width: 98%;
      }

      @media only screen and (max-width: $adaptive) {
        text-align: center;
        font-size: 16px;
        padding: 0 10px;
      }
    }
  }

  &__textContentAnimate {
    @media (max-width: $main-part) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__buttonWrapper {
    @media (max-width: $main-part) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__button {
    @include btn(56px, 16px 40px);
    margin-top: 40px;
    font-weight: bold;
    &:hover {
      color: $white;
    }

    @media only screen and (max-width: $adaptive) {
      width: 360px;
      margin-top: 16px;
      margin-left: 0px;
    }
  }

  &__map {
    max-width: 680px;
    height: 720px;

    @media only screen and (max-width: $main-part) {
      max-width: 100%;
      height: 500px;
    }
    @media only screen and (max-width: $adaptive) {
      height: 536px;
    }
  }
}
