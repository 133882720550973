@import "../../../../../../styles/variables.scss";
@import "../../../../../../styles/mixins.scss";

.button {
  @include size(242px, 32px);
  background-color: $yellow;
  border-radius: 32px;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.16),
        rgba(255, 255, 255, 0.16)
      ),
      $yellow;
  }

  &__img {
    @include size(24px, 24px);
    margin-right: 13px;
  }
}
