@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.news {
  max-width: $main-container;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: $breakpoint-xs) {
    align-items: center;
  }

  &__loader {
    left: 48%;
  }

  &__empty {
    position: relative;
    width: 100%;
    height: 100vh;
  }

  &__content {
    max-width: 1190px;
    margin-top: 48px;

    @media only screen and (max-width: $breakpoint-xxl) {
      margin-left: 0;
      padding: 0 15px;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      max-width: 360px;
      width: 100%;
      margin-top: 16px;
      padding: 0;
    }
  }

  &__title {
    font-size: 34px;
    margin-top: 32px;

    @media only screen and (max-width: $breakpoint-xs) {
      font-size: 24px;
      line-height: 32px;
      margin-top: 16px;
    }
  }

  &__date {
    font-size: 16px;
    color: $grey;
    margin-top: 22px;

    @media only screen and (max-width: $adaptive) {
      margin-top: 6px;
    }
  }

  &__img {
    border-radius: 16px;
    margin-top: 10px;
    width: 100%;
    max-width: 1160px;

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
      height: auto;
      margin-top: 8px;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    font-size: 18px;
    line-height: 32px;
    margin-top: 16px;

    @media only screen and (max-width: $adaptive) {
      max-width: 329px;
      width: 98%;
      margin-top: 18px;
    }
  }

  &__footer {
    max-width: 1160px;
    margin-top: 24px;

    @media only screen and (max-width: $adaptive) {
      margin-top: 24px;
    }
  }
}

.footerBlock {
  max-width: 1320px;
  width: 100%;
  display: flex;
  justify-content: center;

  &__footer {
    max-width: 1160px;
    margin-top: 51px;

    @media only screen and (max-width: $advantages-xl) {
      margin-top: 57px;
    }
  }
}
