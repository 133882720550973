@import "../../../../../../styles/mixins.scss";
@import "../../../../../../styles/variables.scss";

.bookingCard {
  width: 100%;
  max-width: 760px;
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 24px;
  border-radius: 16px;

  @media screen and (max-width: $breakpoint-xxl) {
    max-width: 550px;
  }

  @media screen and (max-width: $breakpoint-lg) {
    max-width: 760px;
  }

  @media screen and (max-width: $adaptive) {
    padding: 17px 16px 16px 16px;
  }

  &__header {
    @include font-subtitle-bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 23px;

    @media screen and (max-width: $adaptive) {
      gap: 8px;
    }
  }

  &__headerRight {
    display: flex;
    gap: 12px;
  }

  &__nameHintWrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__warning {
    position: relative;
    width: 31px;
    max-height: 24px;
  }

  &__warningIcon {
    position: absolute;
    fill: $expired;
    width: 31px;
    height: 31px;
    top: -17px;
    left: 8px;
  }

  &__warningText {
    max-width: 180px;
    left: 45px;
    bottom: -20px;

    @media only screen and (max-width: 500px) {
      left: -130px;
      bottom: 20px;
    }
  }

  &__buttonsWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;

    @media screen and (max-width: $adaptive) {
      gap: 16px;
    }
  }

  &__button {
    @include font-text-button-bold($main-black);
    width: 166px;
    height: 48px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    letter-spacing: 0.08em;
    border: 1px solid $light-grey;

    @media screen and (max-width: $breakpoint-xxl) {
      width: calc(50% - 8px);
    }
  }

  &__replacedButton {
    height: 48px;
  }

  &__loadingPawnBlock {
    position: relative;
    height: 48px;
  }

  &__pawnLoader {
    top: 0;
  }

  &__infoLabel {
    @include font-text-reduced($yellow, 16px);
    display: block;
    padding: 8px 16px;
    border: 1px solid $yellow;
    border-radius: 24px;
    margin-left: 16px;
  }
}
