@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.question {
  max-width: 760px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $light-grey;
  padding: 40px 0 32px 0;

  &_opened {
    align-items: flex-start;
    padding: 40px 0 32px 0;
  }

  &:last-child {
    border-bottom: none;
  }

  @media only screen and (max-width: $adaptive) {
    align-items: flex-start;
    padding: 23.2px 0;
  }

  &__button {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__info {
    margin-left: 24px;
  }

  &__title {
    max-width: 100%;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 5px;
    cursor: pointer;

    @media only screen and (max-width: $adaptive) {
      line-height: 24px;
      max-width: 283px;
      width: 100%;
      margin-top: 0px;
    }
  }

  &__text {
    padding-top: 20px;
    line-height: 20px;
    color: $dark-grey;
    padding-bottom: 5px;

    @media only screen and (max-width: $adaptive) {
      padding-top: 34px;
      line-height: 20px;
      color: $dark-grey;
    }
  }
}
