@import "../../../styles/variables.scss";
@import "../../../styles//mixins.scss";

.inputWithHint {
  position: relative;
  width: 100%;

  &__input {
    width: 100%;
  }

  &__hintsWrapper {
    position: absolute;
    width: 100%;
    height: max-content;
    max-height: 192px;
    overflow-y: auto;
    background-color: $white;
    padding: 0px 16px;
    border-radius: 16px;
    z-index: 30;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.24);
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
      opacity: 0;
    }
  }

  &__hintItem {
    @include font-text($main-black, 24px);
    display: block;
    width: 100%;
    padding: 12px 0;
    transition: 0.2s;
    user-select: none;
    cursor: pointer;

    &:hover {
      color: $yellow;
    }

    @media screen and (max-width: $breakpoint-xm) {
      @include font-text-reduced($main-black, 16px);
    }
  }
}
