@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.prolong {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-height: 374px) and (min-width: $breakpoint-xm) {
    align-items: flex-start;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__popupWrapper {
    @include popupSpecialWrapper(600px);
  }

  &__popup {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 98%;
    background-color: $white;
    border-radius: 16px;
    padding: 22px 22px 52px 52px;

    @media only screen and (max-width: $breakpoint-xm) {
      @include popupScrollAdaptive;
      align-items: center;
      padding: 32px 8px 24px 8px;
    }

    &_noExtended {
      padding-left: 0;
    }
  }

  &__loading {
    width: 100%;
    height: 300px;
  }

  &__noExtended {
    @include font-text-medium;
    width: 100%;
    height: 100%;
    min-height: 300px;
    padding: 20px 50px 50px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__loader {
    left: 45%;
  }

  &__withCalendarBlock {
    width: 100%;
    padding-right: 42px;

    @media screen and (max-width: $breakpoint-xm) {
      padding: 0;
    }
  }

  &__input {
    max-width: 472px;
    width: 100%;
  }

  &__close {
    @include size(14px, 14px);
    cursor: pointer;
    align-self: flex-end;

    &:hover {
      opacity: 0.8;
    }

    @media screen and (max-width: $breakpoint-xm) {
      width: 16px;
      height: 16px;
    }
  }

  &__title {
    color: $main-black;
    font-size: 34px;
    line-height: 40px;
    margin-top: 15px;
    margin-bottom: 18px;

    @media screen and (max-width: $breakpoint-xm) {
      max-width: 250px;
      margin-top: 26px;
      line-height: 48px;
      font-size: 32px;
    }
  }

  &__buttonsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 96px;
    align-self: flex-end;
    margin-top: 32px;

    @media screen and (max-width: $breakpoint-xm) {
      align-self: center;
    }

    @media screen and (max-width: $breakpoint-xs) {
      width: 100%;
      flex-direction: column-reverse;
      row-gap: 40px;
    }
  }

  &__button {
    max-width: 244px;
    width: 100%;
    color: $white;
    cursor: pointer;
  }

  &__buttonCancel {
    @include font-text-reduced;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

.error {
  color: $text-error;
}

@keyframes showModalAdaptive {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0;
  }
}
