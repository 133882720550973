@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

$breakpoint-filters: 700px;
$breakpoint-filters-xl: 1240px;

.filters {
  @media only screen and (max-width: $breakpoint-filters) {
    width: 80%;
    margin: 0 auto;
  }

  &__content {
    border-radius: 24px;
    background-color: $white;
    max-width: 1160px;
    width: 100%;
    padding: 24px;
    margin: 0 auto;

    @media only screen and (max-width: $breakpoint-filters-xl) {
      max-width: 760px;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      width: 98%;
      padding: 20px;
    }

    @media only screen and (max-width: $breakpoint-filters) {
      padding: 24px;
    }
  }

  &__bottomBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media only screen and (max-width: $breakpoint-filters-xl) {
      width: 100%;
      margin-top: 24px;
    }

    @media only screen and (max-width: $breakpoint-filters) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &__topInputs {
    display: flex;
    gap: 16px;

    @media only screen and (max-width: $breakpoint-filters-xl) {
      gap: 33px;
    }

    @media only screen and (max-width: $breakpoint-filters) {
      flex-direction: column-reverse;
      align-items: flex-end;
      gap: 0;
    }
  }

  &__selects {
    display: flex;
    gap: 16px;

    @media only screen and (max-width: $breakpoint-filters-xl) {
      flex-direction: column;
    }

    @media only screen and (max-width: $breakpoint-filters) {
      width: 100%;
      margin-bottom: 24px;
    }
  }

  &__select {
    @media only screen and (max-width: $breakpoint-filters) {
      width: 100%;
    }
  }

  &__prices {
    position: relative;
    height: min-content;
    display: flex;
    align-items: flex-end;
    gap: 16px;

    @media only screen and (max-width: $breakpoint-filters-xl) {
      flex-direction: column;
    }

    @media only screen and (max-width: $breakpoint-filters) {
      width: 100%;
      margin-top: -10px;
      margin-bottom: 24px;
    }
  }

  &__input {
    width: 200px;

    @media only screen and (max-width: $breakpoint-filters) {
      width: 100%;
    }
  }

  &__pricesError {
    @include font-text-reduced($text-color-error);
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    bottom: -25%;
    z-index: 10;

    @media only screen and (max-width: $breakpoint-filters) {
      bottom: -15%;
    }
  }

  &__filterBtn {
    margin-top: 24px;

    @media only screen and (max-width: $breakpoint-filters) {
      display: flex;
      justify-content: center;
      margin: 0;
    }
  }

  &__checkboxContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-right: 80px;

    @media only screen and (max-width: $breakpoint-filters-xl) {
      margin: 0;
    }

    @media only screen and (max-width: $breakpoint-filters) {
      margin-bottom: 20px;
    }
  }

  &__buttonsWrapper {
    display: flex;
    align-items: center;

    @media only screen and (max-width: $breakpoint-filters-xl) {
      align-items: flex-end;
      flex-direction: row-reverse;
      margin-top: 10px;
    }

    @media only screen and (max-width: $breakpoint-filters) {
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 24px;
    }
  }

  &__buttonShow {
    width: 267px;
    color: $white;

    @media only screen and (max-width: 1269px) {
      margin-top: 15px;
    }

    @media only screen and (max-width: $breakpoint-filters) {
      width: 290px;
    }
  }

  &__buttonClearAll {
    @include font-text($main-black, 24px);
    cursor: pointer;
    margin-right: 32px;

    @media only screen and (max-width: $breakpoint-filters-xl) {
      margin-left: 32px;
    }

    @media only screen and (max-width: $breakpoint-filters) {
      margin: 0;
    }
  }
}
