.mainMap {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  :global(ymaps[class$="map"]:first-child:not(:last-child)) {
    display: none;
  }
}
