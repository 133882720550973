@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.radioButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &__inputWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__hiddenInput {
    opacity: 0;
    position: absolute;
    width: 24px;
    height: 24px;
    z-index: 2;
    cursor: pointer;
  }

  &__visibleInput {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border: 1px solid $main-black;
    border-radius: 50%;
    box-sizing: border-box;

    transition: 0.3s;
    z-index: 1;

    &:hover {
      border: 1px solid $yellow;
    }

    &_checked {
      border: 1px solid $yellow;
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $yellow;
      }
    }
  }

  label {
    @include font-text($main-black, 24px);
  }
}
