@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

$order-xl: 1305px;
$form-gap: 16px;

.order {
  position: relative;
  width: 95%;
  max-width: 1160px;
  display: flex;
  flex-direction: column;
  margin: 57px auto 0 auto;

  &__title {
    @include font-text-big;
    line-height: 40px;
  }

  &__subtitle {
    @include font-text-middle($yellow);

    &_rental {
      @media screen and (max-width: $breakpoint-xm) {
        display: none;
      }
    }
  }

  &__block {
    margin-top: 32px;

    &_renter {
      margin-top: 29px;
    }
  }

  &__rentalInfo {
    margin-top: 16px;
  }

  &__formWrapper {
    width: 100%;
    display: flex;
    gap: $form-gap;
    margin-top: 20px;

    @media screen and (max-width: $order-xl) {
      flex-direction: column;
    }

    @media screen and (max-width: $breakpoint-xm) {
      margin-top: 16px;
    }
  }

  &__twoInputs {
    display: flex;
    flex-wrap: wrap;
    gap: $form-gap;
  }

  &__input {
    width: 278px;

    @media screen and (max-width: $order-xl) {
      width: calc(50% - ($form-gap / 2));
      flex-direction: column;
    }

    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  &__fillBtnContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 39px 0 0 0;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      max-width: 860px;
      height: 1px;
      border-top: 1px solid $light-grey;
      left: 0;

      @media only screen and (max-width: $breakpoint-xlg) {
        width: 72%;
      }

      @media only screen and (max-width: $breakpoint-xl) {
        width: 65%;
      }

      @media only screen and (max-width: $breakpoint-lg) {
        display: none;
      }
    }

    @media only screen and (max-width: $breakpoint-xm) {
      display: none;
    }
  }

  &__fillBtn {
    @include font-text($white);
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    background-color: $yellow;
    padding: 6px 16px;
    border-radius: 24px;

    &:hover {
      opacity: 0.8;
    }

    @media screen and (max-width: $breakpoint-xm) {
      display: none;
    }

    &_mobileBtn {
      display: none;

      @media screen and (max-width: $breakpoint-xm) {
        display: flex;
        margin-top: 11px;
      }
    }
  }

  &__buttonContinue {
    max-width: 311px;
    width: 100%;
    height: 56px;
    padding: 0;
    margin: 64px auto 0 auto;

    @media screen and (max-width: $breakpoint-xs) {
      max-width: none;
      margin-top: 32px;
    }
  }

  &__footer {
    margin-top: 64px;

    @media screen and (max-width: $breakpoint-xm) {
      margin-top: 56px;
    }
  }

  &__loadingBlock {
    position: relative;
    width: 100%;
    height: 663px;
  }

  &__loader {
    top: 0;
  }

  &__loaderBooking {
    top: 40%;
    bottom: auto;
  }

  &__modalReuseTitle {
    @include font-text-big;
  }

  &__errorButtonWrapper {
    position: relative;
    display: flex;
    justify-content: center;
  }

  &__error {
    @include font-text-reduced($text-color-error);
    line-height: 18px;
    position: absolute;
    width: max-content;
    max-width: 100%;
    top: auto;
    bottom: 70px;
  }

  &__checkinDate {
    @include font-text-reduced($main-black, 16px);
    margin-top: 8px;
  }
}
