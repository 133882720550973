@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.partnerItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 360px;
  width: 100%;
  max-height: 325px;
  border: 1px solid $light-grey;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;

  @media screen and (max-width: $breakpoint-sm) {
    padding: 16px;
  }

  &__title {
    @include font-subtitle-bold;
    letter-spacing: normal;

    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 6px;
    }
  }

  &__subtitle {
    @include font-text;
    letter-spacing: normal;
    margin-top: 8px;
  }

  &__description {
    @include font-text($dark-grey, 24px);
    min-height: 72px;
    margin-top: 16px;

    @media screen and (max-width: $breakpoint-sm) {
      @include font-text-reduced($dark-grey, 16px);
      min-height: 48px;
    }
  }

  &__link {
    @include font-text;
    max-width: max-content;
    display: block;
    padding: 6px 16px;
    border: 1px solid $yellow;
    border-radius: 32px;
    margin-top: 14px;
    transition: 0.3s;

    &:hover {
      color: $white;
      background-color: $yellow;
    }
  }
}
