@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  max-width: 1160px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 24px;
  border: 1px solid $light-grey;
  background-color: $white;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: $breakpoint-sm) {
    max-width: 560px;
    flex-direction: column-reverse;
  }

  @media screen and (max-width: $breakpoint-xs) {
    max-width: 360px;
  }

  &__info {
    max-width: 600px;
    width: 52%;
    padding: 40px;

    @media screen and (max-width: $breakpoint-sm) {
      max-width: 100%;
      width: 100%;
      max-height: 300px;
      padding: 24px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      max-height: 256px;
    }
  }

  &__date {
    font-size: 14px;
    color: $grey;
  }

  &__title {
    max-width: 520px;
    width: 100%;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media screen and (max-width: $breakpoint-sm) {
      font-size: 26px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__button {
    border: 1px solid $yellow;
    position: absolute;
    left: 40px;
    bottom: 40px;
    color: $yellow;

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.16),
          rgba(255, 255, 255, 0.16)
        ),
        $yellow;
      color: $white;
      border: none;
    }

    @media screen and (max-width: $breakpoint-sm) {
      position: relative;
      left: auto;
      bottom: auto;
      margin-top: 32px;
    }
  }

  &__imgBlock {
    max-width: 560px;
    width: 48%;
    height: 326px;
    overflow: hidden;

    @media screen and (max-width: $breakpoint-sm) {
      max-width: 100%;
      width: 100%;
      height: 250px;
    }

    @media screen and (max-width: $breakpoint-xs) {
      max-height: 192px;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 500ms transform;

    &:hover {
      transform: scale((1.2));

      @media (max-width: $adaptive) {
        transform: none;
      }
    }
  }
}
