@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.mobile {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $popup-z-index;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-height: 465px) and (min-width: $breakpoint-xm) {
    align-items: flex-start;
  }

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: flex-end;
  }

  &__popupWrapper {
    @include popupSpecialWrapper(560px);
  }

  &__popup {
    position: relative;
    width: 100%;
    background-color: $white;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 42px 32px;

    @media only screen and (max-width: $breakpoint-xm) {
      @include popupScrollAdaptive;
      padding: 32px 8px 24px 8px;
    }
  }

  &__content {
    width: 100%;
    max-width: 560px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: $breakpoint-xm) {
      justify-content: space-between;
      height: 100%;
    }
  }

  &__close {
    align-self: flex-end;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-xm) {
      margin-right: 18px;
    }
  }
}

.qrCode {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__code {
    @include size(190px, 190px);
  }

  &__text {
    margin-top: 34px;
    line-height: 48px;
    text-align: center;
    margin-bottom: 12px;
  }
}
