@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.content {
  width: 100%;
  max-width: 507px;
  text-align: center;

  @media only screen and (max-width: $adaptive) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__img {
    margin-top: 8px;
  }

  &__title {
    width: 100%;
    font-size: 24px;
    line-height: 40px;
    margin-top: 30px;
  }

  &__button {
    @include btn(56px, 16px 30.5px);
    margin-top: 31px;
    font-size: 16px;
    color: $white;
    letter-spacing: normal;

    @media only screen and (max-width: $adaptive) {
      width: 360px;
      color: $white;
      margin-top: 23px;
    }
  }
}
