@import "../../../../../../styles/mixins.scss";
@import "../../../../../../styles/variables.scss";

.enterCode {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: $breakpoint-xm) {
    align-items: center;
  }

  &__desc {
    @include font-text-subtitle-calendar($dark-grey);
    max-width: 367px;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 26px;
    line-height: 24px;

    @media only screen and (max-width: $breakpoint-xm) {
      text-align: center;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      text-align: start;
      align-self: flex-start;
    }
  }

  &__number {
    color: $grey;
    line-height: 24px;
    margin-bottom: 56px;

    @media only screen and (max-width: $breakpoint-xm) {
      text-align: center;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      text-align: start;
      align-self: flex-start;
    }
  }

  &__input {
    max-width: 367px;
    width: 100%;
    margin-bottom: 16px;
  }

  &__accept {
    max-width: 367px;
    width: 100%;
    margin-top: 40px;
    font-size: 14px;

    span {
      color: $main-black;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__button {
    max-width: 367px;
    width: 100%;
    margin-top: 32px;
    color: $white;

    @media only screen and (max-width: $breakpoint-xm) {
      margin-bottom: 50px;
    }
  }

  &__login {
    margin-top: 32px;
    text-align: center;
    cursor: pointer;
  }

  &__sendAgainBtn {
    @include font-text;
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
    pointer-events: none;
    align-self: center;
    margin-top: 20px;

    &_active {
      pointer-events: all;
      &:hover {
        opacity: 0.85;
      }
    }
  }

  &__sendAgainSeconds {
    @include font-text($dark-grey);

    &_hide {
      display: none;
    }
  }

  &__contentInputPasswordTwo {
    margin-top: 52px;
    margin-bottom: 50px;
  }
}
