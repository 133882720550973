@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

$column-gap: 16px;

.company {
  width: 100%;
  margin-top: 57px;

  @media only screen and (max-width: $breakpoint-sm) {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: $adaptive) {
      margin-top: 0;
      padding: 0 7px;
    }
  }

  &__content {
    width: 100%;
    max-width: 760px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 40px;

    @media only screen and (max-width: 1380px) {
      margin: 0 auto;
    }

    @media only screen and (max-width: $breakpoint-xs) {
      margin-top: 24px;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 360px;
    }
  }

  &__title {
    color: $yellow;
    line-height: 24px;
  }

  &__requisites {
    color: $yellow;
    line-height: 24px;
    margin-top: 40px;

    @media only screen and (max-width: $breakpoint-sm) {
      margin-top: 32px;
    }
  }

  &__mainInfo {
    display: flex;
    flex-wrap: wrap;
    gap: 24px $column-gap;
    margin-top: 16px;

    @media only screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
      max-height: 100%;
    }

    @media only screen and (max-width: $adaptive) {
      flex-direction: column;
      margin-top: 16.5px;
      gap: 16px $column-gap;
    }
  }

  &__organization {
    max-width: 760px;
    width: 100%;
  }

  &__inn {
    max-width: 170px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xxl) {
      max-width: none;
      width: calc(50% - ($column-gap / 2));
    }

    @media only screen and (max-width: 1065px) {
      width: 100%;
    }
  }

  &__registration {
    max-width: 157px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xxl) {
      max-width: none;
      width: calc(50% - ($column-gap / 2));
    }

    @media only screen and (max-width: 1065px) {
      width: 100%;
    }
  }

  &__director {
    max-width: 401px;
    width: 53%;

    @media only screen and (max-width: $breakpoint-xxl) {
      max-width: none;
      width: 100%;
    }
  }

  &__calculatedBill {
    max-width: 422px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xxl) {
      max-width: none;
    }
  }

  &__correspondentBill {
    max-width: 322px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xxl) {
      max-width: none;
    }
  }

  &__phoneBikContainer {
    display: flex;
    column-gap: 16px;

    @media only screen and (max-width: $breakpoint-xxl) {
      width: 100%;
      row-gap: 16px;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      flex-direction: column-reverse;
    }

    @media only screen and (max-width: 1065px) {
      flex-wrap: wrap;
    }
  }

  &__phone {
    max-width: 256px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xxl) {
      max-width: none;
      width: calc(50% - ($column-gap / 2));
    }

    @media only screen and (max-width: 1065px) {
      width: 100%;
    }
  }

  &__bik {
    max-width: 184px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-xxl) {
      max-width: none;
      width: calc(50% - ($column-gap / 2));
    }

    @media only screen and (max-width: 1065px) {
      width: 100%;
    }
  }

  &__index {
    max-width: 288px;
    width: 100%;

    @media only screen and (max-width: 1065px) {
      max-width: none;
    }
  }

  &__address,
  &__bankName {
    max-width: 760px;
    width: 100%;
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    margin-top: 31px;
    margin-bottom: 64px;
    row-gap: 36px;

    @media only screen and (max-width: $breakpoint-sm) {
      flex-direction: column-reverse;
      gap: 36px;
      margin-top: 32px;
      margin-bottom: 50px;
    }

    @media only screen and (max-width: $adaptive) {
      margin-top: 31px;
      margin-bottom: 60px;
      gap: 28px;
    }
  }

  &__warning {
    color: $text-error;
  }

  &__deleteBtn {
    width: max-content;
    font-size: 16px;
    color: $grey;
    cursor: pointer;
    margin-right: 20px;

    &:hover {
      opacity: 0.8;
    }

    @media only screen and (max-width: $adaptive) {
      margin-left: 18px;
    }
  }

  &__saveBtn {
    width: 100%;
    max-width: 240px;
    height: 56px;
    color: $white;
    padding: 0;
    margin: 32px 0 64px 0;
    align-self: flex-end;

    @media only screen and (max-width: $breakpoint-xm) {
      max-width: none;
    }
  }

  &__empty {
    width: 100%;
    max-width: 920px;
    display: flex;
    justify-content: center;
    margin-top: 196px;

    @media only screen and (max-width: $breakpoint-lg) {
      margin-bottom: 130px;
    }

    @media only screen and (max-width: $adaptive) {
      max-width: 375px;
      display: flex;
      justify-content: center;
      margin: 80px 0 0 0;
    }
  }

  &__inputsWrapper {
    position: relative;
  }

  &__loaderInner {
    top: 45%;
    bottom: auto;

    @media screen and (max-width: $breakpoint-xm) {
      top: 70%;
    }
  }
}
