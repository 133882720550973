@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.apartmentItem {
  position: relative;
  max-width: 760px;
  width: 100%;
  height: min-content;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid $light-grey;
  background-color: $white;
  position: relative;

  &__close {
    @include size(12px, 12px);
    position: absolute;
    top: 21px;
    right: 19px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media screen and (max-width: $breakpoint-xm) {
      display: none;
    }
  }

  &__closeMobile {
    display: none;

    @media screen and (max-width: $breakpoint-xm) {
      display: block;
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }

  &__closeIcon {
    width: 100%;
    height: 100%;
  }

  &__content {
    position: relative;
    width: 100%;
    height: max-content;
    display: flex;

    @media screen and (max-width: $breakpoint-xm) {
      flex-direction: column;
    }
  }

  &__info {
    width: 60%;
    max-width: 440px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px 20px 32px 40px;

    @media screen and (max-width: $breakpoint-xm) {
      width: 90%;
      padding: 16px;
    }
  }

  &__photoEmpty {
    width: 100%;
    max-width: 245px;
    height: 198px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__photoWrapper {
    width: 35%;
    max-width: 245px;
    height: 198px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    object-fit: cover;
    cursor: pointer;

    @media screen and (max-width: $breakpoint-xm) {
      max-width: 100%;
      width: 100%;
      height: 192px;
    }
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media screen and (max-width: $breakpoint-xm) {
      width: 100%;
      height: auto;
    }
  }

  &__price {
    display: block;

    @media screen and (max-width: $breakpoint-xm) {
      font-size: 40px;
      margin-top: 32px;
    }
  }

  &__pricePeriod {
    font-size: 24px;
    opacity: 0.8;
  }

  &__infoTitle {
    @media screen and (max-width: $breakpoint-xm) {
      font-size: 24px;
    }
  }
}
