$main-black: #222222;
$brown: #9c8100;
$yellow: #cfb023;
$dark-grey: #666666;
$grey: #999999;
$light-grey: #e0e0e0;
$white: #ffffff;
$bg: #f7f7f8;
$text-color-error: #e05a5a;
$text-error: #e93939;
$expired: #f64444;
$paid: #3cba67;

$main-container: 1400px;
$breakpoint-xxl: 1365px;
$breakpoint-xlg: 1299px;
$advantages: 1245px;
$apartment: 1210px;
$breakpoint-xl: 1199px;
$main-part: 1090px;
$advantages-xl: 959px;
$breakpoint-lg: 899px;
$breakpoint-sm: 767px;
$breakpoint-xm: 576px;
$adaptive-footer: 564px;
$service: 532px;
$breakpoint-xs: 430px;
$adaptive: 376px;
$floor: 960px;

$account-menu-paddingLeft: 25.2%;
$account-menu-paddingLeft-min: 10%;

$footer-height: 120px;
$footer-height-adaptive: 200px;
$header-height: 104px;
$header-height-adaptive: 96px;

$popup-z-index: 999;
$header-z-index: 998;
