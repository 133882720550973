@import "../../../../styles/variables.scss";

.ask {
  display: flex;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;
  border: none;
  background-color: $yellow;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: $breakpoint-sm) {
    background-color: $bg;
  }

  @media only screen and (max-width: $adaptive) {
    display: flex;
    align-items: center;
    margin-top: -8px;
    background-color: $bg;
  }

  &__title {
    font-size: 18px;
    color: $white;
    margin-left: 8px;

    @media only screen and (max-width: $breakpoint-sm) {
      color: $main-black;
    }
    
    @media only screen and (max-width: $adaptive) {
      font-size: 18px;
      margin-left: 8px;
      color: $main-black;
    }
  }
}
