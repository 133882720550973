@import "../../../../../styles/variables.scss";

.container {
  padding: 4px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.24);
  position: relative;

  &__title {
    color: $white;
    font-size: 18px;
    max-width: 230px;
  }

  &__close {
    cursor: pointer;
    
    &:hover {
      opacity: 0.8;
    }
  }
}
