@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: $adaptive) {
    display: flex;
    align-items: center;
  }

  &__button {
    cursor: pointer;
    user-select: none;

    @media only screen and (max-width: $adaptive) {
      cursor: pointer;
      user-select: none;
    }
  }

  &__pages {
    display: flex;
    gap: 16px;
    margin: 0 15px;

    @media only screen and (max-width: $adaptive) {
      max-width: 216px;
      width: max-content;
      padding: 19px 0;
    }
  }

  &__page {
    @include size(24px, 24px);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    @media only screen and (max-width: $adaptive) {
      width: 24px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      font-size: 16px;
    }
  }

  &__dots {
    width: 23px;

    @media only screen and (max-width: $adaptive) {
      @include size(24px, 24px);
    }
  }

  &__activePage {
    @include size(24px, 24px);
    border-radius: 50%;
    background-color: $main-black;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1px;

    @media only screen and (max-width: $adaptive) {
      @include size(24px, 24px);
      min-width: 24px;
      background-color: $main-black;
      color: $white;
    }
  }
}
